
import { defineComponent, toRef } from 'vue';
import { object, string } from 'vue-types';
import { Camera } from '@/api';
import NInput from '@/uikit/input/NInput.vue';
import NLoadingCircle from '@/uikit/loading/NLoadingCircle.vue';
import NSelect from '@/uikit/select/NSelect.vue';
import { useCameraLookupSubForm } from './setup';

export default defineComponent({
  name: 'CameraLookupSubForm',
  components: {
    NInput,
    NLoadingCircle,
    NSelect
  },
  props: {
    camera: object<Camera>().isRequired,
    modelValue: string().isRequired
  },
  emits: ['update:modelValue'],
  setup: (props, { emit }) => {
    return useCameraLookupSubForm(toRef(props, 'modelValue'), toRef(props, 'camera'), (url) => emit('update:modelValue', url));
  }
});
