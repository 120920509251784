import { TabItemsMap } from '@/definitions/app/tab.items';
import { CarCard, HumanCard, WatchList } from '@/api';
import { CardTypesMap } from '@/store/application/data.assets';
import { cardAssetsModule } from '@/store/cards/CardAssetsModule';
import { dataModule } from '@/store/data';
import { NInput, NSelect } from '@/uikit';
import NCheckbox from '@/uikit/checkbox/NCheckbox.vue';
import { IFormLayout } from '@/uikit/forms/NForm.vue';
import NTextareaAutosize from '@/uikit/textarea/NTextareaAutosize.vue';
import CardFormFirstObjects from '@/pages/cards/content/CardFormFirstObjects.vue';

export function getCardFormSchema(cardType: string) {
  const isHumanCard = cardType === CardTypesMap.Humans;

  const licensePlateField = {
    path: 'license_plate_number',
    classes: 'n-form-w-6  control-m n-form-pad-10',
    i18n_label: 'cards.license_plate',
    component: NInput
  };

  let result: IFormLayout | any[] = [
    {
      path: 'id',
      classes: 'n-form-w-6  control-m n-form-pad-10',
      component: CardFormFirstObjects,
      props: { cardType }
    },
    {
      path: 'name',
      classes: 'n-form-w-6  control-m n-form-pad-10',
      i18n_label: 'cards.name',
      component: NInput,
      validators: [{ name: 'required' }]
    },
    {
      path: 'watch_lists',
      classes: 'n-form-w-6  control-m n-form-pad-10',
      i18n_label: 'common.watch_lists',
      component: NSelect,
      validators: [{ name: 'required' }],
      props: {
        multiple: true,
        multiline: true,
        color: true,
        items: dataModule.watchListsWithoutUnknown.map((v: WatchList) => ({
          label: v.name,
          value: v.id,
          color: '#' + v.color,
          disabled: !dataModule.getIsEditableWatchList(v.id)
        }))
      }
    },
    isHumanCard ? null : licensePlateField,
    {
      path: 'comment',
      classes: 'n-form-w-6  control-m n-form-pad-10',
      i18n_label: 'common.comment',
      component: NTextareaAutosize
    },
    {
      path: 'active',
      classes: 'n-form-w-6  control-m n-form-pad-10',
      component: NCheckbox,
      props: {
        i18n_label: 'cards.active'
      }
    }
  ];

  return result.filter((v: any) => !!v);
}
