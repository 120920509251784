
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { viewModelRepository } from '@/api/common';
import EventItem from '@/components/events/EventItem.vue';
import CaseClusterEvent from '@/pages/cases/case-clusters/CaseClusterEvent.vue';
import { ItemsActionNames } from '@/definitions/app/item.actions.name';
import InfiniteScroll from '@/components/common/InfiniteScroll.vue';

@Options({
  name: 'CaseClusterEvents',
  components: { InfiniteScroll, CaseClusterEvent, EventItem }
})
export default class CaseClusterEvents extends Vue {
  @Prop({ type: Number, required: true })
  readonly clusterId!: number;

  @Prop({ type: Boolean, required: false, default: false })
  readonly shoConfidence!: boolean;

  readonly module = viewModelRepository.getCaseEventsListViewModel();

  mounted() {
    this.module.filter.current.case_cluster_in = [this.clusterId];
    this.module.filter.current.limit = '100';
    this.module.get();
  }

  actionHandler(item: any, type: any) {
    if (type === ItemsActionNames.ShowFullScreen) {
      this.$photoViewer.show([item]);
    }
  }
}
