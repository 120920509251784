import { IFormLayout } from '@/uikit/forms/NForm.vue';
import Permissions from '@/components/permissions/Permissions.vue';

const layout: IFormLayout = [
  {
    path: 'permissions',
    classes: 'n-form-w-6',
    component: Permissions,
    props: { disabledIds: [1] }
  }
];

export default layout;
