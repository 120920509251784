import { BodyEvent, CarCard, CarEvent, FaceEvent, HumanCard } from '@/api';
import { BboxConfig } from '@/uikit/bbox/types';
import { CardType, CardTypesMap, ObjectsType, ObjectType } from '@/store/application/data.assets';
import { viewModelRepository } from '@/api/common';
import { customDialogFactory } from '@/store/dialogs/customDialogFactory';
import CardsDuplicateDialog from '@/components/cards/CardsDuplicateDialog.vue';
import { loadDataModule } from '@/store/data/LoadDataModule';
import { getExternalImage } from '@/pages/search/helpers';
import DetectionModule from '@/components/detection/DetectionModule';

export type CardUploadResult = {
  result: boolean;
  cardId?: number;
  cardType: CardType;
};

export class CardPhotoUploaderModule {
  async uploadByDetection(
    file: File,
    detection: BboxConfig,
    objectsType: ObjectsType,
    currentCardId?: number,
    cardType: CardType = 'humans'
  ): Promise<CardUploadResult> {
    let userSelectedCardId = currentCardId;
    const looksLike = `detection:${detection.meta.id}`;
    const duplicateCards = await this.getDuplicateCards(cardType, looksLike, currentCardId);
    let result = false;

    if (duplicateCards.length) {
      const props = {
        image: detection.thumbnail,
        cards: duplicateCards,
        cardType: cardType,
        currentCardId
      };
      userSelectedCardId = await customDialogFactory.create<number>(CardsDuplicateDialog, props, 'onAddToCard');
    }

    if (userSelectedCardId) {
      await this.addPhotoToCardByFile(file, objectsType, String(detection.meta.id), userSelectedCardId);
      result = true;
    }

    return {
      result,
      cardId: userSelectedCardId,
      cardType
    };
  }

  async uploadByEvent(
    event: FaceEvent | BodyEvent | CarEvent,
    eventType: string,
    objectsType: ObjectsType,
    currentCardId?: number,
    cardType: CardType = 'humans'
  ): Promise<CardUploadResult> {
    let userSelectedCardId = currentCardId;
    const looksLike = `${eventType}:${event.id}`;
    const duplicateCards = await this.getDuplicateCards(cardType, looksLike, currentCardId);
    let result = false;

    if (duplicateCards.length) {
      const props = {
        image: event.thumbnail,
        cards: duplicateCards,
        cardType: cardType,
        currentCardId
      };
      userSelectedCardId = await customDialogFactory.create<number>(CardsDuplicateDialog, props, 'onAddToCard');
    }

    if (userSelectedCardId && userSelectedCardId > 0) {
      await this.addPhotoFrom(`${eventType}:${event.id}`, objectsType, userSelectedCardId);
      result = true;
    }

    return {
      result,
      cardId: userSelectedCardId,
      cardType
    };
  }

  protected getDetection(blob: Blob): BboxConfig[] {
    const detectionModule = new DetectionModule({});
    detectionModule.detect(blob);
    const bboxes = detectionModule.bboxes;
    return bboxes;
  }

  protected async getFileByUrl(url: string): Promise<File> {
    const blob = await getExternalImage(url);
    const file = new File([blob], `loaded-image-${Math.round(Math.random() * 1e6)}.jpg`, { type: 'image/jpeg' });
    return file;
  }

  protected async addPhotoFrom(from: string, objectsType: ObjectsType, cardId: number) {
    const data = new FormData();
    data.append('create_from', from);
    data.append('card', String(cardId));
    const itemsModule = this.getItemsModule(cardId, objectsType);
    await itemsModule?.dataService.create(data);
    return true;
  }

  protected async addPhotoToCardByFile(file: File, objectsType: ObjectsType, detectionId: string, cardId: number) {
    const data = new FormData();
    data.append('source_photo', file);
    data.append('detect_id', detectionId);
    data.append('card', String(cardId));
    const itemsModule = this.getItemsModule(cardId, objectsType);
    await itemsModule?.dataService.create(data);
    return true;
  }

  protected async getDuplicateCards(cardType: CardType, looksLike: string, excludedId?: number): Promise<(CarCard & HumanCard)[]> {
    let cardsModule = this.getCardsModule(cardType);
    cardsModule.filter.current.looks_like = looksLike;
    cardsModule.filter.current.ordering = '-looks_like_confidence';
    await cardsModule.get();
    const duplicateItems = (cardsModule.items as (CarCard & HumanCard)[]).filter((item: any) => item.id !== excludedId);
    return duplicateItems;
  }

  protected getItemsModule(cardId: number, objectsType: ObjectsType) {
    const result = loadDataModule.getObjectsLVMByCardID({ id: cardId, type: objectsType, autoload: false });
    return result;
  }

  protected getCardsModule(cardType: CardType) {
    switch (cardType) {
      case CardTypesMap.Cars:
        return viewModelRepository.getCardsCarsListViewModel();
      case CardTypesMap.Humans:
      default:
        return viewModelRepository.getCardsHumansListViewModel();
    }
  }
}

export const cardPhotoUploaderModule = new CardPhotoUploaderModule();
