import sum from 'lodash/sum';
import { computed, ref, Ref, unref } from 'vue';
import { BlockedPermissions, PluginAccessPermissionGroups, Plugins, SystemAccessPermissionGroups, SystemCommonPermissionGroups } from './const';
import { AccessPermissionGroup, CommonPermissionGroup, Permission, PluginGroup } from './types';

export function useGroupPermissionsViewModel(granted: Ref<Permission[]>, plugins: PluginGroup) {
  const blockedSet = computed(() => new Set(BlockedPermissions));
  const grantedSet = computed(() => new Set(unref(granted)));
  const searchText = ref('');

  const accessGroups = computed(computeAccessPermissionGroups);
  const commonGroups = computed(() => SystemCommonPermissionGroups);
  const access = computed(matchAccessPermissionGroups);
  const common = computed(matchCommonPermissionGroups);
  const totalCount = computed(() => unref(common).length + unref(access).length * 4);
  const grantedCount = computed(() => countCommonPermissionsGranted(unref(common)) + countAccessPermissionsGranted(unref(access)));

  function isBlocked(permission: Permission): boolean {
    return unref(blockedSet).has(permission);
  }

  function isGranted(permission: Permission): boolean {
    return unref(grantedSet).has(permission);
  }

  function toggle(permission: Permission): void {
    isGranted(permission) ? revoke(permission) : grant(permission);
  }

  function grant(permission: Permission): void {
    /* TODO: Replace this by new array emitting. */
    unref(granted).push(permission);
  }

  function revoke(permission: Permission): void {
    /* TODO: Replace this by new array emitting. */
    unref(granted).splice(unref(granted).indexOf(permission), 1);
  }

  function computeAccessPermissionGroups(): AccessPermissionGroup[] {
    const groups = Plugins.filter((plugin) => Boolean(plugins[plugin])).map((plugin) => PluginAccessPermissionGroups[plugin]);
    return SystemAccessPermissionGroups.concat(groups.flat());
  }

  function matchAccessPermissionGroups(): AccessPermissionGroup[] {
    const matcher = createStringMatcher(unref(searchText));
    return unref(accessGroups).filter((group) => matcher(group.target));
  }

  function matchCommonPermissionGroups(): CommonPermissionGroup[] {
    const matcher = createStringMatcher(unref(searchText));
    return unref(commonGroups).filter((group) => matcher(group.target));
  }

  function isGruntedNumber(permission: Permission) {
    return Number(isGranted(permission));
  }

  function countAccessPermissionsGranted(accessGroups: AccessPermissionGroup[]) {
    return sum(
      accessGroups.map((group) => isGruntedNumber(group.view) + isGruntedNumber(group.add) + isGruntedNumber(group.change) + isGruntedNumber(group.delete))
    );
  }

  function countCommonPermissionsGranted(commonGroups: CommonPermissionGroup[]) {
    return sum(commonGroups.map((group) => isGruntedNumber(group.permission)));
  }

  return { access, common, totalCount, grantedCount, isBlocked, isGranted, searchText, toggle };
}

function createStringMatcher(substr: string) {
  const regexp = new RegExp(substr, 'i');
  return (str: string) => regexp.test(str);
}
