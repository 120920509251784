
import { Options, Vue } from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import { ItemsActionName, ItemsActionNames } from '@/definitions/app/item.actions.name';
import { User, UserFaceService } from '@/api';
import { viewModelRepository } from '@/api/common';
import { actionHandler } from '@/store/data/ActionHandler';
import { NBaseBar, NButton } from '@/uikit';
import { NAttachment, NAttachmentFileBlob, NAttachments } from '@/uikit/attachments';
import { BboxConfig } from '@/uikit/bbox/types';
import DetectionDialog from '@/components/detection/DetectionDialog.vue';
import { isUserFaceRequestWithThumbnail, UserFaceObject } from '@/pages/users/types';
import CameraShotAttach from '@/pages/users/uploader/CameraShotAttach.vue';
import FileAttachButton from '@/pages/users/uploader/FileAttachButton.vue';
import { userFacesModule } from '@/pages/users/UserFacesModule';
import UserFace from './UserFace.vue';

@Options({
  components: {
    CameraShotAttach,
    DetectionDialog,
    FileAttachButton,
    NAttachments,
    NBaseBar,
    NButton,
    UserFace
  }
})
export default class UserFaces extends Vue {
  @Prop({ type: Object, required: true })
  readonly item!: User;

  @Prop({ type: Boolean, default: false })
  readonly isNew!: boolean;

  module = viewModelRepository.getUserFaceListViewModel();
  file: Blob | null = null;

  get facesModule() {
    return userFacesModule;
  }

  get items() {
    let fakeFaces = this.facesModule.getUserFaces(this.item.id);
    return [...fakeFaces, ...this.module.items];
  }

  @Watch('item.id')
  initModule() {
    this.module.filter.force.limit = 1000;
    this.module.filter.force.user = [this.item.id];
    this.module.get();
  }

  async applyDetection([v]: BboxConfig[]) {
    if (this.file) {
      let item = { user: this.item.id, source_photo: this.file, create_from: `detection:${v.meta.id}` };
      this.file = null;
      if (this.isNew) {
        this.facesModule.add({ ...item, thumbnail: v.thumbnail });
      } else {
        await UserFaceService.userFaceCreate(item);
        await this.module.get();
      }
    }
  }

  getIsCover(userFace: UserFaceObject) {
    return isUserFaceRequestWithThumbnail(userFace) ? true : userFace.id === this.item.face_cover;
  }

  handleAttach(file: File) {
    this.file = file;
  }

  handleDndAttach([attachment]: NAttachment[]) {
    this.handleAttach(attachment.file as NAttachmentFileBlob);
  }

  handleAction(actionName: ItemsActionName, userFace: UserFaceObject) {
    switch (actionName) {
      case ItemsActionNames.SetCover:
        !isUserFaceRequestWithThumbnail(userFace) && (this.item.face_cover = userFace.id);
        break;
      case ItemsActionNames.ShowFullScreen:
        actionHandler.run(ItemsActionNames.ShowFullScreen, userFace);
        break;
      case ItemsActionNames.Delete:
        this.deleteFace(userFace);
        break;
    }
  }

  async deleteFace(userFace: UserFaceObject) {
    if (isUserFaceRequestWithThumbnail(userFace)) {
      this.facesModule.remove(userFace);
    } else {
      await this.module.delete(userFace.id);
      await this.module.get({ resetState: false });
    }
  }

  mounted() {
    !this.isNew && this.initModule();
  }
}
