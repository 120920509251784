import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "case-cluster-events" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CaseClusterEvent = _resolveComponent("CaseClusterEvent")!
  const _component_InfiniteScroll = _resolveComponent("InfiniteScroll")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.module.items, (item) => {
      return (_openBlock(), _createBlock(_component_CaseClusterEvent, {
        key: item.id,
        item: item,
        onAction: (type) => _ctx.actionHandler(item, type)
      }, null, 8, ["item", "onAction"]))
    }), 128)),
    _createVNode(_component_InfiniteScroll, {
      filters: _ctx.module.filter.current,
      onLoadMore: _cache[0] || (_cache[0] = ($event: any) => (_ctx.module.append()))
    }, null, 8, ["filters"])
  ]))
}