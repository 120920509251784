
import { reactive } from 'vue';
import { Options, Vue } from 'vue-class-component';
import { Watch } from 'vue-property-decorator';
import { NAttachments } from '@/uikit/attachments';
import NButton from '@/uikit/buttons/NButton.vue';
import NButtonGroup from '@/uikit/buttons/NButtonGroup.vue';
import { NButtonGroupItem } from '@/uikit/buttons/types';
import NForm from '@/uikit/forms/NForm.vue';
import NLoadingProgress from '@/uikit/loading/NLoadingProgress.vue';
import NModalWindow from '@/uikit/modal-window/NModalWindow.vue';
import { ItemsActionNames } from '@/definitions/app/item.actions.name';
import { BatchUploadService, PaginatedUploadListList } from '@/api';
import { dataAssetsModule } from '@/store/application/data.assets.module';
import { actionHandler } from '@/store/data/ActionHandler';
import { dialogModule } from '@/store/dialogs/dialogModule';
import { languageModule } from '@/store/languages';
import BatchUploadLogsDownload from '@/pages/audit-logs/batch-upload-logs/BatchUploadLogsDownload.vue';
import BatchLoaderModalCsv from '@/pages/cards/batch-loader/BatchLoaderModalCsv.vue';
import BatchLoaderModalImages from '@/pages/cards/batch-loader/BatchLoaderModalImages.vue';
import BatchLoaderModalResult from '@/pages/cards/batch-loader/BatchLoaderModalResult.vue';
import { BatchLoaderModule, batchLoaderModule } from '@/pages/cards/batch-loader/BatchLoaderModule';
import { getItemsNavigationModule } from '@/components/common/ItemsNavigationTypes';
import ItemsNavigation from '@/components/common/ItemsNavigation.vue';

@Options({
  components: {
    ItemsNavigation,
    NAttachments,
    NButton,
    NButtonGroup,
    NForm,
    NLoadingProgress,
    NModalWindow,
    BatchUploadLogsDownload,
    BatchLoaderModalCsv,
    BatchLoaderModalImages,
    BatchLoaderModalResult
  }
})
export default class BatchLoaderModal extends Vue {
  private logs: PaginatedUploadListList = {};

  get batchModule() {
    return batchLoaderModule;
  }

  get model() {
    return this.batchModule.model;
  }

  get dataAssetsModule() {
    return dataAssetsModule;
  }

  get realInputType(): string {
    return this.model.inputType;
    // return this.model.objectType === 'cars' ? this.model.inputType : 'images';
  }

  get inputTypeItems(): NButtonGroupItem[] {
    return ['csv', 'images'].map((v: string) => ({ value: v, label: languageModule.getTranslatedToken(`cards.bu_${v}`, 'f') }));
  }

  get resultItems() {
    return this.batchModule.items;
  }

  get navigationState() {
    return getItemsNavigationModule(this.resultItems);
  }

  get totalStatistics() {
    return this.batchModule.total;
  }

  get cardType() {
    return this.dataAssetsModule.getCardTypeByObjectType(this.model.objectType);
  }

  get loading() {
    return this.batchModule.loading;
  }

  get finished() {
    return this.batchModule.finished;
  }

  get hasToShowConfirm() {
    return this.batchModule.items.length && !this.batchModule.finished;
  }

  async setObjectType(v: string) {
    let result = this.hasToShowConfirm ? await this.showConfirm() : true;
    if (result) {
      this.model.objectType = v;
      this.batchModule.items = [];
      if (v === 'cars') {
        this.model.inputType = 'csv';
      } else {
        this.model.inputType = 'images';
      }
    }
  }

  async setInputType(v: string) {
    let result = this.hasToShowConfirm ? await this.showConfirm() : true;
    if (result) {
      this.model.inputType = v;
      this.batchModule.items = [];
    }
  }

  batchUpload() {
    this.batchModule.paused = false;
    this.batchModule.upload();
  }
  pauseUpload() {
    this.batchModule.pause(true);
  }

  async showLogs() {
    this.logs = await BatchUploadService.batchUploadList();
  }

  async showConfirm(): Promise<boolean> {
    return await dialogModule.createBatchLoaderConfirm();
  }

  async openCard(id: number) {
    const sidebarType = dataAssetsModule.getCardTypeByObjectType(this.model.objectType);
    let result = this.hasToShowConfirm ? await this.showConfirm() : true;
    if (result) {
      this.$emit('close');
      await actionHandler.run(ItemsActionNames.ShowItem, { type: 'cards_' + sidebarType, rawItem: id });
    }
  }

  async close() {
    let result = this.hasToShowConfirm ? await this.showConfirm() : true;
    if (result) {
      this.$emit('close');
    }
  }

  beforeUnmount() {
    this.batchModule.timer.clear();
    this.batchModule.items = [];
    this.batchModule.model = new BatchLoaderModule().model;
  }
}
