import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, Teleport as _Teleport } from "vue"

const _hoisted_1 = { class: "label-mono-xs" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BatchUploadLogsDownload = _resolveComponent("BatchUploadLogsDownload")!
  const _component_NButton = _resolveComponent("NButton")!
  const _component_MultisidebarHeader = _resolveComponent("MultisidebarHeader")!
  const _component_BatchUploadLogsDetails = _resolveComponent("BatchUploadLogsDetails")!
  const _component_NThumbnail = _resolveComponent("NThumbnail")!
  const _component_NIcon = _resolveComponent("NIcon")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_MultisidebarHeader, {
      label: _ctx.$t('audit_logs.details', 'f'),
      onAction: _ctx.handleAction
    }, {
      beforeActions: _withCtx(() => [
        (_ctx.item.object_type === 'uploadlist')
          ? (_openBlock(), _createBlock(_component_BatchUploadLogsDownload, {
              key: 0,
              item: _ctx.item?.action_details?.created_data
            }, null, 8, ["item"]))
          : _createCommentVNode("", true),
        _createVNode(_component_NButton, {
          type: "secondary",
          icon: "copy",
          onClick: _ctx.copyText
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('audit_logs.copy', 'f')), 1)
          ]),
          _: 1
        }, 8, ["onClick"])
      ]),
      _: 1
    }, 8, ["label", "onAction"]),
    (_ctx.item.object_type === 'uploadlist')
      ? (_openBlock(), _createBlock(_component_BatchUploadLogsDetails, {
          key: 0,
          uploadListId: _ctx.item.action_details?.created_data?.id
        }, null, 8, ["uploadListId"]))
      : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
          (_ctx.item.thumbnail)
            ? (_openBlock(), _createBlock(_component_NThumbnail, {
                key: 0,
                class: "audit-logs-sidebar__image",
                modelValue: _ctx.item.thumbnail,
                onClick: _ctx.showImage
              }, null, 8, ["modelValue", "onClick"]))
            : _createCommentVNode("", true),
          _createElementVNode("pre", _hoisted_1, _toDisplayString(_ctx.itemContent), 1)
        ], 64)),
    (_openBlock(), _createBlock(_Teleport, { to: "body" }, [
      _createElementVNode("div", {
        class: _normalizeClass([{ 'audit-logs_visible_noty': _ctx.visibleNoty }, "audit-logs__notification heading-m"])
      }, [
        _createVNode(_component_NIcon, { name: "info" }),
        _createTextVNode(_toDisplayString(_ctx.$t('audit_logs.clipboard_success', 'f')), 1)
      ], 2)
    ]))
  ]))
}