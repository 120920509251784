import { configModule } from '@/store/config';
import { itemViewModelLoader } from '@/store/multisidebar/helpers';

function isADEnabled() {
  return configModule.config.services.ffsecurity.active_directory;
}

export default async function (...args: any[]) {
  // @ts-ignore
  const defaultItem = await itemViewModelLoader(...args);
  const defaultSave = defaultItem.model.save;
  defaultItem.model.save = async function () {
    if (!isADEnabled()) {
      delete this.item['ad_group'];
    } else if (!this.item['ad_group']) {
      this.item['ad_group'] = { group_guid: null };
    }
    return defaultSave.apply(defaultItem.model);
  };
  return defaultItem;
}
