
import { defineComponent } from 'vue';
import { arrayOf, object } from 'vue-types';
import { VmsPluginCamerasRequest } from '@/api';
import { VmsPluginCameraStream } from '@/api/models/VmsPluginCamerasRequest';
import { VmsSidebarCamerasTabListItem } from './components';

export default defineComponent({
  name: 'VmsSidebarCamerasTabList',
  components: { VmsSidebarCamerasTabListItem },
  props: {
    cameras: defineCamerasProp(),
    streams: defineStreamsProp()
  },
  emits: ['select'],
  methods: {
    dispatchSelectEvent(stream: VmsPluginCameraStream): void {
      this.$emit('select', stream);
    }
  }
});

function defineCamerasProp() {
  return arrayOf(object<VmsPluginCamerasRequest>()).isRequired;
}

function defineStreamsProp() {
  return arrayOf(object<VmsPluginCameraStream>().isRequired);
}
