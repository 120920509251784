
import { nextTick, reactive } from 'vue';
import { Options, Vue } from 'vue-class-component';
import { Prop, Ref, Watch } from 'vue-property-decorator';
import { Debounce } from '@/common/debounce-decorator';
import { NSelect } from '@/uikit';
import NAttachments from '@/uikit/attachments/NAttachments.vue';
import NButtonGroup from '@/uikit/buttons/NButtonGroup.vue';
import NForm from '@/uikit/forms/NForm.vue';
import { fileDownload } from '@/uikit/helpers';
import NTextareaAutosize from '@/uikit/textarea/NTextareaAutosize.vue';
import { WatchList } from '@/api';
import { dataModule } from '@/store/data';
import { BatchLoaderItem } from '@/pages/cards/batch-loader/BatchLoaderItem';
import { BatchLoaderModule } from '@/pages/cards/batch-loader/BatchLoaderModule';
import log from 'loglevel';

@Options({
  name: 'BatchLoaderModalCsv',
  components: { NAttachments, NForm }
})
export default class BatchLoaderModalCsv extends Vue {
  @Prop({ type: Object, required: true })
  readonly batchModule!: BatchLoaderModule;

  private layout = [
    {
      path: 'content',
      classes: 'n-form-w-6 control-m n-form-pad-10',
      i18n_label: 'cards.enter_cards_data',
      component: NTextareaAutosize,
      props: {
        i18n_placeholder: 'cards.enter_cards_format',
        i18n_description: 'cards.enter_cards_description'
      }
    },
    {
      path: 'watch_lists',
      classes: 'n-form-w-6  control-m n-form-pad-10',
      i18n_label: 'common.watch_lists',
      component: NSelect,
      validators: [{ name: 'required' }],
      props: {
        multiple: true,
        color: true,
        items: dataModule.watchListsWithoutUnknown.map((v: WatchList) => ({ label: v.name, value: v.id, color: '#' + v.color }))
      }
    },
    {
      path: 'parallel_upload',
      classes: 'n-form-pad-10',
      i18n_label: 'cards.parallel_upload',
      component: NButtonGroup,
      props: {
        items: [
          { value: 2, label: '2' },
          { value: 5, label: '5' },
          { value: 10, label: '10' },
          { value: 20, label: '20' }
        ]
      }
    }
  ];

  get loading() {
    return this.batchModule.loading;
  }

  get attachDisabled() {
    if (this.batchModule.paused) return false;
    return this.batchModule.loading;
  }

  downloadSample() {
    const url = 'ui-static/cars.csv';
    fileDownload(url, 'sample.csv');
  }

  parseText(content: string) {
    const items = content
      .split('\n')
      .map((v: string) => v.trim())
      .filter((v: string) => v != '')
      .map((v: string) => {
        return v.split(';').map((v) => v.trim());
      })
      .map((item: string[]) => {
        const batchLoaderItem = new BatchLoaderItem();
        batchLoaderItem._instance = this.batchModule;
        batchLoaderItem.objectType = this.batchModule.model.objectType;
        batchLoaderItem.fillAsItem(item[0], item[1] || '', item[2] || '');
        return batchLoaderItem;
      });
    this.batchModule.finished = false;
    this.batchModule.items = items;
  }

  parseFile(file: File) {
    const reader = new FileReader();
    reader.onload = (e) => {
      const content = e.target?.result as string;
      this.parseText(content);
    };
    reader.readAsText(file);
  }

  uploadDNDHandler(files: any) {
    this.parseFile(files[0].file);
  }

  selectFile(event: Event) {
    const element = event.currentTarget as HTMLInputElement;
    element.files && element.files?.[0] && this.parseFile(element.files[0]);
  }

  @Watch('batchModule.model.content')
  @Debounce(200)
  contentWatcher(content: string) {
    if (content.length) {
      this.parseText(content);
    }
  }
}
