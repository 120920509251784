
import { Options, Vue } from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import { ItemsActionName } from '@/definitions/app/item.actions.name';
import { ItemViewModel } from '@/definitions/view-models';
import { Group } from '@/api';
import { ItemAclResult, ModelAclResult } from '@/store/acl/types';
import { configModule } from '@/store/config';
import { dataModule } from '@/store/data';
import { actionHandler } from '@/store/data/ActionHandler';
import { actionsModule } from '@/store/data/ActionsModule';
import { MultisidebarItem } from '@/store/multisidebar/types';
import { Action } from '@/uikit';
import NForm from '@/uikit/forms/NForm.vue';
import NTabs from '@/uikit/tabs/NTabs.vue';
import { getItemCommonFields } from '@/components/common/get-item-common-fields';
import MultisidebarHeader from '@/components/multisidebar/MultisidebarHeader.vue';
import MultisidebarSaveActions from '@/components/multisidebar/MultisidebarSaveActions.vue';
import cameraGroupsLayout from '@/pages/groups/group-camera-groups';
import { adGroupFromItem, nameFromItem } from '@/pages/groups/group-information';
import permissionsLayout from '@/pages/groups/group-permissions';
import watchListsLayout from '@/pages/groups/group-watch-lists';

const Tabs = {
  Information: 'information',
  WatchLists: 'watch_lists',
  CameraGroups: 'camera_groups',
  Permissions: 'permissions'
};

@Options({
  name: 'GroupsSidebar',
  components: { MultisidebarHeader, MultisidebarSaveActions, NForm, NTabs }
})
export default class GroupsSidebar extends Vue {
  @Prop({ type: Object, required: true })
  readonly sidebarItem!: MultisidebarItem<ItemViewModel<Group>>;

  @Prop({ type: Object, required: true })
  readonly modelAcl!: ModelAclResult;

  tab = 'information';

  get isPermissionsFormDisabled() {
    const isPermissionTab = this.tab !== Tabs.Information;
    const isDisabledCauseItsAdminRole = this.model.item?.id === 1;
    return !this.computedModelAcl.update || (isPermissionTab && isDisabledCauseItsAdminRole);
  }

  get computedModelAcl() {
    return dataModule.isCurrentUserAdmin ? { view: true, update: true, delete: true, add: true } : this.modelAcl;
  }

  get model() {
    return this.sidebarItem.model;
  }

  get sidebarForm() {
    switch (this.tab) {
      case Tabs.WatchLists:
        return watchListsLayout;
      case Tabs.CameraGroups:
        return cameraGroupsLayout;
      case Tabs.Permissions:
        return permissionsLayout;
      default:
        return this.infoLayout;
    }
  }

  get infoLayout() {
    const base = this.isADEnabled ? [nameFromItem, adGroupFromItem] : [nameFromItem];
    !this.model.isNew && base.push(...getItemCommonFields());
    return base;
  }

  get isADEnabled() {
    return configModule.config.services.ffsecurity.active_directory;
  }

  get currentTabItems() {
    return this.editMode ? this.tabItems : this.tabItems.slice(0, 1);
  }

  get editMode() {
    return !this.model.isNew;
  }

  get tabItems() {
    const hasCameraGroups = configModule.features.cameragroups_enabled;
    const cameraGroupsTab = {
      name: Tabs.CameraGroups,
      label: this.$t(`common.${Tabs.CameraGroups}`, 'f')
    };

    return [
      {
        name: Tabs.Information,
        label: this.$t(`common.${Tabs.Information}`, 'f')
      },
      {
        name: Tabs.WatchLists,
        label: this.$t(`common.${Tabs.WatchLists}`, 'f')
      },
      hasCameraGroups ? cameraGroupsTab : undefined,
      {
        name: Tabs.Permissions,
        label: this.$t(`common.${Tabs.Permissions}`, 'f')
      }
    ].filter((v) => !!v);
  }

  get headerActions(): Action[] {
    return actionsModule
      .getItemActions(this.modelAcl, {
        hasDelete: true
      })
      .map(actionsModule.computeActionByName);
  }

  handleAction(actionName: ItemsActionName) {
    actionHandler.run(actionName, this.sidebarItem);
  }

  validateForm() {
    const result = this.$refs.form ? this.$refs.form.validate() : true;
    if (!result) {
      this.$refs.form.displayErrors();
    }
    return result;
  }

  created() {
    actionHandler.getIsItemFormValid = this.validateForm.bind(this);
  }
}
