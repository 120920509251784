
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { ItemsActionNames } from '@/definitions/app/item.actions.name';
import { viewModelRepository } from '@/api/common';
import { MultisidebarItemTypes } from '@/store/multisidebar/types';
import { actionHandler } from '@/store/data/ActionHandler';
import { RouterModule } from '@/store/router';
import CaseClusterItem from '@/pages/cases/case-clusters/CaseClusterItem.vue';
import { RelatedItem } from '@/components/case_clusters/types';
import { CaseCluster } from '@/api';
import Confidence from '@/components/common/Confidence.vue';

type CaseClusterItemWithConfidence = CaseCluster & { confidence: number };

@Options({
  name: 'CaseClusterConnections',
  components: { Confidence, CaseClusterItem }
})
export default class CaseClusterConnections extends Vue {
  @Prop({ type: Array, required: true })
  readonly items!: RelatedItem[];

  readonly module = viewModelRepository.getCaseClustersListViewModel();

  mounted() {
    if (this.items) {
      this.module.filter.current.id_in = this.items.map((v) => v.id);
      this.module.get();
    }
  }

  get computedItems(): CaseClusterItemWithConfidence[] {
    return this.module.items.map((v) => {
      const relatedItem = this.items.find((i) => i.id === v.id)!;
      return { ...v, confidence: relatedItem.confidence } as CaseClusterItemWithConfidence;
    });
  }

  actionHandler(item: any, type: any) {
    const isParticipant = !!item.role;
    if (type === 'click' && isParticipant) {
      actionHandler.run(ItemsActionNames.ShowItem, {
        type: MultisidebarItemTypes.CaseClusters,
        rawItem: item
      });
    }
    if (type === 'ShowCard') {
      actionHandler.run(ItemsActionNames.ShowItem, {
        type: MultisidebarItemTypes.CardsHumans,
        rawItem: item
      });
    }
    if (type === 'ShowCase') {
      // TODO: hardcoded data
      RouterModule.navigateToCase(2);
    }
  }
}
