import { NInput, NInputNumber } from '@/uikit';
import { IFormLayout } from '@/uikit/forms/NForm.vue';
import { getNumberValidator } from '@/uikit/forms/validators';
import CameraMap from '@/components/data-source/CameraMap.vue';
import { Camera } from '@/api';

const layout: IFormLayout = [
  {
    component: CameraMap,
    classes: 'n-form-w-100p',
    props: (item: Camera) => {
      return { item };
    }
  },
  [
    {
      path: 'latitude',
      classes: 'n-form-w-150 n-form-pad-10',
      i18n_label: 'ds.latitude',
      component: NInputNumber,
      props: { units: '°', textAlign: 'right', step: 0.1, min: -90, max: 90 },
      validators: [getNumberValidator({ required: false, min: -90, max: 90, float: true })]
    },
    {
      path: 'longitude',
      classes: 'n-form-w-150 n-form-pad-10',
      i18n_label: 'ds.longitude',
      component: NInputNumber,
      props: { units: '°', textAlign: 'right', step: 0.1, min: -180, max: 180 },
      validators: [getNumberValidator({ required: false, min: -180, max: 180, float: true })]
    },
    {
      path: 'azimuth',
      classes: 'n-form-w-150 n-form-pad-10',
      i18n_label: 'ds.azimuth',
      component: NInputNumber,
      props: { units: '°', textAlign: 'right', step: 0.1, min: 0, max: 359.9 },
      validators: [getNumberValidator({ required: false, min: 0, max: 359.9, float: true })]
    }
  ],
  [
    {
      path: 'angle_of_view',
      classes: 'n-form-w-150 n-form-pad-10',
      i18n_label: 'ds.angle_of_view',
      component: NInputNumber,
      props: { units: '°', textAlign: 'right', step: 1, min: 0, max: 359 },
      validators: [getNumberValidator({ required: false, min: 0, max: 359, float: true })]
    },
    {
      path: 'pitch',
      classes: 'n-form-w-150 n-form-pad-10',
      i18n_label: 'ds.pitch',
      component: NInputNumber,
      props: { units: '°', textAlign: 'right', step: 1, min: -90, max: 90 },
      validators: [getNumberValidator({ required: false, min: -90, max: 90, float: true })]
    }
  ],
  [
    {
      path: 'address',
      classes: 'n-form-w-6 n-form-pad-10',
      i18n_label: 'common.address',
      component: NInput,
      props: { clearable: true }
    }
  ]
];

export default layout;
