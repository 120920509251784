
import { defineComponent, ref } from 'vue';
import { integer } from 'vue-types';
import NButton from '@/uikit/buttons/NButton.vue';
import NModalWindow from '@/uikit/modal-window/NModalWindow.vue';
import { CameraLookupModel } from '../types';
import { defineAuthenticatorProp } from '../utils';
import CameraLookupAuthenticationForm from './CameraLookupAuthenticationForm.vue';

type CameraLookupAuthenticationFormInstance = InstanceType<typeof CameraLookupAuthenticationForm>;

type CameraLookupAuthenticationWindowState = {
  login: string;
  password: string;
  running: boolean;
};

const pass = () => {};

export default defineComponent({
  name: 'CameraLookupAuthenticationWindow',
  components: {
    CameraLookupAuthenticationForm,
    NButton,
    NModalWindow
  },
  props: {
    authenticator: defineAuthenticatorProp(),
    id: integer().isRequired
  },
  emits: ['close', 'done'],
  data: (): CameraLookupAuthenticationWindowState => ({ login: '', password: '', running: false }),
  setup: () => ({ form: ref<CameraLookupAuthenticationFormInstance | null>(null) }),
  methods: {
    authenticate(): void {
      this.running = true;
      this.authenticator(this.id, this.login, this.password)
        .then(this.dispatchDoneEvent)
        .catch(pass)
        .finally(() => (this.running = false));
    },
    dispatchCloseEvent(): void {
      this.$emit('close');
    },
    dispatchDoneEvent(camera: CameraLookupModel): void {
      this.$emit('done', camera);
    },
    submit(): void {
      this.form?.validate() && this.authenticate();
    }
  }
});
