
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { EventOrEpisode } from '@/components/events/types';
import { EventView } from '@/components/events/adapter';
import { ModelAclResult } from '@/store/acl/types';

import NForm from '@/uikit/forms/NForm.vue';
import formLayout from './EventGeoForm';
import CommonMap from '@/components/map/CommonMap.vue';
import { CommonMapConfig } from '@/components/map/CommonMapConfig';
import EventMarker from '@/components/events/map/EventMarker.vue';
import EventClusterMarker from '@/components/events/map/EventClusterMarker.vue';
import SmallEventItem from '@/components/events/map/SmallEventItem.vue';
import EventItemShort from '@/components/events/EventItemShort.vue';
import { ItemsActionName } from '@/definitions/app/item.actions.name';
import { FaceEvent, HumanCard } from '@/api';
import { MultisidebarItem } from '@/store/multisidebar/types';
import { ItemViewModel } from '@/definitions/view-models';
import { viewModelRepository } from '@/api/common';
import { Item } from '@/pages/events/EventsSidebar.vue';
import { multisidebarModule } from '@/store/multisidebar';

@Options({
  name: 'EventGeo',
  components: { CommonMap, NForm }
})
export default class EventGeo extends Vue {
  @Prop({ type: Object, required: true })
  readonly item!: EventOrEpisode;

  @Prop({ type: Object, required: true })
  readonly sidebarItem!: MultisidebarItem<ItemViewModel<Item>>;

  @Prop({ type: Object, required: true })
  readonly eventView!: EventView;

  @Prop({ type: String, required: true })
  readonly objectType!: string;

  @Prop({ type: String, required: true })
  readonly cardType!: string;

  @Prop({ type: Object, required: true })
  readonly modelAcl!: ModelAclResult;

  get module() {
    const module = viewModelRepository.getEventsFacesListViewModel();
    module.items = [this.eventView as any];
    module.loaded = true;
    return module;
  }

  get mapConfig() {
    const result: CommonMapConfig = {
      sidebarTitle_i18n: 'common.events',
      module: this.module,
      mode: 'simple',
      msbType: multisidebarModule.currentItem?.type as any,
      centerPoint: { lat: Number(this.eventView.latitude), lng: Number(this.eventView.longitude) },
      minZoom: 15,
      colorsConfig: {
        default: {
          new: '#f00',
          default: '#c00',
          old: '#600'
        }
      },
      ItemMarker: EventMarker,
      ClusterMarker: EventClusterMarker,
      PopupItem: SmallEventItem,
      ListItem: EventItemShort,
      itemProps: {
        objects: this.objectType,
        // episodeType: this.episodeType,
        type: this.sidebarItem.type,
        acknowledgeAllowed: false,
        eventSelected: (item: any) => true,
        openedItem: (item: any) => true,
        selectedItem: (item: any) => true
      },
      actionHandler: async (actionName: ItemsActionName, item: FaceEvent | HumanCard) => {
        // console.log('Action handler of config: ', actionName, item);
      }
    };

    return result;
  }

  get formLayout() {
    return formLayout;
  }
}
