
import { nextTick, reactive } from 'vue';
import { Options, Vue } from 'vue-class-component';
import { Prop, Ref, Watch } from 'vue-property-decorator';
import { Line } from '@/api/models/Line';
import NModalWindow from '@/uikit/modal-window/NModalWindow.vue';
import LinesROI from '@/pages/lines/LinesROI.vue';

@Options({
  name: 'LinesROIModal',
  components: { LinesROI, NModalWindow }
})
export default class LinesROIModal extends Vue {
  @Prop({ type: Object, required: true })
  readonly model!: Line;

  @Prop({ type: Boolean, default: false })
  readonly disabled!: boolean;

}
