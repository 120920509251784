import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "multisidebar-content__main" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MultisidebarHeader = _resolveComponent("MultisidebarHeader")!
  const _component_RemoteMonitoringEventSidebarContent = _resolveComponent("RemoteMonitoringEventSidebarContent")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_MultisidebarHeader, {
      "model-acl": {},
      label: _ctx.headerLabel,
      changed: false,
      actions: _ctx.actions,
      onAction: _cache[0] || (_cache[0] = (v) => _ctx.actionHandler(v, _ctx.sidebarItem))
    }, null, 8, ["label", "actions"]),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_RemoteMonitoringEventSidebarContent, {
        ref: "content",
        item: _ctx.currentItem,
        "model-acl": _ctx.modelAcl,
        onAction: _cache[1] || (_cache[1] = (v, p) => _ctx.actionHandler(v, p))
      }, null, 8, ["item", "model-acl"])
    ])
  ], 64))
}