
import { Options, Vue } from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import { ItemsActionName } from '@/definitions/app/item.actions.name';
import { ItemViewModel } from '@/definitions/view-models';
import { ModelAclResult } from '@/store/acl/types';
import { actionHandler } from '@/store/data/ActionHandler';
import { MultisidebarItem, MultisidebarSharedState } from '@/store/multisidebar/types';
import MultisidebarHeader from '@/components/multisidebar/MultisidebarHeader.vue';
import { AnnexAlarm } from '@/thirdpaties/annex/annex.types';
import NTabs from '@/uikit/tabs/NTabs.vue';
import { adaptItem } from '@/pages/annex/alarms/AnnexAlarmAdapter';
import SidebarSection from '@/pages/annex/sidebar/SidebarSection.vue';
import NThumbnail from '@/uikit/thumbnail/NThumbnail.vue';
import Confidence from '@/components/common/Confidence.vue';
import CardItemRow from '@/components/cards/CardItemRow.vue';
import MatchedCardHelper from '@/common/MatchedCardHelper';
import AnnexAlarmAcknowledgeInfo from '@/pages/annex/alarms/AnnexAlarmAcknowledgeInfo.vue';
import NButton from '@/uikit/buttons/NButton.vue';
import { annexAlarmActionHandler } from '@/pages/annex/alarms/AnnexAlarmActionHandler';
import NIcon from '@/uikit/icons/NIcon.vue';
import MultisidebarActions from '@/components/multisidebar/MultisidebarActions.vue';
import AnnexAlarmFeatures from '@/pages/annex/alarms/AnnexAlarmFeatures.vue';
import NImageViewerBboxLayer from '@/uikit/image-viewer/NImageViewerBboxLayer.vue';
import NImageViewerLineLayer from '@/uikit/image-viewer/NImageViewerLineLayer.vue';
import SimpleOverlay from '@/pages/annex/sidebar/SimpleOverlay.vue';
import { EventDetails, ThumbnailPositions } from '@/uikit/thumbnail';

@Options({
  name: 'AnnexAlarmSidebar',
  components: {
    SimpleOverlay,
    NImageViewerLineLayer,
    NImageViewerBboxLayer,
    AnnexAlarmFeatures,
    MultisidebarActions,
    NIcon,
    NButton,
    AnnexAlarmAcknowledgeInfo,
    CardItemRow,
    Confidence,
    NThumbnail,
    SidebarSection,
    NTabs,
    MultisidebarHeader
  },
  emits: []
})
export default class AnnexAlarmSidebar extends Vue {
  @Prop({ type: Object, required: true })
  readonly sidebarItem!: MultisidebarItem<ItemViewModel<AnnexAlarm>>;

  @Prop({ type: Object, required: true })
  readonly sidebarSharedState!: MultisidebarSharedState;

  @Prop({ type: Object, required: true })
  readonly modelAcl!: ModelAclResult;

  matchedCardHelper = new MatchedCardHelper();

  get card() {
    return this.matchedCardHelper.card;
  }

  get item() {
    return this.sidebarItem.model.item as AnnexAlarm;
  }

  get adaptedItem() {
    return adaptItem(this.item);
  }

  get history() {
    const comment = this.item.comment;
    if (comment) {
      const position = comment.indexOf(';');
      if (position !== -1) {
        return {
          acknowledge: comment.substring(0, position),
          complete: comment.substring(position + 1)
        };
      }
    }
    return {
      acknowledge: comment,
      complete: ''
    };
  }

  get thumbnailActions() {
    const result = [{ icon: 'eye-open', name: EventDetails.ShowFullScreen, position: ThumbnailPositions.BottomRight }];
    return result;
  }

  get headerClasses() {
    return {
      tag_new: this.adaptedItem.status === 'new',
      tag_in_work: this.adaptedItem.status === 'in_work' && this.item.ack_author,
      tag_in_work_auto: this.adaptedItem.status === 'in_work' && !this.item.ack_author,
      tag_completed: this.adaptedItem.status === 'completed'
    };
  }

  get tabItems() {
    const result = [
      {
        name: 'info',
        label: this.$t('annex_alarms.info', 'f')
      },
      {
        name: 'history',
        label: this.$t('annex_alarms.history', 'f')
      }
    ];
    return result;
  }

  get formattedDate(): string {
    return this.$filters.formatDateTime(this.item.declared_at);
  }

  get confidence() {
    return this.item.extra?.match?.confidence;
  }

  async mounted() {
    this.matchedCardHelper.type = this.item.extra?.object_type;
    this.matchedCardHelper.id = this.item.extra?.match?.card_id;
  }

  get cardId() {
    return this.item.extra?.match?.card_id;
  }
  @Watch('cardId')
  async changeCardHandler(v: number | null) {
    this.matchedCardHelper.type = this.item.extra?.object_type;
    this.matchedCardHelper.id = v;
  }

  actionHandler(action: string) {
    annexAlarmActionHandler(action, this.item);
  }

  handleAction(v: ItemsActionName) {
    actionHandler.run(v, this.sidebarItem);
  }
}
