
import { Options, Vue } from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import { Camera, ExternalVmsService } from '@/api';
import NSelect from '@/uikit/select/NSelect.vue';

@Options({
  name: 'ExternalVmsStreamSelect',
  components: { NSelect },
  emits: []
})
export default class ExternalVmsStreamSelect extends Vue {
  @Prop({ type: Number, default: null })
  readonly modelValue!: number | null;

  @Prop({ type: Object, required: true })
  readonly model!: Camera;

  protected options: { label: string; value: number }[] = [];

  get hasCamera() {
    return this.model.external_vms_camera_id;
  }

  @Watch('model.external_vms_camera_id', { immediate: true })
  async initOptions() {
    if (this.hasCamera) {
      let response = await ExternalVmsService.externalVmsCamerasList(this.model.external_vms!);
      const camera = response.results?.find((v) => String(v.id) === this.model.external_vms_camera_id!);
      this.options = camera?.streams.map((v: any) => ({ label: v.url, value: v.url })) || [];
    }
  }

  updateValue(value: number) {
    this.$emit('update:modelValue', value);
  }
}
