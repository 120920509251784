
import { nextTick, reactive } from 'vue';
import { Options, Vue } from 'vue-class-component';
import { Prop, Ref, Watch } from 'vue-property-decorator';

@Options({
  name: 'SidebarSection'
})
export default class SidebarSection extends Vue {
  @Prop({ type: String, required: true })
  readonly title!: string;

  @Prop({ type: String, required: true })
  readonly date!: string;

  get formattedDate(): string {
    return this.$filters.formatDateTime(this.date);
  }
}
