
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { ItemsActionName } from '@/definitions/app/item.actions.name';
import { ItemViewModel } from '@/definitions/view-models';
import { Counter } from '@/api';
import { ModelAclResult } from '@/store/acl/types';
import { actionHandler } from '@/store/data/ActionHandler';
import { actionsModule } from '@/store/data/ActionsModule';
import { MultisidebarItem } from '@/store/multisidebar/types';
import NTabs from '@/uikit/tabs/NTabs.vue';
import NTextDelimiter from '@/uikit/text/NTextDelimiter.vue';
import CommonStatus from '@/components/common/CommonStatus.vue';
import CounterInfo from '@/components/counters/CounterInfo.vue';
import MultisidebarHeader from '@/components/multisidebar/MultisidebarHeader.vue';
import MultisidebarSaveActions from '@/components/multisidebar/MultisidebarSaveActions.vue';
import CounterForm from '@/pages/counters/CounterForm.vue';
import CounterRecords from '@/pages/counters/CounterRecords.vue';
import CounterROI from '@/pages/counters/CounterROI.vue';
import CounterROIList from '@/pages/counters/CounterROIList.vue';

@Options({
  name: 'CounterSidebar',
  components: {
    CommonStatus,
    CounterForm,
    CounterInfo,
    CounterROI,
    CounterROIList,
    CounterRecords,
    MultisidebarHeader,
    MultisidebarSaveActions,
    NTabs,
    NTextDelimiter
  }
})
export default class CounterSidebar extends Vue {
  @Prop({ type: Object, required: true })
  readonly sidebarItem!: MultisidebarItem<ItemViewModel<Counter>>;

  @Prop({ type: Object, required: true })
  readonly modelAcl!: ModelAclResult;

  activeTab = 'information';

  get moduleItem() {
    return this.sidebarItem.model;
  }
  get currentItem() {
    return this.sidebarItem.model.item;
  }

  get disabled() {
    return !(this.moduleItem.isNew ? this.modelAcl.add : this.modelAcl.update);
  }

  get headerActions() {
    return actionsModule
      .getItemActions(this.modelAcl, {
        hasChanges: this.moduleItem.hasChanges,
        hasActive: true,
        hasDelete: true,
        currentActive: this.currentItem?.active
      })
      .map(actionsModule.computeActionByName);
  }

  get tabItems() {
    return this.moduleItem.isNew
      ? [
          {
            name: 'information',
            label: this.$t('counters.information', 'f')
          }
        ]
      : [
          {
            name: 'records',
            label: this.$t('counters.records', 'f')
          },
          {
            name: 'information',
            label: this.$t('counters.information', 'f')
          },
          {
            name: 'roi',
            label: this.$t('counters.roi', 'f')
          }
        ];
  }

  actionHandler(actionName: ItemsActionName, payload?: any) {
    actionHandler.run(actionName, payload);
  }

  validateForm() {
    // TODO: на вкладке ROI нет формы для валидации
    // нужно придумать решение как поступать в таких случаях
    if (this.activeTab === 'roi') {
      return true;
    }
    return this.$refs.form.validate();
  }

  mounted() {
    actionHandler.getIsItemFormValid = this.validateForm.bind(this);
  }
}
