import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "simple-overlay" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ImageViewer = _resolveComponent("ImageViewer")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(), _createBlock(_component_ImageViewer, {
      key: _ctx.imageUrl,
      src: _ctx.imageUrl,
      bboxes: _ctx.adaptedBBoxes,
      line: _ctx.adaptedLine,
      track: _ctx.adaptedTrack,
      actions: [],
      zoomable: false,
      draggable: false,
      "always-show-track": true
    }, null, 8, ["src", "bboxes", "line", "track"]))
  ]))
}