import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "geo-event-sidebar" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CommonMap = _resolveComponent("CommonMap")!
  const _component_NForm = _resolveComponent("NForm")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_CommonMap, {
      config: _ctx.mapConfig,
      class: "geo-event-sidebar__map"
    }, null, 8, ["config"]),
    _createVNode(_component_NForm, {
      ref: "form",
      layout: _ctx.formLayout,
      model: _ctx.eventView,
      disabled: true
    }, null, 8, ["layout", "model"])
  ]))
}