
import without from 'lodash/without';
import { computed, defineComponent, inject, ref, Ref, unref, VNode } from 'vue';
import { bool } from 'vue-types';
import { VmsPluginCameraStream } from '@/api/models/VmsPluginCamerasRequest';
import NButton from '@/uikit/buttons/NButton.vue';
import NCheckbox from '@/uikit/checkbox/NCheckbox.vue';
import { copyTextToClipboard } from '@/uikit/helpers';
import { NTable, NTableBodyCell, NTableColumn, NTableSelectionColumnFactory } from '@/uikit/table-v2';
import { defineStreamsProp } from '@/pages/external-vms/components/sidebar-cameras/utils';
import { VmsSidebarCamerasTabListItemStreamTableStatus } from './components';

type VmsSidebarCamerasTabListItemStreamTableState = {
  selectionsSchema: NTableSelectionColumnFactory<VmsPluginCameraStream>;
};

export default defineComponent({
  name: 'VmsSidebarCamerasTabListItemStreamTable',
  components: { NTable },
  props: {
    added: bool().def(false),
    streams: defineStreamsProp()
  },
  emits: ['update:selected', 'select'],
  data: (): VmsSidebarCamerasTabListItemStreamTableState => ({
    selectionsSchema: createTableSelectionColumn
  }),
  setup: () => ({
    select: inject<(selected: VmsPluginCameraStream[]) => void>('select'),
    selected: inject<Ref<VmsPluginCameraStream[]>>('streams', ref([]))
  }),
  computed: {
    columns(): NTableColumn<VmsPluginCameraStream>[] {
      return [this.createUrlTableColumn(), this.createStatusTableColumn(), this.createAddButtonTableColumn()];
    }
  },
  methods: {
    createUrlTableColumn(): NTableColumn<VmsPluginCameraStream> {
      return {
        body: {
          content: ({ model }) => (
            <p class="label-s vms-sidebar-cameras-tab-list-item-stream-table__url">
              {model.url}&nbsp;
              <NButton {...{ icon: 'copy', iconSize: 12, onClick: () => this.copyStreamUrlToClipboard(model.url), type: 'link' }} />
            </p>
          )
        },
        width: '1fr'
      };
    },
    createStatusTableColumn(): NTableColumn<VmsPluginCameraStream> {
      return {
        body: () => <VmsSidebarCamerasTabListItemStreamTableStatus added={this.added} />
      };
    },
    createAddButtonTableColumn(): NTableColumn<VmsPluginCameraStream> {
      return {
        body: {
          content: ({ model }) => (
            <NButton
              {...{ icon: 'add', onClick: () => this.dispatchSelectEvent(model), type: 'text' }}
              class="vms-sidebar-cameras-tab-list-item-stream-table__add"
            />
          ),
          style: { paddingLeft: 0, paddingRight: 0 }
        }
      };
    },
    copyStreamUrlToClipboard(url: string): void {
      copyTextToClipboard(url);
    },
    dispatchSelectEvent(stream: VmsPluginCameraStream): void {
      this.$emit('select', stream);
    }
  }
});

export function createTableSelectionColumn(
  selected: Ref<Readonly<VmsPluginCameraStream[]>>,
  _content: Ref<Readonly<VmsPluginCameraStream[]>>,
  update: (selected: VmsPluginCameraStream[]) => void
): NTableColumn<VmsPluginCameraStream> {
  const selectedSet = computed(() => new Set(unref(selected)));

  function renderBodyCheckbox({ model }: NTableBodyCell<VmsPluginCameraStream>): VNode {
    const props = {
      'onUpdate:modelValue': (state: boolean) => {
        update(state ? [...unref(selected), model] : without(unref(selected), model));
      },
      modelValue: unref(selectedSet).has(model)
    };
    return <NCheckbox {...props} />;
  }

  return {
    body: {
      content: renderBodyCheckbox,
      style: { paddingLeft: 0, paddingRight: 0 }
    }
  };
}
