import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createBlock as _createBlock, normalizeClass as _normalizeClass, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "camera-map__actions" }
const _hoisted_2 = {
  key: 0,
  class: "camera-map__actions-start"
}
const _hoisted_3 = { class: "camera-map__actions-end" }
const _hoisted_4 = {
  key: 0,
  class: "map__button-wrapper"
}
const _hoisted_5 = { class: "map__button-wrapper" }
const _hoisted_6 = { class: "camera-map__map-container" }
const _hoisted_7 = { class: "camera-map__bar" }
const _hoisted_8 = { class: "map__button-wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MapProviderSelect = _resolveComponent("MapProviderSelect")!
  const _component_NButton = _resolveComponent("NButton")!
  const _component_MapSearch = _resolveComponent("MapSearch")!
  const _component_CameraMarkerEditable = _resolveComponent("CameraMarkerEditable")!
  const _component_Map = _resolveComponent("Map")!

  return (_ctx.mapEnabled)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: _normalizeClass(['camera-map', { 'camera-map_fullscreen': _ctx.isFullscreen }]),
        ref: "cameraMap"
      }, [
        _createElementVNode("div", _hoisted_1, [
          (!_ctx.isFullscreen)
            ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                _createVNode(_component_MapProviderSelect, {
                  modelValue: _ctx.providerId,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.providerId) = $event))
                }, null, 8, ["modelValue"])
              ]))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_3, [
            (_ctx.hasChanges)
              ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                  _createVNode(_component_NButton, {
                    type: "secondary",
                    icon: "revert",
                    onAction: _ctx.resetFields
                  }, null, 8, ["onAction"])
                ]))
              : _createCommentVNode("", true),
            _createElementVNode("div", _hoisted_5, [
              _createVNode(_component_NButton, {
                type: "secondary",
                icon: _ctx.isFullscreen ? 'fullscreen-exit' : 'fullscreen',
                onAction: _ctx.toggleFullscreen
              }, null, 8, ["icon", "onAction"])
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("div", _hoisted_7, [
            _createElementVNode("div", _hoisted_8, [
              _createVNode(_component_MapSearch, { onSelect: _ctx.handleSearchSelect }, null, 8, ["onSelect"])
            ])
          ]),
          _createVNode(_component_Map, {
            class: _normalizeClass(['camera-map__map', { 'camera-map__map_fullscreen': _ctx.isFullscreen }]),
            "start-center": _ctx.showCamera ? _ctx.latLng : null,
            "provider-id": _ctx.providerId,
            "restore-view": !_ctx.showCamera,
            "auto-locate-center": "",
            "locate-on": "",
            "to-target-on": "",
            onToTarget: _ctx.setCameraPosition,
            onMapReady: _ctx.handleMapReady
          }, {
            default: _withCtx(({ map }) => [
              (_ctx.showCamera)
                ? (_openBlock(), _createBlock(_component_CameraMarkerEditable, {
                    key: 0,
                    disabled: _ctx.disabled,
                    map: map,
                    "lat-lng": _ctx.latLng,
                    "onUpdate:lat-lng": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.latLng) = $event)),
                    rotation: _ctx.item.azimuth,
                    "onUpdate:rotation": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.item.azimuth) = $event)),
                    angle: _ctx.item.angle_of_view,
                    "onUpdate:angle": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.item.angle_of_view) = $event))
                  }, null, 8, ["disabled", "map", "lat-lng", "rotation", "angle"]))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }, 8, ["class", "start-center", "provider-id", "restore-view", "onToTarget", "onMapReady"])
        ])
      ], 2))
    : _createCommentVNode("", true)
}