
import { Options, Vue } from 'vue-class-component';
import { LMarker, LIcon } from '@vue-leaflet/vue-leaflet';
import { getAngle, getAnglePoints, getConePath, rotatePoint } from './helpers';
import { Prop } from 'vue-property-decorator';
import { PointAsArray } from '@/uikit/draw/types';
import { LatLngType } from './types';
import { Map as TMap } from 'leaflet';
import { generateV4UUID } from '@/common/uuid';

function hasParentDataId(el: any, ids: string[]): boolean {
  return ids.includes(el.dataset?.id) || (el.parentNode && hasParentDataId(el.parentNode, ids));
}

@Options({
  name: 'CameraMarkerEditable',
  components: {
    LMarker,
    LIcon
  },
  emits: ['update:latLng', 'update:angle', 'update:rotation']
})
export default class CameraMarkerEditable extends Vue {
  @Prop({ type: Object, required: true })
  readonly latLng!: LatLngType;

  @Prop({ type: Object, required: true })
  readonly map!: TMap;

  @Prop({ type: Number, default: 72 })
  readonly angle!: number;

  @Prop({ type: Number, default: 0 })
  readonly rotation!: number;

  @Prop({ type: Boolean, default: 0 })
  readonly disabled!: boolean;

  markerPartIds = {
    left: 'left-point',
    right: 'right-point',
    middle: 'middle-point',
    bucket: 'bucket'
  };

  radius = 110;
  canvasOffset = 10;
  size = (this.radius + this.canvasOffset) * 2;
  angleAdjustOn = false;
  rotationOn = false;
  marker: any = null;
  markerId = generateV4UUID();

  beforeUnmount() {
    document.removeEventListener('mouseup', this.handleMouseUp.bind(this));
    document.removeEventListener('mousemove', this.handleMouseMove.bind(this));
    document.removeEventListener('mouseleave', this.handleMouseOut.bind(this));
  }

  created() {
    document.addEventListener('mouseup', this.handleMouseUp.bind(this));
    document.addEventListener('mousemove', this.handleMouseMove.bind(this));
    document.addEventListener('mouseleave', this.handleMouseOut.bind(this));
  }

  get options(): Record<string, any> {
    return { autoPanOnFocus: false };
  }

  get center(): PointAsArray {
    return [this.radius + this.canvasOffset, this.radius + this.canvasOffset];
  }

  get bucketOffset(): string {
    return `translate(${this.radius - this.canvasOffset}, ${this.radius - this.canvasOffset + 3})`;
  }

  get middlePoint(): PointAsArray {
    return [this.center[0], this.center[1] - this.radius];
  }

  get anglePoints() {
    return getAnglePoints(this.angle, this.radius, this.center);
  }

  get conePath() {
    return getConePath(this.angle, this.radius, this.center);
  }

  get iconSvgStyle() {
    return {
      transform: this.rotation ? `rotate(${Math.round(this.rotation)}deg)` : ''
    };
  }

  setMarker(marker: any) {
    this.marker = marker;
  }

  restoreDefaultState() {
    this.angleAdjustOn = false;
    this.rotationOn = false;
    !this.disabled && this.marker?.dragging?.enable();
    this.map?.dragging?.enable();
  }

  handleDragEnd(e: any) {
    this.$emit('update:latLng', e.target.getLatLng());
  }

  handleMouseOut() {
    this.restoreDefaultState();
  }

  handleMouseUp() {
    this.restoreDefaultState();
  }

  handleMouseDown(e: any) {
    if (this.disabled) return;
    const element = e.originalEvent.target;
    this.angleAdjustOn = hasParentDataId(element, [this.markerPartIds.left, this.markerPartIds.right]);
    this.rotationOn = hasParentDataId(element, [this.markerPartIds.middle]);
    hasParentDataId(element, [this.markerPartIds.bucket]) ? this.marker.dragging?.enable() : this.marker.dragging?.disable();
    if (this.angleAdjustOn || this.rotationOn) {
      this.map?.dragging.disable();
    }
  }

  handleMouseMove(e: MouseEvent) {
    if (this.disabled) return;
    if (!this.angleAdjustOn && !this.rotationOn) return;
    const markerRect = document.getElementById(this.markerId)?.getBoundingClientRect();

    if (!markerRect) return;

    if (this.angleAdjustOn) {
      const mousePoint = [e.clientX - markerRect.left, e.clientY - markerRect.top] as PointAsArray;
      const middlePoint = this.rotation ? rotatePoint(this.middlePoint, -this.rotation, this.center) : this.middlePoint;
      const angle = getAngle(middlePoint as PointAsArray, this.center, mousePoint, false);
      this.$emit('update:angle', (180 - angle) * 2);
    }

    if (this.rotationOn) {
      const centerPoint = this.map.latLngToLayerPoint(this.latLng);
      const mousePoint = this.map.mouseEventToLayerPoint(e);
      const leftPoint = [centerPoint.x, centerPoint.y + this.radius] as PointAsArray;
      const angle = getAngle(leftPoint, [centerPoint.x, centerPoint.y], [mousePoint.x, mousePoint.y]);
      this.$emit('update:rotation', Number(angle.toFixed(2)));
    }
  }
}
