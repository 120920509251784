
import { Options, Vue } from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import { ItemsActionName } from '@/definitions/app/item.actions.name';
import { ListViewModel } from '@/definitions/view-models';
import { DailySearchEvent } from '@/api/models/DailySearchEvent';
import { aclModule } from '@/store/acl';
import { CardTypesMap, ObjectsTypesMap } from '@/store/application/data.assets';
import { actionHandler } from '@/store/data/ActionHandler';
import { loadDataModule } from '@/store/data/LoadDataModule';
import { multisidebarModule } from '@/store/multisidebar';
import { MultisidebarItemTypes } from '@/store/multisidebar/types';
import { NDateTimeLabel, NButton } from '@/uikit';
import NThumbnail from '@/uikit/thumbnail/NThumbnail.vue';
import CardItemProxy from '@/components/cards/CardItemProxy.vue';
import CardItemTile from '@/components/cards/CardItemTile.vue';
import Confidence from '@/components/common/Confidence.vue';
import ConfidenceDelimiter from '@/components/common/ConfidenceDelimiter.vue';

@Options({
  name: 'RemoteDailyEventItem',
  computed: {
    ObjectsTypesMap() {
      return ObjectsTypesMap;
    }
  },
  components: { CardItemProxy, CardItemTile, Confidence, ConfidenceDelimiter, NButton, NDateTimeLabel, NThumbnail },
  emits: ['showImage']
})
export default class RemoteDailyEventItem extends Vue {
  @Prop({ type: String, default: 'full' })
  readonly type = 'full';

  @Prop({ type: Object, required: true })
  readonly item!: DailySearchEvent;

  cardObjectsVM: ListViewModel<any, any> | null = null;

  private hasCard = true;

  get pageSidebarType() {
    return MultisidebarItemTypes.CardsHumans;
  }

  get sidebarModule() {
    return multisidebarModule;
  }

  async actionHandler(id: string | number, action: ItemsActionName, payload?: any): Promise<void> {
    switch (action) {
      default:
        actionHandler.run(action, { type: this.pageSidebarType, rawItem: this.item.id }); // todo: check
        break;
    }
  }

  get modelAcl() {
    return aclModule.getModelAclByName('humancard');
  }

  mounted() {
    this.load();
  }

  load() {
    if (!this.item.card) return;
    this.cardObjectsVM = loadDataModule.getObjectsLVMByCardID({ id: this.item.card, limit: 1, type: 'faces', ordering: 'id' });
  }

  get cardThumbnail(): string | undefined {
    const hasItems = this.cardObjectsVM?.items?.length;
    return hasItems ? this.cardObjectsVM!.items[0]?.thumbnail : undefined;
  }

  showItemImage() {
    this.$emit('showImage', this.item);
  }

  showCardImage() {
    this.$emit('showImage', this.cardObjectsVM!.items[0]);
  }
}
