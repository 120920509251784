
import { nextTick, reactive } from 'vue';
import { Options, Vue } from 'vue-class-component';
import { Prop, Ref, Watch } from 'vue-property-decorator';

@Options({
  name: 'NDateTimeText'
})
export default class NDateTimeText extends Vue {
  @Prop({ type: String, required: true })
  readonly modelValue!: string;
}
