import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, normalizeStyle as _normalizeStyle, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_VideoPlayer = _resolveComponent("VideoPlayer")!
  const _component_CameraScreenshot = _resolveComponent("CameraScreenshot")!
  const _component_NButton = _resolveComponent("NButton")!

  return (_openBlock(), _createElementBlock("div", {
    style: _normalizeStyle({ '--video-height': _ctx.videoHeight })
  }, [
    (_ctx.active && !_ctx.casesEnabled)
      ? (_openBlock(), _createBlock(_component_VideoPlayer, {
          key: 0,
          class: "video-processing-player__video",
          model: "videos",
          cameraId: _ctx.item.id,
          "time-from": 0,
          "reconnect-on-close": true,
          "show-controls": false,
          "show-timeline": false,
          "show-bottom-bar": false
        }, null, 8, ["cameraId"]))
      : (_openBlock(), _createBlock(_component_CameraScreenshot, {
          key: 1,
          "model-value": _ctx.item.screenshot,
          refreshKey: _ctx.statusLabelValue,
          width: "520",
          displayWidth: "520px"
        }, null, 8, ["model-value", "refreshKey"])),
    (!_ctx.item.case)
      ? (_openBlock(), _createBlock(_component_NButton, {
          key: 2,
          class: "video-processing-player__navigate-button",
          onAction: _ctx.navigateToVideoEvents,
          type: "link"
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('ds.open_video_events')), 1)
          ]),
          _: 1
        }, 8, ["onAction"]))
      : _createCommentVNode("", true)
  ], 4))
}