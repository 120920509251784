
import { defineComponent } from 'vue';
import { bool } from 'vue-types';
import { NHint } from '@/uikit/hint';

export default defineComponent({
  name: 'VmsSidebarCamerasTabListItemStreamTableStatus',
  components: { NHint },
  props: {
    added: bool().def(false)
  },
  computed: {
    content(): string {
      return this.$t(`external_vms.${this.added ? 'added' : 'not_added'}`, 'f');
    }
  }
});
