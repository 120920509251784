import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "multisidebar-content__main" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MultisidebarHeader = _resolveComponent("MultisidebarHeader")!
  const _component_CasePhotoSidebarContent = _resolveComponent("CasePhotoSidebarContent")!
  const _component_MultisidebarSaveActions = _resolveComponent("MultisidebarSaveActions")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_MultisidebarHeader, {
      "model-acl": {},
      label: _ctx.currentItem.name,
      changed: _ctx.sidebarItem.model.hasChanges,
      actions: _ctx.actions,
      disableActions: _ctx.isActionsDisabled,
      onAction: _cache[0] || (_cache[0] = (v) => _ctx.actionHandler(v, _ctx.sidebarItem))
    }, null, 8, ["label", "changed", "actions", "disableActions"]),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_CasePhotoSidebarContent, {
        ref: "content",
        item: _ctx.currentItem,
        onAction: _ctx.actionHandler,
        disabled: _ctx.isActionsDisabled
      }, null, 8, ["item", "onAction", "disabled"])
    ]),
    _createVNode(_component_MultisidebarSaveActions, {
      "sidebar-item": _ctx.sidebarItem,
      "model-acl": _ctx.modelAcl
    }, null, 8, ["sidebar-item", "model-acl"])
  ], 64))
}