
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { CarCard, HumanCard } from '@/api';
import NButton from '@/uikit/buttons/NButton.vue';
import NModalWindow from '@/uikit/modal-window/NModalWindow.vue';
import NTextDelimiter from '@/uikit/text/NTextDelimiter.vue';
import CardItemTile from '@/components/cards/CardItemTile.vue';
import ThumbnailItem from '@/components/common/ThumbnailItem.vue';

@Options({
  name: 'CardsDuplicateDialog',
  components: { CardItemTile, NButton, NModalWindow, NTextDelimiter, ThumbnailItem },
  emits: ['addToCard', 'close']
})
export default class CardsDuplicateDialog extends Vue {
  @Prop({ type: String })
  image!: string;

  @Prop({ type: Array })
  cards!: HumanCard[] | CarCard[];

  @Prop({ type: String })
  cardType = '';

  @Prop({ type: Number })
  currentCardId?: number;
}
