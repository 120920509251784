
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { ItemsActionName, ItemsActionNames } from '@/definitions/app/item.actions.name';
import { ItemViewModel } from '@/definitions/view-models';
import { ModelAclResult } from '@/store/acl/types';
import { actionHandler } from '@/store/data/ActionHandler';
import { actionsModule } from '@/store/data/ActionsModule';
import { MultisidebarItem } from '@/store/multisidebar/types';
import MultisidebarHeader from '@/components/multisidebar/MultisidebarHeader.vue';
import { RemoteMonitoringEvent } from '@/api/models/RemoteMonitoringEvent';
import RemoteMonitoringEventSidebarContent from '@/pages/remote-monitoring/RemoteMontioringEventSidebarContent.vue';
import { PuppeteerService } from '@/api/services/PuppeteerService';
import { autoUpdateHelper } from '@/api/common/auto-update-helper';

@Options({
  name: 'RemoteMonitoringEventsSidebar',
  components: {
    RemoteMonitoringEventSidebarContent,
    MultisidebarHeader
  }
})
export default class RemoteMonitoringEventsSidebar extends Vue {
  @Prop({ type: Object, required: true })
  readonly sidebarItem!: MultisidebarItem<ItemViewModel<RemoteMonitoringEvent>>;

  @Prop({ type: Object })
  readonly modelAcl!: ModelAclResult;

  get headerLabel() {
    return this.$t('events.matched_event', 'f');
  }

  get currentItem(): RemoteMonitoringEvent | undefined {
    return this.module.item;
  }

  get module(): ItemViewModel<RemoteMonitoringEvent> {
    return this.sidebarItem.model;
  }

  get actions() {
    return actionsModule
      .getItemActions(this.modelAcl, {})
      .map(actionsModule.computeActionByName);
  }

  async actionHandler(action: ItemsActionName, payload: any) {
    if (action === ItemsActionNames.Acknowledge) {
      await PuppeteerService.puppeteerRemoteMonitoringEventsAcknowledgeCreate(this.currentItem!.id);
      Object.assign(this.module.item, { acknowledged: true });
      Object.assign(this.module.originalItem, { acknowledged: true });
      autoUpdateHelper.updateHandler(this.module.name, this.module.item);
    } else {
      await actionHandler.run(action, payload);
    }
  }
}
