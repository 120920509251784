import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "card-form"
}
const _hoisted_2 = {
  key: 1,
  class: "card-form__event"
}
const _hoisted_3 = { class: "card-form-event__thumbnail" }
const _hoisted_4 = { class: "card-form-event__description control-m" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NButton = _resolveComponent("NButton")!
  const _component_NThumbnail = _resolveComponent("NThumbnail")!
  const _component_NForm = _resolveComponent("NForm")!
  const _component_NAttachments = _resolveComponent("NAttachments")!
  const _component_BatchLoaderModal = _resolveComponent("BatchLoaderModal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.isDataModuleReady)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          (!_ctx.isCreated && !_ctx.event && _ctx.hasBatchUploadPermissions)
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                _createTextVNode(_toDisplayString(_ctx.$t('cards.do_you_have_many_cards')) + " ", 1),
                _createVNode(_component_NButton, {
                  type: "link",
                  onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.batchLoader = true)),
                  "data-qa": _ctx.dataQa + '.batch'
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t('cards.batch_cards_upload__after_try')), 1)
                  ]),
                  _: 1
                }, 8, ["data-qa"])
              ], 64))
            : _createCommentVNode("", true),
          (_ctx.event && !_ctx.isCreated)
            ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                _createElementVNode("div", _hoisted_3, [
                  _createVNode(_component_NThumbnail, {
                    "model-value": _ctx.event.thumbnail,
                    "data-qa": _ctx.dataQa + '.thumbnail'
                  }, null, 8, ["model-value", "data-qa"])
                ]),
                _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.$t('cards.create_card_from_event')) + " " + _toDisplayString(_ctx.event.id), 1)
              ]))
            : _createCommentVNode("", true),
          _createVNode(_component_NForm, {
            ref: "itemForm",
            layout: _ctx.schema,
            model: _ctx.item,
            disabled: _ctx.disabled,
            "data-qa": _ctx.dataQa + '.item'
          }, null, 8, ["layout", "model", "disabled", "data-qa"]),
          _createVNode(_component_NForm, {
            ref: "itemMetaForm",
            layout: _ctx.metaSchema,
            model: _ctx.item.meta || {},
            disabled: _ctx.disabled,
            "data-qa": _ctx.dataQa + '.meta'
          }, null, 8, ["layout", "model", "disabled", "data-qa"]),
          (_ctx.isCreated)
            ? (_openBlock(), _createBlock(_component_NAttachments, {
                key: 2,
                "disallow-attach": !_ctx.hasUploadPermission || _ctx.disabled,
                "disallow-remove": !_ctx.hasUploadPermission || _ctx.disabled,
                "handle-attach": _ctx.handleAttachmentAttach,
                "handle-remove": _ctx.handleAttachmentRemove,
                i18n: _ctx.attachmentLabels,
                class: "card-form__upload",
                attachments: _ctx.attachments,
                "onUpdate:attachments": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.attachments) = $event))
              }, null, 8, ["disallow-attach", "disallow-remove", "handle-attach", "handle-remove", "i18n", "attachments"]))
            : _createCommentVNode("", true),
          (_ctx.isCreated)
            ? (_openBlock(), _createBlock(_component_NForm, {
                key: 3,
                layout: _ctx.infoFields,
                model: _ctx.item
              }, null, 8, ["layout", "model"]))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true),
    (_ctx.batchLoader)
      ? (_openBlock(), _createBlock(_component_BatchLoaderModal, {
          key: 1,
          onClose: _cache[2] || (_cache[2] = ($event: any) => (_ctx.batchLoader = false))
        }))
      : _createCommentVNode("", true)
  ], 64))
}