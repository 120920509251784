import { Webhook } from '@/api';
import { NInputNumber } from '@/uikit';
import NCheckbox from '@/uikit/checkbox/NCheckbox.vue';
import { IFormLayout } from '@/uikit/forms/NForm.vue';
import { getNumberValidator, getRequiredValidator, getStringLengthValidator, getUrlValidator } from '@/uikit/forms/validators';
import NInput from '@/uikit/input/NInput.vue';
import NText from '@/uikit/text/NText.vue';
import { getItemCommonFields } from '@/components/common/get-item-common-fields';
import WebhooksFiltersEditor from '@/pages/webhooks/components/WebhooksFiltersEditor.vue';

const layout: IFormLayout = [
  {
    path: 'name',
    classes: 'label-m n-form-w-2 n-form-pad-10 camera-group-form-pad',
    i18n_label: 'webhooks.webhook_title',
    component: NInput,
    validators: [getRequiredValidator(), getStringLengthValidator({ min: 2, max: 100 })]
  },
  {
    path: 'url',
    classes: 'label-m n-form-w-4 n-form-pad-10 camera-group-form-pad',
    i18n_label: 'common.url',
    component: NInput,
    validators: [getUrlValidator()]
  },
  {
    classes: 'label-m n-form-w-6 n-form-pad-10 camera-group-form-pad',
    i18n_label: 'webhooks.batch_size',
    component: NText
  },
  {
    path: 'batch_size',
    classes: 'label-m n-form-w-1',
    component: NInputNumber,
    props: { controls: false },
    validators: [getNumberValidator({ required: false, min: 1 })]
  },
  {
    classes: 'label-m n-form-w-6 camera-group-form-pad',
    i18n_label: 'webhooks.send_attempts',
    component: NText
  },
  [
    {
      path: 'send_attempts',
      classes: 'label-m n-form-w-1',
      component: NInputNumber,
      props: { controls: false },
      validators: [getNumberValidator({ required: true, min: 0 })]
    },
    {
      classes: 'label-m n-form-tip',
      component: NText,
      i18n_label: 'webhooks.send_attempts_tip'
    }
  ],
  {
    path: 'active',
    classes: 'label-m n-form-w-6 n-form-pad-10 camera-group-form-pad',
    props: { i18n_label: 'webhooks.active' },
    component: NCheckbox
  },
  {
    path: 'filters',
    classes: 'label-m n-form-w-6 n-form-pad-10 camera-group-form-pad',
    component: WebhooksFiltersEditor,
    validators: [getRequiredValidator()],
    props: (item: Webhook) => ({ item })
  },
  ...getItemCommonFields()
];

export default layout;
