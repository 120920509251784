import { languageModule } from '@/store/languages';
import { NewItemIdStart } from '@/store/multisidebar';
import { IFormContext } from '@/uikit/forms/NForm.vue';
import { IFormLayoutBlocks } from '@/uikit/forms/NFormBlocks.vue';
import { getRequiredValidator, getStringLengthValidator } from '@/uikit/forms/validators';
import NInput from '@/uikit/input/NInput.vue';
import NText from '@/uikit/text/NText.vue';
import NTextareaAutosize from '@/uikit/textarea/NTextareaAutosize.vue';
import { getItemCommonFields } from '@/components/common/get-item-common-fields';

export const casePhotoFormLayout: IFormLayoutBlocks = [
  {
    layout: getItemCommonFields(),
    display: (model: any) => {
      return model.id > NewItemIdStart;
    }
  },
  {
    layout: [
      {
        path: 'name',
        classes: 'label-m n-form-w-6 n-form-pad-10 camera-group-form-pad',
        i18n_label: 'common.name',
        component: NInput,
        validators: [getRequiredValidator()]
      },
      {
        path: 'comment',
        classes: 'n-form-w-6  control-m n-form-pad-10',
        i18n_label: 'common.description',
        component: NTextareaAutosize
      },
      {
        path: 'status',
        classes: 'n-form-w-6  control-m n-form-pad-10',
        i18n_label: 'common.status',
        component: NText,
        decode: function (this: IFormContext) {
          return languageModule.getTranslatedToken(`cases.photo_status__${this.model.status}`);
        }
      }
    ]
  },
];
