import { UploadList } from '@/api';
import { nextTick, reactive } from 'vue';
import { viewModelRepository } from '@/api/common';
import { autoUpdateHelper } from '@/api/common/auto-update-helper';
import { dataAssetsModule } from '@/store/application/data.assets.module';
import { dataModule } from '@/store/data';
import SimpleTimer from '@/components/video-player/utils/simple-timer';
import { BatchLoaderItem } from '@/pages/cards/batch-loader/BatchLoaderItem';
import BatchLoaderLogger from '@/pages/cards/batch-loader/BatchLoaderLogger';

export interface BatchLoaderModel {
  objectType: string;
  inputType: string;
  content: string;
  filenameAsName: boolean;
  prefixName: string;
  suffixName: string;
  filenameAsComment: boolean;
  prefixComment: string;
  suffixComment: string;
  watch_lists: number[];
  photo_group: string;
  parallel_upload: number;
}

export class BatchLoaderModule {
  uploadList!: UploadList;

  loading = false;
  paused = false;
  finished = false;

  timer = new SimpleTimer();
  items: BatchLoaderItem[] = [];

  model: BatchLoaderModel = {
    objectType: 'faces',
    inputType: 'images',
    content: '',
    filenameAsName: false,
    prefixName: '',
    suffixName: '',
    filenameAsComment: false,
    prefixComment: '',
    suffixComment: '',
    watch_lists: [1],
    photo_group: 'reject',
    parallel_upload: 5
  };

  private getPurifiedFileName(fileName: string) {
    return fileName.replace(/\.[^/.]+$/, '');
  }

  getComment(fileName: string) {
    return this.model.prefixComment + (this.model.filenameAsComment ? this.getPurifiedFileName(fileName) : '') + this.model.suffixComment;
  }

  getName(fileName: string) {
    return (
      this.model.prefixName +
      (this.model.filenameAsName ? this.getPurifiedFileName(fileName) : Math.round(Math.random() * 1e12).toString()) +
      this.model.suffixName
    );
  }

  get ObjectsFaces() {
    return viewModelRepository.getObjectsFacesItemViewModel();
  }

  get currentUserLogin() {
    return dataModule.currentUserModule.item?.name;
  }

  get nameForBatchUpload() {
    return this.currentUserLogin + '-' + new Date().getTime() * 1000 + Math.floor(Math.random() * 1000);
  }

  get totalItems() {
    return this.items.length;
  }

  get totalUploaded() {
    return this.items.filter((item) => item.statistics.statusCode === 'success').length;
  }

  get totalErrors() {
    return this.items.filter((item) => !!item.statistics.errorCode).length;
  }

  get errorPercent() {
    return (this.totalErrors * 100) / this.totalItems;
  }

  get progress() {
    return (this.totalUploaded * 100) / this.totalItems;
  }

  get total() {
    return {
      uploaded: this.totalUploaded,
      errors: this.totalErrors,
      errorPercent: this.errorPercent,
      progress: this.progress
    };
  }

  updateThreads() {
    let threadCount = this.model.parallel_upload;
    let inWorkCount = this.items.filter((item) => item.started && !item.finished).length;
    let allFinished = this.items.every((item) => item.finished);

    if (inWorkCount < threadCount) {
      this.items
        .filter((item) => !item.started)
        .slice(0, threadCount - inWorkCount)
        .forEach((item) => {
          item.upload();
        });
    }

    if (allFinished) {
      this.loading = false;
      this.finished = true;
      this.timer.clear();
      const cardType = dataAssetsModule.getCardTypeByObjectType(this.model.objectType);
      autoUpdateHelper.createHandler(`/cards/${cardType}/`);
    }
  }

  async upload() {
    if (this.paused) {
      this.pause(false);
      return;
    }
    this.loading = true;
    this.uploadList = await BatchLoaderLogger.addUploadList(this.nameForBatchUpload);
    this.timer.setInterval(this.updateThreads.bind(this), 500);
  }

  pause(enabled: boolean) {
    if (enabled) {
      this.timer.clear();
      this.loading = false;
      this.paused = true;
    } else {
      this.loading = true;
      this.paused = false;
      this.timer.setInterval(this.updateThreads.bind(this), 500);
    }
  }
}

export const batchLoaderModule = reactive(new BatchLoaderModule());
