import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "batch-loader-result" }
const _hoisted_2 = ["src"]
const _hoisted_3 = { class: "batch-loader-result__info" }
const _hoisted_4 = { class: "control-s batch-loader-result__file-name" }
const _hoisted_5 = { class: "label-xs batch-loader-result__file-size" }
const _hoisted_6 = {
  key: 0,
  class: "label-xs batch-loader-result__file-size"
}
const _hoisted_7 = {
  key: 2,
  class: "label-xs"
}
const _hoisted_8 = { class: "label-xs batch-loader-result__cards-ids" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NButton = _resolveComponent("NButton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("img", {
      src: _ctx.fileSrc,
      class: "batch-loader-result__image"
    }, null, 8, _hoisted_2),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.fileName), 1),
      _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.fileSize), 1),
      (_ctx.comment)
        ? (_openBlock(), _createElementBlock("div", _hoisted_6, _toDisplayString(_ctx.comment), 1))
        : _createCommentVNode("", true),
      (_ctx.getStatusBadge(_ctx.item))
        ? (_openBlock(), _createElementBlock("div", {
            key: 1,
            class: _normalizeClass(["label-m batch-loader-result__badge", { 'batch-loader-result_error': _ctx.item.statistics.errorCode, 'batch-loader-result_success': _ctx.item.statistics.statusCode }])
          }, _toDisplayString(_ctx.getStatusBadge(_ctx.item)), 3))
        : (_openBlock(), _createElementBlock("div", _hoisted_7, _toDisplayString(_ctx.$t('cards.in_queue')), 1)),
      _createElementVNode("div", _hoisted_8, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.item.cards, (card) => {
          return (_openBlock(), _createBlock(_component_NButton, {
            key: card,
            type: "link",
            onClick: ($event: any) => (_ctx.goToCard(card))
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.getResultCardTitle(card)), 1)
            ]),
            _: 2
          }, 1032, ["onClick"]))
        }), 128))
      ])
    ])
  ]))
}