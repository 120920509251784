import { reactive } from 'vue';

export type ItemsNavigationState = {
  source: any[];
  page: number;
  limit: number;
};

export class ItemsNavigationModule implements ItemsNavigationState {
  limit: number = 20;
  page: number = 0;
  source: any[] = [];

  constructor(source: any[], page: number = 0, limit: number = 20) {
    this.source = source;
    this.page = page;
    this.limit = limit;
  }

  get items() {
    let result = this.source.slice(this.page * this.limit, this.page * this.limit + this.limit);
    return result;
  }
}

export function getItemsNavigationModule(source: any[]) {
  return reactive(new ItemsNavigationModule(source));
}
