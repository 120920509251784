
import { nextTick, reactive } from 'vue';
import { Options, Vue } from 'vue-class-component';
import { Prop, Ref, Watch } from 'vue-property-decorator';
import { ItemsActionNames } from '@/definitions/app/item.actions.name';
import { CardsService } from '@/api';
import { viewModelRepository } from '@/api/common';
import { CardTypes } from '@/store/application/data.assets';
import { actionHandler } from '@/store/data/ActionHandler';
import { MultisidebarItemTypes } from '@/store/multisidebar/types';
import { RouterModule } from '@/store/router';
import NTable from '@/uikit/table/NTable.vue';
import InfiniteScroll from '@/components/common/InfiniteScroll.vue';
import tableSchema from './table-schema';

@Options({
  name: 'BatchUploadLogsDetails',
  components: { InfiniteScroll, NTable }
})
export default class BatchUploadLogsDetails extends Vue {
  @Prop({ type: Number, required: true })
  readonly uploadListId!: number;

  tableSchema = tableSchema;
  readonly module = viewModelRepository.getBatchUploadEntryListViewModel();

  async mounted() {
    this.module.filter.current.upload_list = this.uploadListId;
    await this.module.get();
  }

  async openHumanCardById(id: number) {
    const item = await CardsService.cardsHumansRetrieve(id);
    actionHandler.run(ItemsActionNames.ShowItem, {
      type: MultisidebarItemTypes.CardsHumans,
      rawItem: item
    });
  }

  async openCarCardById(id: number) {
    const item = await CardsService.cardsCarsRetrieve(id);
    actionHandler.run(ItemsActionNames.ShowItem, {
      type: MultisidebarItemTypes.CardsCars,
      rawItem: item
    });
  }

  cellClick(item: any, cell: any, index: any) {
    if (cell.path === 'card_id' && item.human_card_id > 0) {
      this.openHumanCardById(item.human_card_id);
    }
    if (cell.path === 'card_id' && item.car_card_id > 0) {
      this.openCarCardById(item.car_card_id);
    }
  }
}
