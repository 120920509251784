import { ItemViewModel } from '@/definitions/view-models';
import { User, UserFaceService, UsersService } from '@/api';
import { itemViewModelLoader } from '@/store/multisidebar/helpers';
import { MultisidebarItem, MultisidebarItemAttributes } from '@/store/multisidebar/types';
import { userFacesModule } from '@/pages/users/UserFacesModule';

export async function userAttributesLoader(item: MultisidebarItem<ItemViewModel<User>>): Promise<MultisidebarItemAttributes> {
  let thumbnailSrc = '';
  if (item.model.item?.face_cover) {
    let face = await UserFaceService.userFaceRetrieve(item.model.item?.face_cover);
    thumbnailSrc = face.thumbnail;
  }
  return {
    label: item.model.item?.name,
    thumbnailSrc
  };
}

export async function userItemLoader(...args: any[]) {
  // @ts-ignore
  const defaultItem = await itemViewModelLoader(...args);
  const defaultSave = defaultItem.model.save;
  defaultItem.model.save = async function () {
    if (defaultItem.model.isNew) {
      const prevId = defaultItem.model.item.id;
      const user = await defaultSave.apply(defaultItem.model);
      userFacesModule.replaceIds(prevId, user.id);
      await userFacesModule.uploadAll(user.id);
      return user;
    }

    return defaultSave.apply(defaultItem.model);
  };

  return defaultItem;
}
