import { dataModule } from '@/store/data';
import { NewItemIdStart } from '@/store/multisidebar';
import { IFormContext, IFormModel } from '@/uikit/forms/NForm.vue';
import { IFormLayoutBlocks } from '@/uikit/forms/NFormBlocks.vue';
import { getRequiredValidator, getStringLengthValidator } from '@/uikit/forms/validators';
import NInput from '@/uikit/input/NInput.vue';
import NSwitch from '@/uikit/switch/NSwitch.vue';
import NTextDelimiter from '@/uikit/text/NTextDelimiter.vue';
import NTextareaAutosize from '@/uikit/textarea/NTextareaAutosize.vue';
import { getItemCommonFields } from '@/components/common/get-item-common-fields';
import CameraGroupName from '@/components/data-source/CameraGroupName.vue';
import CameraName from '@/components/data-source/CameraName.vue';
import BBoxIntersectionPoint from '@/components/lines/BBoxIntersectionPoint.vue';
import DataSourceType from '@/components/lines/DataSourceType.vue';
import DetectorsInfo from '@/components/lines/DetectorsInfo.vue';

const formLayoutBlocks: IFormLayoutBlocks = [
  {
    layout: [
      {
        classes: 'n-form-w-6  n-form-pad-10',
        component: NTextDelimiter,
        props: { i18n_label: 'common.information' }
      },
      {
        path: 'name',
        classes: 'label-m n-form-w-6 n-form-pad-10 camera-group-form-pad',
        i18n_label: 'lines.name',
        component: NInput,
        validators: [getRequiredValidator(), getStringLengthValidator({ required: true, min: 3, max: 100 })]
      },
      {
        path: 'comment',
        classes: 'label-m n-form-w-6 n-form-pad-10 camera-group-form-pad',
        i18n_label: 'lines.description',
        component: NTextareaAutosize
      },
      {
        path: 'active',
        classes: 'label-m n-form-w-6 n-form-pad-10',
        i18n_label: 'common.column_active',
        component: NSwitch,
        props: {}
      },
      {
        classes: 'n-form-w-6  n-form-pad-10',
        component: NTextDelimiter,
        props: { i18n_label: 'lines.data_source' }
      },
      {
        classes: 'n-form-w-6  n-form-pad-10',
        component: DataSourceType,
        encode: function (this: IFormContext, model: IFormModel, value: string) {
          this.state.sourceType = value;
          if (value === 'camera') {
            this.state.cameraGroup = undefined;
            this.model.video_archive = null;
          } else {
            this.state.cameraGroup = undefined;
            this.model.camera = null;
          }
        },
        decode: function (this: IFormContext) {
          if (this.model.camera !== null) {
            const camera = dataModule.camerasModule.items.find((item) => item.id === this.model.camera);
            if (camera) {
              this.state.sourceType = 'camera';
              this.state.cameraGroup = camera.group;
            }
          }
          if (this.model.video_archive !== null) {
            const video_archive = dataModule.videosModule.items.find((item) => item.id === this.model.video_archive);
            if (video_archive) {
              this.state.sourceType = 'video_archive';
              this.state.cameraGroup = video_archive.camera_group;
            }
          }
          return this.state.sourceType;
        }
      }
    ],
    display: true
  },
  {
    layout: [
      // if camera
      {
        classes: 'label-m n-form-w-6 n-form-pad-10',
        i18n_label: 'common.camera_groups',
        component: CameraGroupName,
        props: { i18n_placeholder: 'common.select_camera_group' },
        encode: function (this: IFormContext, model: IFormModel, value: number) {
          this.state.cameraGroup = value;
          this.model.camera = null;
        },
        decode: function (this: IFormContext) {
          return this.state.cameraGroup;
        }
      },
      {
        path: 'camera',
        classes: 'label-m n-form-w-6 n-form-pad-10',
        i18n_label: 'lines.camera',
        component: CameraName,
        props: function (this: IFormContext) {
          return {
            i18n_placeholder: 'common.select_camera',
            cameraGroup: this.state.cameraGroup,
            sourceType: this.state.sourceType
          };
        },
        validators: [getRequiredValidator()]
      },
      {
        classes: 'label-m n-form-w-6  n-form-pad-10',
        i18n_label: 'lines.detectors',
        component: DetectorsInfo,
        props: function (this: IFormContext) {
          return { sourceType: 'camera', sourceId: this.model.camera };
        }
      }
    ],
    display: (model: any, state: any) => {
      return state.sourceType === 'camera';
    }
  },
  {
    layout: [
      // if video_archive
      {
        classes: 'label-m n-form-w-6 n-form-pad-10',
        i18n_label: 'lines.data_source_group',
        component: CameraGroupName,
        encode: function (this: IFormContext, model: IFormModel, value: number) {
          this.state.cameraGroup = value;
          this.model.video_archive = null;
        },
        decode: function (this: IFormContext) {
          return this.state.cameraGroup;
        },
        props: function (this: IFormContext) {
          return { i18n_placeholder: 'common.select_camera_group', excludeVideoArchive: false };
        }
      },
      {
        path: 'video_archive',
        classes: 'label-m n-form-w-6 n-form-pad-10',
        i18n_label: 'lines.video_archive',
        component: CameraName,
        props: function (this: IFormContext) {
          return {
            i18n_placeholder: 'common.select_camera',
            cameraGroup: this.state.cameraGroup,
            sourceType: this.state.sourceType
          };
        },
        validators: [getRequiredValidator()]
      },
      {
        classes: 'label-m n-form-w-6  n-form-pad-10',
        i18n_label: 'lines.detectors',
        component: DetectorsInfo,
        props: function (this: IFormContext) {
          return { sourceType: 'video_archive', sourceId: this.model.video_archive };
        }
      }
    ],
    display: (model: any, state: any) => {
      return state.sourceType === 'video_archive';
    }
  },
  {
    layout: [
      {
        path: 'bbox_intersection_point',
        classes: 'n-form-w-6  n-form-pad-10',
        i18n_label: 'lines.bbox_intersection_point',
        component: BBoxIntersectionPoint,
        validators: [getRequiredValidator()]
      }
    ]
  },
  {
    layout: getItemCommonFields(),
    display: (model: any) => {
      return model.id > NewItemIdStart;
    }
  }
];

export default formLayoutBlocks;
