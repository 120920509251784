
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { VideoArchive } from '@/api';
import { ObjectsTypesMap } from '@/store/application/data.assets';
import { RouterModule } from '@/store/router';
import NButton from '@/uikit/buttons/NButton.vue';
import CameraScreenshot from '@/components/data-source/CameraScreenshot.vue';
import VideoPlayer from '@/components/video-player/VideoPlayer.vue';
import { configModule } from '@/store/config';
import { getStatusLabelValue } from '@/pages/data-sources/forms/data-source-info';

@Options({
  name: 'VideoProcessingPlayer',
  components: { CameraScreenshot, NButton, VideoPlayer }
})
export default class VideoProcessingPlayer extends Vue {
  @Prop({ type: Object, required: true })
  readonly item!: VideoArchive;

  get active() {
    return this.item.health_status?.status === 'INPROGRESS';
  }

  get casesEnabled() {
    return configModule.features.cases_enabled;
  }

  get videoHeight() {
    if (this.item.frame_width) {
      return Math.ceil((520 / this.item.frame_width) * this.item.frame_height) + 'px';
    }
    return '300px';
  }

  get statusLabelValue() {
    return getStatusLabelValue(this.item as any);
  }

  navigateToVideoEvents() {
    RouterModule.navigateToEvents(ObjectsTypesMap.Faces, { video_archive: this.item.id });
  }
}
