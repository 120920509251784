
import { defineComponent } from 'vue';
import { arrayOf, string } from 'vue-types';
import NSelect from '@/uikit/select/NSelect.vue';
import type { ISelectItem } from '@/uikit/select/types';

export default defineComponent({
  name: 'VmsSidebarCamerasTabFiltersCameraGroup',
  components: { NSelect },
  props: {
    cameraGroups: arrayOf(string()).isRequired,
    modelValue: string().isRequired
  },
  emits: ['update:modelValue'],
  computed: {
    items(): ISelectItem[] {
      return [{ value: '', label: this.$t('external_vms.any_camera_group') }, ...this.cameraGroups.map((value) => ({ value, label: value }))];
    }
  },
  methods: {
    dispatchUpdateModelValueEvent(modelValue: string): void {
      this.$emit('update:modelValue', modelValue);
    }
  }
});
