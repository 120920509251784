import { NInputNumber } from '@/uikit';
import NCheckbox from '@/uikit/checkbox/NCheckbox.vue';
import { IFormContext, IFormLayout, IFormModel } from '@/uikit/forms/NForm.vue';
import { getNumberValidator, getUrlValidator } from '@/uikit/forms/validators';
import NInput from '@/uikit/input/NInput.vue';
import NTextDelimiter from '@/uikit/text/NTextDelimiter.vue';
import CameraTransform from '@/components/data-source/CameraTransform.vue';

const layout: IFormLayout = [
  {
    classes: 'n-form-w-6  n-form-pad-10',
    component: NTextDelimiter,
    props: { i18n_label: 'ds.transformation' }
  },
  {
    path: 'stream_settings.video_transform',
    classes: 'n-form-w-6  n-form-pad-10',
    component: CameraTransform,
    props: (item: any) => ({ screenshotUrl: item.screenshot })
  },
  {
    classes: 'n-form-w-6  n-form-pad-10',
    component: NTextDelimiter,
    props: { i18n_label: 'ds.posting_objects' }
  },
  {
    path: 'stream_settings.router_timeout_ms',
    i18n_label: 'ds.timeout',
    i18n_tooltip: 'ds.params.router_timeout_ms_desc',
    classes: 'label-m n-form-w-2 n-form-label-horizontal-50 n-form-pad-10',
    component: NInputNumber,
    props: { min: 0, i18n_units: 'ds.ms', textAlign: 'right' },
    validators: [getNumberValidator({ required: false, min: 0, max: 300000 })]
  },
  {
    path: 'stream_settings.router_verify_ssl',
    classes: 'label-m n-form-w-4 n-form-vcenter n-form-pad-10',
    i18n_tooltip: 'ds.params.router_verify_ssl_desc',
    component: NCheckbox,
    props: { i18n_label: 'ds.verify_ssl_certificate' }
  },
  {
    classes: 'n-form-w-6  n-form-pad-10',
    component: NTextDelimiter,
    props: { i18n_label: 'ds.timestamp' }
  },
  [
    {
      path: 'stream_settings.use_stream_timestamp',
      classes: 'label-m n-form-w-4 n-form-vcenter n-form-pad-10',
      i18n_tooltip: 'ds.params.use_stream_timestamp_desc',
      component: NCheckbox,
      props: { i18n_label: 'ds.retrieve_timestamps_from_stream' }
    },
    {
      path: 'stream_settings.start_stream_timestamp',
      classes: 'label-m n-form-w-4 n-form-label-horizontal-100 n-form-pad-10',
      i18n_label: 'ds.add_to_timestamps',
      i18n_tooltip: 'ds.params.start_stream_timestamp_desc',
      component: NInputNumber,
      props: { min: 0, max: Math.pow(2, 32) - 1, i18n_units: 'ds.sec', textAlign: 'right' },
      validators: [getNumberValidator({ required: false, min: 0, max: Math.pow(2, 32) - 1 })]
    }
  ],
  {
    classes: 'n-form-w-6  n-form-pad-10',
    component: NTextDelimiter,
    props: { i18n_label: 'ds.other' }
  },
  {
    classes: 'label-m n-form-w-6 n-form-pad-10 camera-group-form-pad',
    i18n_label: 'ds.ffmpeg_parameters',
    i18n_tooltip: 'ds.params.ffmpeg_params_desc',
    component: NInput,
    encode: function (this: IFormContext, model: IFormModel, value: string) {
      this.model.stream_settings.ffmpeg_params = value.split(' ').filter((v) => !!v);
    },
    decode: function (this: IFormContext) {
      return (this.model.stream_settings?.ffmpeg_params || []).join(' ');
    }
  },
  [
    {
      path: 'stream_settings.play_speed',
      classes: 'label-m n-form-w-6 n-form-pad-10 pull-end',
      i18n_label: 'ds.play_speed_limit',
      i18n_tooltip: 'ds.params.play_speed_desc',
      component: NInputNumber,
      props: { min: -1, max: 100 },
      validators: [getNumberValidator({ required: true, float: true, min: -1, max: 100 })]
    },
    {
      path: 'stream_settings.ffmpeg_format',
      classes: 'label-m n-form-w-6 n-form-pad-10 pull-end',
      i18n_tooltip: 'ds.params.ffmpeg_format_desc',
      i18n_label: 'ds.force_input_format',
      component: NInput
    },
    {
      path: 'stream_settings.imotion_threshold',
      classes: 'label-m n-form-w-6 n-form-pad-10 pull-end',
      i18n_label: 'ds.imotion_threshold',
      i18n_tooltip: 'ds.params.imotion_threshold_desc',
      props: { min: 0, max: 1, step: 0.02 },
      component: NInputNumber,
      validators: [getNumberValidator({ required: true, float: true, min: 0, max: 1 })]
    }
  ],
  {
    path: 'stream_settings.disable_drops',
    classes: 'label-m n-form-w-4 n-form-vcenter n-form-pad-10',
    i18n_tooltip: 'ds.params.disable_drops_desc',
    component: NCheckbox,
    props: { i18n_label: 'ds.read_frames_from_source_without_drops' }
  },
  {
    path: 'single_pass',
    classes: 'label-m n-form-w-4 n-form-vcenter n-form-pad-10',
    i18n_tooltip: 'ds.params.single_pass_desc',
    component: NCheckbox,
    props: { i18n_label: 'ds.params.single_pass' }
  },
  {
    classes: 'label-m n-form-w-6 n-form-pad-10 camera-group-form-pad',
    path: 'router_url',
    i18n_label: 'ds.params.router_url',
    i18n_tooltip: 'ds.params.router_url_desc',
    component: NInput,
    validators: [getUrlValidator(false)]
  }
  /*
  {
    path: 'stream_data_filter',
    classes: 'label-m n-form-w-6 n-form-label-horizontal-100 n-form-pad-10',
    i18n_label: 'ds.stream_data_filter',
    component: NInput
  }
*/
];

export default layout;
