
import { defineComponent } from 'vue';
import NModalWindow from '@/uikit/modal-window/NModalWindow.vue';
import { CameraLookup } from './index';
import { CameraLookupModel } from './types';
import { defineAuthenticatorProp, defineSourceProp } from './utils';

export default defineComponent({
  name: 'CameraLookupWindow',
  components: {
    CameraLookup,
    NModalWindow
  },
  emits: ['close', 'select'],
  props: {
    authenticator: defineAuthenticatorProp(),
    source: defineSourceProp()
  },
  methods: {
    dispatchCloseEvent(): void {
      this.$emit('close');
    },
    dispatchSelectEvent(camera: CameraLookupModel): void {
      this.$emit('select', camera);
    }
  }
});
