
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { ItemsActionName } from '@/definitions/app/item.actions.name';
import { ItemViewModel } from '@/definitions/view-models';
import { AuditLog } from '@/api';
import { ModelAclResult } from '@/store/acl/types';
import { actionHandler } from '@/store/data/ActionHandler';
import { MultisidebarItem } from '@/store/multisidebar/types';
import NButton from '@/uikit/buttons/NButton.vue';
import { copyTextToClipboard } from '@/uikit/helpers';
import NIcon from '@/uikit/icons/NIcon.vue';
import MultisidebarHeader from '@/components/multisidebar/MultisidebarHeader.vue';
import BatchUploadLogsDetails from '@/pages/audit-logs/batch-upload-logs/BatchUploadLogsDetails.vue';
import BatchUploadLogsDownload from '@/pages/audit-logs/batch-upload-logs/BatchUploadLogsDownload.vue';
import NThumbnail from '@/uikit/thumbnail/NThumbnail.vue';

@Options({
  name: 'AuditLogsSidebar',
  components: { NThumbnail, BatchUploadLogsDetails, BatchUploadLogsDownload, MultisidebarHeader, NButton, NIcon },
  emits: []
})
export default class AuditLogsSidebar extends Vue {
  @Prop({ type: Object, required: true })
  readonly sidebarItem!: MultisidebarItem<ItemViewModel<AuditLog>>;

  @Prop({ type: Object, required: true })
  readonly modelAcl!: ModelAclResult;

  visibleNoty = false;

  get item() {
    return this.sidebarItem.model.item;
  }

  get itemContent() {
    return JSON.stringify(this.item, undefined, 2);
  }

  showImage() {
    // @ts-ignore
    const item = { fullframe: this.item?.fullframe, bboxes: [this.item?.bbox] };
    this.$photoViewer.show(item);
  }

  copyText() {
    copyTextToClipboard(this.itemContent).then(() => {
      this.visibleNoty = true;
      setTimeout(() => {
        this.visibleNoty = false;
      }, 3000);
    });
  }

  handleAction(v: ItemsActionName) {
    actionHandler.run(v, this.sidebarItem);
  }
}
