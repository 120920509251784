
import { computed, defineComponent, PropType, Ref, ref, toRef } from 'vue';
import { Group } from '@/api';
import { AdGroupsService } from '@/api/services/AdGroupsService';
import { NSelect, ISelectItem } from '@/uikit';

export default defineComponent({
  name: 'GroupADSelect',
  components: { NSelect },
  props: {
    item: { type: Object as PropType<Group>, required: true }
  },
  setup(props) {
    const item = toRef(props, 'item');
    const items: Ref<ISelectItem[]> = ref([]);
    const itemValue = computed(() => items.value.length && (item.value.ad_group?.group_guid || ''));

    function updateAdGroup(value?: string | null) {
      if (!item.value.ad_group) {
        item.value.ad_group = { group_guid: null };
      }
      item.value.ad_group.group_guid = value ?? null;
    }

    AdGroupsService.adGroupsRetrieve().then((adGroups) => {
      items.value = adGroups.map((item) => ({ label: String(item.group_name), value: item.group_guid }));
    });

    return {
      items,
      itemValue,
      updateAdGroup
    };
  }
});
