
import { nextTick, reactive } from 'vue';
import { Options, Vue } from 'vue-class-component';
import { Prop, Ref, Watch } from 'vue-property-decorator';
import { NAttachment, NAttachmentFileBlob } from '@/uikit/attachments';
import NAttachments from '@/uikit/attachments/NAttachments.vue';
import NForm from '@/uikit/forms/NForm.vue';
import { BatchLoaderModule } from '@/pages/cards/batch-loader/BatchLoaderModule';
import { getBatchLoaderFormSchema } from '@/pages/cards/forms/batch-loader-form-schema';
import { BatchLoaderItem } from '@/pages/cards/batch-loader/BatchLoaderItem';

@Options({
  name: 'BatchLoaderModalImages',
  components: { NAttachments, NForm }
})
export default class BatchLoaderModalImages extends Vue {
  @Prop({ type: Object, required: true })
  readonly batchModule!: BatchLoaderModule;

  get schema() {
    return getBatchLoaderFormSchema();
  }

  get loading() {
    return this.batchModule.loading;
  }

  get attachDisabled() {
    if (this.batchModule.paused) return false;
    return this.batchModule.loading;
  }

  addFiles(files: File[]) {
    this.batchModule.finished = false;
    files.forEach((file: File) => {
      const item = new BatchLoaderItem();
      item._instance = this.batchModule;
      item.objectType = this.batchModule.model.objectType;
      item.fillAsFile(file);
      this.batchModule.items.push(item);
    });
  }

  uploadHandler(e: Event) {
    if (this.attachDisabled) {
      console.log('For attach files pause current process!');
      return;
    }
    const target = e.target as HTMLInputElement;
    if (target && target.files) {
      this.addFiles(Array.from(target.files));
      target.value = '';
    }
  }

  startingTimeout = false;
  uploadDNDHandler(attachments: NAttachment[]) {
    const dndResult: File[] = [];
    dndResult.push(...attachments.map((attachment) => attachment.file as NAttachmentFileBlob));

    if (!this.startingTimeout) {
      this.startingTimeout = true;
      setTimeout(() => {
        this.startingTimeout = false;
        if (this.attachDisabled) {
          console.log('For attach files pause current process!');
          return;
        }
        this.addFiles(dndResult);
      }, 500);
    }
  }
}
