
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { Webhook } from '@/api';
import NBaseBar from '@/uikit/bars/NBaseBar.vue';
import NButton from '@/uikit/buttons/NButton.vue';
import NButtonGroup from '@/uikit/buttons/NButtonGroup.vue';
import NModalWindow from '@/uikit/modal-window/NModalWindow.vue';
import FiltersBig from '@/components/common/filter/FiltersBig.vue';
import FiltersSmall from '@/components/common/filter/FiltersSmall.vue';
import { EditorMode, EditorModes, EditorSections } from '@/pages/webhooks/components/types';
import { isSectionAvailable } from '@/pages/webhooks/components/utils';
import WebhooksFiltersEditorJson from '@/pages/webhooks/components/WebhooksFiltersEditorJson.vue';
import WebhooksFiltersEditorVisual from '@/pages/webhooks/components/WebhooksFiltersEditorVisual.vue';

@Options({
  name: 'WebhooksFiltersEditor',
  components: { FiltersBig, FiltersSmall, NBaseBar, NButton, NButtonGroup, NModalWindow, WebhooksFiltersEditorJson, WebhooksFiltersEditorVisual }
})
export default class WebhooksFiltersEditor extends Vue {
  @Prop({ type: Object, required: true })
  readonly item!: Webhook;

  private mode: EditorMode = EditorModes.Visual;
  private largeFiltersActive = false;
  private resetModalActive = false;

  get hasAvailableSections() {
    return !!Object.values(EditorSections).filter(isSectionAvailable).length;
  }

  get isVisualMode() {
    return this.mode === EditorModes.Visual;
  }

  get modeItems() {
    const visual = { value: EditorModes.Visual, label: this.$t('webhooks.edit_mode_visual', 'f') };
    const manual = { value: EditorModes.Json, label: this.$t('webhooks.edit_mode_json', 'f') };

    return this.hasAvailableSections ? [visual, manual] : [manual];
  }

  get hasChanges() {
    return this.item.filters && Object.keys(this.item.filters).length > 0;
  }

  showResetModal() {
    this.resetModalActive = true;
  }

  reset() {
    this.item.filters = {};
    this.largeFiltersActive = false;
    this.resetModalActive = false;
  }
}
