
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { ItemsActionName } from '@/definitions/app/item.actions.name';
import { ItemViewModel } from '@/definitions/view-models';
import { Webhook } from '@/api';
import { ModelAclResult } from '@/store/acl/types';
import { actionHandler } from '@/store/data/ActionHandler';
import { actionsModule } from '@/store/data/ActionsModule';
import { MultisidebarItem } from '@/store/multisidebar/types';
import { NForm } from '@/uikit';
import MultisidebarHeader from '@/components/multisidebar/MultisidebarHeader.vue';
import MultisidebarSaveActions from '@/components/multisidebar/MultisidebarSaveActions.vue';
import WebhooksSidebarFilters from '@/pages/webhooks/components/WebhooksFiltersEditor.vue';
import layout from '@/pages/webhooks/webhooks-form';

@Options({
  name: 'WebhooksSidebar',
  components: {
    MultisidebarHeader,
    MultisidebarSaveActions,
    NForm,
    WebhooksSidebarFilters
  },
  emits: ['action']
})
export default class WebhooksSidebar extends Vue {
  @Prop({ type: Object, required: true })
  readonly sidebarItem!: MultisidebarItem<ItemViewModel<Webhook>>;

  @Prop({ type: Object, required: true })
  readonly modelAcl!: ModelAclResult;

  get currentItem() {
    return this.module.item;
  }

  get disabled() {
    return !(this.module.isNew ? this.modelAcl.add : this.modelAcl.update);
  }

  get module() {
    return this.sidebarItem.model;
  }

  get headerActions() {
    return actionsModule
      .getItemActions(this.modelAcl, {
        hasChanges: this.module.hasChanges,
        hasDelete: true,
        hasDuplicate: true
      })
      .map(actionsModule.computeActionByName);
  }

  get layout() {
    return layout;
  }

  mounted() {
    actionHandler.getIsItemFormValid = this.validateForm.bind(this);
  }

  actionHandler(actionName: ItemsActionName, payload?: any) {
    actionHandler.run(actionName, payload);
  }

  validateForm() {
    const form: NForm | undefined = this.$refs.form as any as NForm;
    const result = form ? form.validate() : true;
    if (!result) form.displayErrors();
    return result;
  }
}
