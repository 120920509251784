
import { withModifiers } from 'vue';
import { Options, Vue } from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import { ItemsActionNames } from '@/definitions/app/item.actions.name';
import { ExternalVms, VmsPluginInfoRequest } from '@/api';
import { viewModelRepository } from '@/api/common';
import { ExternalVmsSendEventStatus } from '@/api/models/ExternalVmsSendEventStatus';
import { ObjectsSingleToEventType } from '@/store/application/data.assets';
import { actionHandler } from '@/store/data/ActionHandler';
import { NButtonTypes } from '@/uikit';
import NButton from '@/uikit/buttons/NButton.vue';
import { NButtonSizes } from '@/uikit/buttons/types';
import NDateTimeLabel from '@/uikit/datetime/NDateTimeLabel.vue';
import { copyTextToClipboard } from '@/uikit/helpers';
import NHint from '@/uikit/hint/NHint.vue';
import { NTable, NTableColumn } from '@/uikit/table-v2';
import NFilterDateRange from '@/components/common/filter/controls/FilterDateRange.vue';
import InfiniteScroll from '@/components/common/InfiniteScroll.vue';
import { ExternalVmsConfig } from '@/pages/external-vms/types';

@Options({
  name: 'ExternalVmsInfo',
  components: { InfiniteScroll, NDateTimeLabel, NFilterDateRange, NHint, NTable },
  emits: []
})
export default class ExternalVmsSentMessages extends Vue {
  @Prop({ type: Object, required: true })
  readonly item!: ExternalVms;

  @Prop({ type: Object, required: true })
  readonly info!: VmsPluginInfoRequest;

  @Prop({ type: Object, required: false })
  readonly config: ExternalVmsConfig | null = null;

  module = viewModelRepository.getExternalVmsSendEventStatusListViewModel();

  get columns(): NTableColumn<ExternalVmsSendEventStatus>[] {
    return [this.createUrlTableColumn(), this.createStatusTableColumn(), this.createdTableColumn()];
  }

  @Watch('module.filter.current', { deep: true })
  changeFilter(v: any, p: any) {
    if (v?.page !== p?.page || v?.limit !== p?.limit) return;
    this.module.get();
  }

  @Watch('item.id', { immediate: true })
  updateFilter() {
    this.module.filter.current.external_vms = [this.item.id];
  }

  createUrlTableColumn(): NTableColumn<ExternalVmsSendEventStatus> {
    return {
      body: {
        content: ({ model }) => (
          <div>
            <div class="vms-sent-messages-table__id">
              <NButton {...{ type: NButtonTypes.Text, class: 'label-mono-s', onClick: () => this.openEvent(model) }}>#{model.event_id}</NButton>
              &nbsp;
              <NButton
                {...{
                  icon: 'copy',
                  iconSize: 14,
                  onClick: withModifiers(() => this.copyStreamUrlToClipboard(model.event_id), ['stop', 'prevent']),
                  type: NButtonTypes.Link
                }}
              />
            </div>
            <span>
              {this.$t('common.camera_id')}: {model.camera}
            </span>
          </div>
        )
      },
      width: 'max-content'
    };
  }

  createStatusTableColumn(): NTableColumn<ExternalVmsSendEventStatus> {
    return {
      body: ({ model }) => <div class="label-xs">{model.error}</div>,
      width: '1fr'
    };
  }

  createdTableColumn(): NTableColumn<ExternalVmsSendEventStatus> {
    return {
      body: {
        content: ({ model }) => {
          const error = (
            <NHint {...{ content: model.error_msg }}>
              <NButton {...{ type: NButtonTypes.Red, size: NButtonSizes.Small }}>{this.$t('common.error', 'f')}</NButton>
            </NHint>
          );

          return (
            <div class="vms-sent-messages-table__info">
              {model.error && error}
              <NDateTimeLabel multiline modelValue={model.created_date} />
            </div>
          );
        }
      }
    };
  }

  copyStreamUrlToClipboard(url: string): void {
    copyTextToClipboard(url);
  }

  openEvent(item: ExternalVmsSendEventStatus) {
    if (!item.event_type) return;
    const eventType = ObjectsSingleToEventType[item.event_type];
    actionHandler.run(ItemsActionNames.ShowItem, { type: eventType, rawItem: item.event_id });
  }
}
