import { WatchList } from '@/api';
import { dataModule } from '@/store/data';
import { NInput, NSelect } from '@/uikit';
import NButtonGroup from '@/uikit/buttons/NButtonGroup.vue';
import NCheckbox from '@/uikit/checkbox/NCheckbox.vue';
import { IFormContext, IFormLayout, IFormModel } from '@/uikit/forms/NForm.vue';

export function getBatchLoaderFormSchema() {
  let result: IFormLayout | any[] = [
    [
      {
        path: 'prefixName',
        classes: 'n-form-w-7 control-m n-form-pad-10',
        i18n_label: 'cards.prefix_name',
        component: NInput
      },
      {
        path: 'filenameAsName',
        classes: 'n-form-w-2 n-form-self-align_end control-m n-form-pad-10',
        component: NCheckbox,
        props: { i18n_label: 'cards.filename_as_name' }
      },
      {
        path: 'suffixName',
        classes: 'n-form-w-7 control-m n-form-pad-10',
        i18n_label: 'cards.suffix_name',
        component: NInput
      }
    ],
    [
      {
        path: 'prefixComment',
        classes: 'n-form-w-7 control-m n-form-pad-10',
        i18n_label: 'cards.prefix_comment',
        component: NInput
      },
      {
        path: 'filenameAsComment',
        classes: 'n-form-w-2 n-form-self-align_end control-m n-form-pad-10',
        component: NCheckbox,
        props: { i18n_label: 'cards.filename_as_name' }
      },
      {
        path: 'suffixComment',
        classes: 'n-form-w-7  control-m n-form-pad-10',
        i18n_label: 'cards.suffix_comment',
        component: NInput
      }
    ],
    {
      path: 'watch_lists',
      classes: 'n-form-w-6  control-m n-form-pad-10',
      i18n_label: 'common.watch_lists',
      component: NSelect,
      validators: [{ name: 'required' }],
      props: {
        multiple: true,
        color: true,
        items: dataModule.watchListsWithoutUnknown.map((v: WatchList) => ({ label: v.name, value: v.id, color: '#' + v.color }))
      }
    },
    [
      {
        path: 'photo_group',
        classes: 'n-form-w-3 control-m n-form-pad-10',
        i18n_label: 'cards.photo_group',
        component: NSelect,
        validators: [{ name: 'required' }],
        props: {
          selectonly: true,
          items: [
            { i18n_label: 'cards.reject', value: 'reject' },
            { i18n_label: 'cards.biggest', value: 'biggest' },
            { i18n_label: 'cards.all', value: 'all' }
          ]
        }
      },
      {
        path: 'parallel_upload',
        classes: 'n-form-pad-10',
        i18n_label: 'cards.parallel_upload',
        component: NButtonGroup,
        props: {
          items: [
            { value: 2, label: '2' },
            { value: 5, label: '5' },
            { value: 10, label: '10' },
            { value: 20, label: '20' }
          ]
        }
      }
    ]
  ];

  return result;
}
