
import { Options, Vue } from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import { ItemsActionName, ItemsActionNames } from '@/definitions/app/item.actions.name';
import { viewModelRepository } from '@/api/common';
import { actionHandler } from '@/store/data/ActionHandler';
import { NButton } from '@/uikit';
import Confidence from '@/components/common/Confidence.vue';
import ConfidenceDelimiter from '@/components/common/ConfidenceDelimiter.vue';
import MatchedCardInfo from '@/pages/events/MatchedCardInfo.vue';
import { RemoteMonitoringEvent } from '@/api/models/RemoteMonitoringEvent';
import { HumanCard } from '@/api';
import WatchListsGroup from '@/components/common/WatchListsGroup.vue';
import NThumbnail from '@/uikit/thumbnail/NThumbnail.vue';
import NForm from '@/uikit/forms/NForm.vue';
import Attributes from '@/components/common/Attributes.vue';
import Acknowledge from '@/components/common/deprecated/Acknowledge.vue';
import AcknowledgeInfo from '@/components/common/AcknowledgeInfo.vue';
import { getItemCommonFields, getRemoteMonitoringItemCommonFields } from '@/components/common/get-item-common-fields';
import { ModelAclResult } from '@/store/acl/types';
import { PuppeteerService } from '@/api/services/PuppeteerService';
import { monitoringModule } from '@/components/monitoring/MonitoringModule';
import { EventDetails, ThumbnailPositions } from '@/uikit/thumbnail';

@Options({
  name: 'RemoteMonitoringEventSidebarContent',
  components: {
    AcknowledgeInfo,
    Acknowledge,
    Attributes,
    NForm,
    NThumbnail,
    WatchListsGroup,
    Confidence,
    ConfidenceDelimiter,
    MatchedCardInfo,
    NButton
  }
})
export default class RemoteMonitoringEventsSidebarContent extends Vue {
  @Prop({ type: Object, required: true })
  readonly item!: RemoteMonitoringEvent;

  @Prop({ type: Object, required: true})
  readonly modelAcl!: ModelAclResult;

  private reasons: string = '';

  private cardModule = viewModelRepository.getCardsHumansItemViewModel();

  get infoFields() {
    return getRemoteMonitoringItemCommonFields();
  }

  created() {
    this.load();
  }

  get card(): HumanCard | undefined {
    return this.cardModule.item;
  }

  get thumbnailActions() {
    const result = [
      { icon: 'eye-open', name: EventDetails.ShowFullScreen, position: ThumbnailPositions.BottomRight }
    ];
    return result;
  }

  async actionHandler(v: ItemsActionName, payload?: any) {
    await actionHandler.run(v, payload);
  }

  @Watch('item.id')
  async load() {
    this.cardModule.clear();
    this.reasons = '';

    if (this.item.card) {
      this.cardModule.get(this.item.card);
      this.reasons = await monitoringModule.getReasonsByCardId(this.item.card);
    }
  }

  async acknowledge() {
    this.$emit('action', 'acknowledge', this.item);
  }

  showFullscreen() {
    this.$photoViewer.show(this.item);
  }
}
