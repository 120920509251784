import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "webhooks-filters-editor" }
const _hoisted_2 = { class: "label-m webhooks-filters-editor__reset-question" }
const _hoisted_3 = { class: "webhooks-filters-editor__reset-actions" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NButtonGroup = _resolveComponent("NButtonGroup")!
  const _component_NButton = _resolveComponent("NButton")!
  const _component_NBaseBar = _resolveComponent("NBaseBar")!
  const _component_WebhooksFiltersEditorVisual = _resolveComponent("WebhooksFiltersEditorVisual")!
  const _component_WebhooksFiltersEditorJson = _resolveComponent("WebhooksFiltersEditorJson")!
  const _component_NModalWindow = _resolveComponent("NModalWindow")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_NBaseBar, { class: "webhooks-filters-editor__bar" }, {
      start: _withCtx(() => [
        _createVNode(_component_NButtonGroup, {
          items: _ctx.modeItems,
          modelValue: _ctx.mode,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.mode) = $event)),
          "init-value-on-empty": ""
        }, null, 8, ["items", "modelValue"])
      ]),
      end: _withCtx(() => [
        (_ctx.hasChanges)
          ? (_openBlock(), _createBlock(_component_NButton, {
              key: 0,
              type: "secondary",
              icon: "close",
              onClick: _ctx.showResetModal
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('common.reset', 'f')), 1)
              ]),
              _: 1
            }, 8, ["onClick"]))
          : _createCommentVNode("", true),
        (_ctx.isVisualMode)
          ? (_openBlock(), _createBlock(_component_NButton, {
              key: 1,
              type: "secondary",
              onAction: _cache[1] || (_cache[1] = ($event: any) => (_ctx.largeFiltersActive = true))
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('webhooks.enable_visual', 'f')), 1)
              ]),
              _: 1
            }))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }),
    (_ctx.isVisualMode)
      ? (_openBlock(), _createBlock(_component_WebhooksFiltersEditorVisual, {
          key: 0,
          largeFiltersActive: _ctx.largeFiltersActive,
          hasChanges: _ctx.hasChanges,
          mode: "webhook",
          modelValue: _ctx.item.filters,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.item.filters) = $event)),
          onClose: _cache[3] || (_cache[3] = ($event: any) => (_ctx.largeFiltersActive = false)),
          onReset: _ctx.reset
        }, null, 8, ["largeFiltersActive", "hasChanges", "modelValue", "onReset"]))
      : (_openBlock(), _createBlock(_component_WebhooksFiltersEditorJson, {
          key: 1,
          modelValue: _ctx.item.filters,
          "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.item.filters) = $event))
        }, null, 8, ["modelValue"])),
    (_ctx.resetModalActive)
      ? (_openBlock(), _createBlock(_component_NModalWindow, {
          key: 2,
          "close-icon": false
        }, {
          content: _withCtx(() => [
            _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.$t('webhooks.reset_question', 'f')), 1)
          ]),
          footer: _withCtx(() => [
            _createElementVNode("div", _hoisted_3, [
              _createVNode(_component_NButton, { onClick: _ctx.reset }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('webhooks.reset_confirm', 'f')), 1)
                ]),
                _: 1
              }, 8, ["onClick"]),
              _createVNode(_component_NButton, {
                type: "secondary",
                onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.resetModalActive = false))
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('common.cancel', 'f')), 1)
                ]),
                _: 1
              })
            ])
          ]),
          _: 1
        }))
      : _createCommentVNode("", true)
  ]))
}