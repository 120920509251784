import { formatFileSize } from '@/common/filters';
import { BodyObjectRequest, CardsService, CarObjectRequest, DetectService, FaceObjectRequest, ObjectsService } from '@/api';
import { CardTypesMap, ObjectsMultiToSingle } from '@/store/application/data.assets';
import { dataAssetsModule } from '@/store/application/data.assets.module';
import { DetectResult, ObjectType } from '@/components/detection/types';
import BatchLoaderLogger from '@/pages/cards/batch-loader/BatchLoaderLogger';
import { BatchLoaderModule } from '@/pages/cards/batch-loader/BatchLoaderModule';
import { configModule } from '@/store/config';

export type BatchLoaderItemFile = {
  blob: File | null;
  src: string;
  fileName: string;
  size: string;
};

export type BatchLoaderItemItem = {
  name: string;
  license_plate_number: string;
  comment: string;
};

const photoGroupAction: Record<any, any> = {
  ALL: 'all',
  BIGGEST: 'biggest',
  REJECT: 'reject'
};
const area = (bbox: Record<string, number>) => (bbox.bottom - bbox.top) * (bbox.right - bbox.left);
const biggest = (objects: any) => objects.reduce((acc: any, el: any) => (area(el.bbox) > area(acc.bbox) ? el : acc));

export class BatchLoaderItem {
  started = false;
  finished = false;
  id = Math.random();
  file: BatchLoaderItemFile | null = null;
  item: BatchLoaderItemItem | null = null;
  cards: number[] = [];
  statistics = {
    statusCode: '',
    errorCode: ''
  };

  _instance!: BatchLoaderModule;
  detectionObjectType = '';
  cardType = '';
  objectType = '';

  updateObjectType() {
    this.detectionObjectType = ObjectsMultiToSingle[this.objectType];
    this.cardType = dataAssetsModule.getCardTypeByObjectType(this.objectType);
  }

  async detect() {
    let result = {
      objects: {}
    };
    if (!this.file) {
      throw new Error('detect: file not set');
    }
    const file = this.file;
    const car_features = configModule.config.available_detect_features?.car;
    const attributes = this.detectionObjectType === 'car' && car_features && car_features.includes('license_plate') ? { license_plate: true } : {};
    let payload = { photo: file.blob, attributes: { [this.detectionObjectType]: attributes } };
    try {
      result = (await DetectService.detectCreate(payload as any)) as DetectResult;
    } catch (e) {
      throw new Error('detection_error');
    }
    return Object.keys(result.objects).length !== 0 ? { ...result.objects } : null;
  }

  private checkGroupPhoto(detectionItem: any) {
    const { photo_group } = this._instance.model;
    const originalItems = detectionItem[this.detectionObjectType];
    const items = originalItems.filter(({ low_quality }: { low_quality: boolean }) => !low_quality);

    if (items.length === 0) {
      throw new Error('no_objects');
    } else if (photo_group === photoGroupAction.ALL || items.length === 1) {
      return items;
    } else if (photo_group === photoGroupAction.REJECT && items.length > 1) {
      throw new Error('detection_error');
    } else if (photo_group === photoGroupAction.BIGGEST) {
      return [biggest(items)];
    }
  }

  async createCard(requestBody: any) {
    switch (this.cardType) {
      case CardTypesMap.Humans:
        return CardsService.cardsHumansCreate(requestBody);
      case CardTypesMap.Cars:
        return CardsService.cardsCarsCreate(requestBody);
    }
  }

  async deleteCard(id: number) {
    switch (this.cardType) {
      case CardTypesMap.Humans:
        return CardsService.cardsHumansDestroy(id);
      case CardTypesMap.Cars:
        return CardsService.cardsCarsDestroy(id);
    }
  }

  async addObjectToCard(form: FaceObjectRequest | BodyObjectRequest | CarObjectRequest) {
    switch (this.detectionObjectType) {
      case ObjectType.Face:
        return ObjectsService.objectsFacesCreate(form);
      case ObjectType.Body:
        return ObjectsService.objectsBodiesCreate(form);
      case ObjectType.Car:
        return ObjectsService.objectsCarsCreate(form);
    }
  }

  async createCardsWithObjects(detect: any) {
    for (const detectItem of detect) {
      const detectIndex: number = detect.indexOf(detectItem);
      const fileName = this.file!.fileName;

      const requestBody: any = {
        name: this._instance.getName(fileName) + (detectIndex > 0 ? `_${detectIndex}` : ''),
        comment: this._instance.getComment(fileName),
        watch_lists: this._instance.model.watch_lists,
        active: true,
        upload_list: this._instance.uploadList.id
      };

      // only for cars
      const license_plate_number = detectItem.features?.license_plate_number?.name;
      if (license_plate_number && license_plate_number !== 'unknown') {
        requestBody.license_plate_number = license_plate_number;
      }

      const card = await this.createCard(requestBody);
      try {
        await this.addObjectToCard({ card: card!.id, source_photo: this.file!.blob as File, detect_id: detectItem.id });
        this.cards.push(card!.id);
      } catch (e) {
        await this.deleteCard(card!.id);
      }
    }
  }

  fillAsFile(file: File) {
    this.item = null;
    this.file = {
      blob: file,
      src: URL.createObjectURL(file),
      fileName: file!.name,
      size: formatFileSize(file!.size, true)
    };
  }

  fillAsItem(name: string, license_plate_number: string, comment: string) {
    this.file = null;
    this.item = {
      name,
      license_plate_number,
      comment
    };
  }

  async uploadAsFile() {
    if (!this.file) {
      throw new Error('uploadAsFile: file not set');
    }
    try {
      this.started = true;
      const detectItem = this.file && (await this.detect());
      const filteredDetectItem = this.checkGroupPhoto(detectItem);
      await this.createCardsWithObjects(filteredDetectItem);
      this.statistics.statusCode = 'success';
      if (this.cards.length) {
        for (let i = 0; i < this.cards.length; i++) {
          const cardId = this.cards[i];
          await BatchLoaderLogger.addUploadListEntry(this.cardType, this._instance.uploadList.id, this.file.fileName, cardId, 200, 'success');
        }
      } else {
        await BatchLoaderLogger.addUploadListEntry(this.cardType, this._instance.uploadList.id, this.file.fileName, -1, 404, 'empty');
      }
    } catch (e) {
      this.statistics.errorCode = e.message;
      await BatchLoaderLogger.addUploadListEntry(this.cardType, this._instance.uploadList.id, this.file.fileName, -1, e.code || 500, e.message);
    } finally {
      this.finished = true;
    }
  }

  async uploadAsItem() {
    if (!this.item) {
      throw new Error('uploadAsItem: item not set');
    }
    try {
      this.started = true;
      const requestBody = {
        name: this.item.name,
        license_plate_number: this.item.license_plate_number,
        comment: this.item.comment,
        watch_lists: this._instance.model.watch_lists,
        active: true,
        upload_list: this._instance.uploadList.id
      };
      const card = await this.createCard(requestBody);
      this.cards.push(card!.id);
      this.statistics.statusCode = 'success';
      await BatchLoaderLogger.addUploadListEntry(this.cardType, this._instance.uploadList.id, '-', card!.id, 200, 'success');
    } catch (e) {
      this.statistics.errorCode = e.message;
      await BatchLoaderLogger.addUploadListEntry(this.cardType, this._instance.uploadList.id, '-', -1, e.code || 500, e.message);
    } finally {
      this.finished = true;
    }
  }

  async upload() {
    this.updateObjectType();
    if (this.file) {
      await this.uploadAsFile();
    } else {
      await this.uploadAsItem();
    }
  }
}
