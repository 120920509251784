
import { Options, Vue } from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import { ItemsActionNames } from '@/definitions/app/item.actions.name';
import { WatchList } from '@/api';
import { viewModelRepository } from '@/api/common';
import { dataModule } from '@/store/data';
import { actionHandler } from '@/store/data/ActionHandler';
import { MultisidebarItemTypes } from '@/store/multisidebar/types';
import NRadio from '@/uikit/radio/NRadio.vue';
import NTable, { ITableColumn, ITableModel, ITableRow } from '@/uikit/table/NTable.vue';
import NTableColumnCell from '@/uikit/table/NTableColumnCell.vue';
import HeaderBar from '@/components/common/HeaderBar.vue';
import { WatchListItemTextTypes } from '@/components/common/watch-list-types';
import WatchListItem from '@/components/common/WatchListItem.vue';

@Options({
  name: 'GroupWatchLists',
  components: { HeaderBar, NRadio, NTable }
})
export default class GroupWatchLists extends Vue {
  @Prop({ type: Number, required: true })
  readonly modelValue!: number;

  @Prop({ type: Boolean, default: false })
  readonly disabled!: boolean;

  columns: ITableColumn[] = [
    {
      header: {
        classes: ['bottom-border'],
        component: NTableColumnCell,
        props: { i18n_label: 'common.column_name' }
      },
      content: {
        path: 'name',
        component: WatchListItem,
        props: (item: WatchList) => ({ color: `#${item.color}`, name: item.name, textType: WatchListItemTextTypes.Link })
      }
    },
    {
      width: '100px',
      header: {
        classes: ['align-center', 'bottom-border'],
        component: NTableColumnCell,
        props: { i18n_label: 'common.column_off' }
      },
      content: {
        path: 'permission',
        classes: ['align-center'],
        component: NRadio,
        props: (item: any) => ({ name: 'watch_' + item.id, selectValue: 'off' })
      }
    },
    {
      width: '100px',
      header: {
        classes: ['align-center', 'bottom-border'],
        component: NTableColumnCell,
        props: { i18n_label: 'common.column_view' }
      },
      content: {
        path: 'permission',
        classes: ['align-center'],
        component: NRadio,
        props: (item: any) => ({ name: 'watch_' + item.id, selectValue: 'view' })
      }
    },
    {
      width: '100px',
      header: {
        classes: ['align-center', 'bottom-border'],
        component: NTableColumnCell,
        props: { i18n_label: 'common.column_change' }
      },
      content: {
        path: 'permission',
        classes: ['align-center'],
        component: NRadio,
        props: (item: any) => ({ name: 'watch_' + item.id, selectValue: 'edit' })
      }
    }
  ];
  header: ITableRow = { checked_all: false };
  content: ITableModel = [];
  searchText = '';

  readonly module = viewModelRepository.getPermissionsWatchListsListViewModel();

  get offCount() {
    return this.watchLists.filter((v) => v['permission'] === 'off').length;
  }

  get watchLists() {
    return this.content.filter((item: any) => item.name.toLowerCase().includes(this.searchText));
  }

  get fullWatchListsMap() {
    const watchLists = dataModule.watchListsModule.items;
    return Object.fromEntries(watchLists.map((v) => [v.id, v]));
  }

  async actionHandler({ type, row, path, value }: { type: string; row: any; path: string; value: any }) {
    if (type === 'change') {
      if (path === 'permission') {
        value = value === 'off' ? null : value;
        if (!row.permissions.toString().includes(this.modelValue.toString())) Object.assign(row.permissions, { [this.modelValue]: value });
        await this.module.update(row);
        await this.module.get({ resetState: false });
      } else if (path === 'name') {
        await actionHandler.run(ItemsActionNames.ShowItem, { type: MultisidebarItemTypes.WatchLists, rawItem: row.id });
      }
    }
  }

  async mounted() {
    this.module.filter.current.limit = 1000;
    await this.module.get();
    this.updateTable();
  }

  @Watch('modelValue', { deep: true })
  updateTable() {
    this.content = this.module.items.map((item) => ({
      ...item,
      color: this.fullWatchListsMap[item.id].color,
      permission: item.permissions && item.permissions[this.modelValue] ? item.permissions[this.modelValue] : 'off'
    }));
  }
}
