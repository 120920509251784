
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { ItemsActionName } from '@/definitions/app/item.actions.name';
import { ItemViewModel } from '@/definitions/view-models';
import { ModelAclResult } from '@/store/acl/types';
import { actionHandler } from '@/store/data/ActionHandler';
import { MultisidebarItem, MultisidebarSharedState } from '@/store/multisidebar/types';
import MultisidebarHeader from '@/components/multisidebar/MultisidebarHeader.vue';
import NTabs from '@/uikit/tabs/NTabs.vue';
import SidebarSection from '@/pages/annex/sidebar/SidebarSection.vue';
import NButton from '@/uikit/buttons/NButton.vue';
import NIcon from '@/uikit/icons/NIcon.vue';
import MultisidebarActions from '@/components/multisidebar/MultisidebarActions.vue';
import { Alert } from '@/api';
import FastCommentsForm from '@/pages/alerts/forms/FastCommentsForm.vue';
import AlertRuleForm from '@/pages/alerts/forms/AlertRuleForm.vue';
import { NForm } from '@/uikit';
import { EditorSection, EditorSections } from '@/pages/webhooks/components/types';
import generalSchema from '@/pages/alerts/forms/general.schema';
import deliveryChannelsSchema from '@/pages/alerts/forms/delivery.channels.schema';
import settingsSchema from '@/pages/alerts/forms/settings.schema';
import { AlertRuleTabItems, AlertRuleTabs } from '@/pages/alerts/alert.definitions';
import { IFormLayoutBlocks } from '@/uikit/forms/NFormBlocks.vue';
import { IFormLayout } from '@/uikit/forms/NForm.vue';
import MultisidebarSaveActions from '@/components/multisidebar/MultisidebarSaveActions.vue';
import WebhooksFiltersEditorVisual from '@/pages/webhooks/components/WebhooksFiltersEditorVisual.vue';
import { actionsModule } from '@/store/data/ActionsModule';

@Options({
  name: 'AlertRuleSidebar',
  components: {
    WebhooksFiltersEditorVisual,
    MultisidebarSaveActions,
    NForm,
    AlertRuleForm,
    FastCommentsForm,
    MultisidebarActions,
    NIcon,
    NButton,
    SidebarSection,
    NTabs,
    MultisidebarHeader
  },
  emits: []
})
export default class AlertRuleSidebar extends Vue {
  @Prop({ type: Object, required: true })
  readonly sidebarItem!: MultisidebarItem<ItemViewModel<Alert>>;

  @Prop({ type: Object, required: true })
  readonly sidebarSharedState!: MultisidebarSharedState;

  @Prop({ type: Object, required: true })
  readonly modelAcl!: ModelAclResult;

  @Prop({ type: String, required: true })
  readonly dataQa!: string;

  largeFilterActive = false;

  formRef: NForm | null = null;

  get tab() {
    return this.sidebarSharedState.activeTab;
  }

  get isAlertRulesSelected() {
    return this.tab === AlertRuleTabItems.AlertRules;
  }

  get isFastCommentsSelected() {
    return this.tab === AlertRuleTabItems.FastComments;
  }

  get hasChanges(): boolean {
    return this.sidebarItem.model.hasChanges;
  }

  get hasFiltersChanges(): boolean {
    return this.item.filters && Object.keys(this.item.filters).length > 0;
  }

  get item() {
    return this.sidebarItem.model.item as Alert;
  }

  get filterSections(): EditorSection[] {
    return [EditorSections.FaceEvents, EditorSections.BodyEvents, EditorSections.CarEvents, EditorSections.Counters];
  }

  get formSchema(): IFormLayoutBlocks | IFormLayout | null {
    let result: IFormLayoutBlocks | IFormLayout | null = null;
    switch (this.tab) {
      case AlertRuleTabItems.General:
        result = generalSchema;
        break;
      case AlertRuleTabItems.DeliveryChannels:
        result = deliveryChannelsSchema;
        break;
      case AlertRuleTabItems.Settings:
        result = settingsSchema;
        break;
    }
    return result;
  }

  get isFormDisabled() {
    return !(this.sidebarItem.model.isNew ? this.modelAcl.add : this.modelAcl.update);
  }

  get headerActions() {
    return actionsModule
      .getItemActions(this.modelAcl, {
        hasChanges: this.sidebarItem.model.hasChanges,
        hasActive: true,
        hasDelete: true,
        hasDuplicate: true,
        currentActive: this.item.active
      })
      .map(actionsModule.computeActionByName);
  }

  get tabItems() {
    return AlertRuleTabs.map((v: string) => ({ name: v, label: this.$t(`alerts.tabs.${v}`, 'f') }));
  }

  get canSave() {
    return Object.keys(this.item?.filters ?? {}).length > 0;
  }

  reset() {
    return this.sidebarItem.model.reset();
  }

  resetFilters() {
    return (this.item!.filters = {});
  }

  setFormRef(value: NForm) {
    this.formRef = value;
  }

  actionHandler(action: string) {
    // annexAlarmActionHandler(action, this.item);
  }

  handleAction(v: ItemsActionName) {
    actionHandler.run(v, this.sidebarItem);
  }

  created() {
    actionHandler.getIsItemFormValid = () => this.formRef?.validateAndDisplayErrors() ?? true;
  }
}
