import { dataAssetsModule } from '@/store/application/data.assets.module';
import { cameraResetModule } from '@/store/camera/camera.reset.module';
import { IFormContext, IFormLayout, IFormModel } from '@/uikit/forms/NForm.vue';
import NSwitch from '@/uikit/switch/NSwitch.vue';
import NTextDelimiter from '@/uikit/text/NTextDelimiter.vue';

const validateDetectors = {
  handler: function (context: IFormContext) {
    return context.model.stream_settings.detectors.face || context.model.stream_settings.detectors.body || context.model.stream_settings.detectors.car;
  },
  i18n_message: 'ds.one_detector_must_be_on'
};

const detectors: IFormLayout = [
  {
    classes: 'n-form-w-6  n-form-pad-10',
    component: NTextDelimiter,
    props: { i18n_label: 'ds.detectors' }
  },
  [
    {
      name: 'face',
      classes: 'n-form-w-2 n-form-pad-10 n-form-min-h-30 n-form-vcenter',
      component: NSwitch,
      props: { i18n_label: 'ds.faces' },
      encode: function (this: IFormContext, model: IFormModel, value: boolean) {
        cameraResetModule.toggleDetector(this.model, 'face');
      },
      decode: function (this: IFormContext) {
        return !!this.model.stream_settings.detectors.face;
      },
      hidden: () => !dataAssetsModule.isObjectAvailable('face')
    },
    {
      name: 'body',
      classes: 'n-form-w-2 n-form-pad-10 n-form-min-h-30 n-form-vcenter',
      component: NSwitch,
      props: { i18n_label: 'ds.bodies' },
      encode: function (this: IFormContext, model: IFormModel, value: boolean) {
        cameraResetModule.toggleDetector(this.model, 'body');
      },
      decode: function (this: IFormContext) {
        return !!this.model.stream_settings.detectors.body;
      },
      hidden: () => !dataAssetsModule.isObjectAvailable('body')
    },
    {
      name: 'car',
      classes: 'n-form-w-2 n-form-pad-10 n-form-min-h-30 n-form-vcenter',
      component: NSwitch,
      props: { i18n_label: 'ds.cars' },
      encode: function (this: IFormContext, model: IFormModel, value: boolean) {
        cameraResetModule.toggleDetector(this.model, 'car');
      },
      decode: function (this: IFormContext) {
        return !!this.model.stream_settings.detectors.car;
      },
      hidden: () => !dataAssetsModule.isObjectAvailable('car')
    }
  ]
];

export { detectors };
