
import { computed, defineComponent, reactive, toRefs, unref } from 'vue';
import { string } from 'vue-types';
import NForm, { IFormLayout } from '@/uikit/forms/NForm.vue';
import NInput from '@/uikit/input/NInput.vue';

export default defineComponent({
  name: 'CameraLookupAuthenticationForm',
  components: { NForm },
  props: {
    login: string().isRequired,
    password: string().isRequired
  },
  emits: ['update:login', 'update:password'],
  setup: (props, { emit }) => {
    const { login, password } = toRefs(props);
    const model = reactive({
      login: computed({
        get: () => unref(login),
        set: (value) => emit('update:login', value)
      }),
      password: computed({
        get: () => unref(password),
        set: (value) => emit('update:password', value)
      })
    });
    return { model };
  },
  computed: {
    layout(): IFormLayout {
      return [
        {
          classes: 'label-m',
          component: NInput,
          i18n_label: 'common.login',
          path: 'login',
          validators: [{ name: 'required' }]
        },
        {
          classes: 'label-m',
          component: NInput,
          i18n_label: 'common.password',
          path: 'password',
          props: { password: true },
          validators: [{ name: 'required' }]
        }
      ];
    }
  },
  methods: {
    validate(): boolean {
      const form = this.$refs.form;
      const isValid: boolean = form.validate();
      return !isValid && form.displayErrors(), isValid;
    }
  }
});
