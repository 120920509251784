import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createSlots as _createSlots, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "multisidebar-content__main" }
const _hoisted_2 = ["data-qa"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CardMonitoringStatus = _resolveComponent("CardMonitoringStatus")!
  const _component_MultisidebarHeader = _resolveComponent("MultisidebarHeader")!
  const _component_NTabs = _resolveComponent("NTabs")!
  const _component_CardForm = _resolveComponent("CardForm")!
  const _component_NForm = _resolveComponent("NForm")!
  const _component_FilterDateRange = _resolveComponent("FilterDateRange")!
  const _component_ItemsView = _resolveComponent("ItemsView")!
  const _component_CaseCardsView = _resolveComponent("CaseCardsView")!
  const _component_DetectionDialog = _resolveComponent("DetectionDialog")!
  const _component_MultisidebarSaveActions = _resolveComponent("MultisidebarSaveActions")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_MultisidebarHeader, {
      "model-acl": _ctx.modelAcl,
      label: _ctx.currentItem.name,
      inactivated: !_ctx.currentItem?.active,
      changed: _ctx.module.hasChanges,
      actions: _ctx.headerActions,
      "data-qa": _ctx.dataQa + '.header',
      onAction: _cache[0] || (_cache[0] = (v) => _ctx.actionHandler(v, _ctx.sidebarItem))
    }, _createSlots({ _: 2 }, [
      (_ctx.isPuppeteerEnabled && _ctx.hasAnyMonitoringRecord && _ctx.currentItemMonitoringRecord)
        ? {
            name: "extra-status",
            fn: _withCtx(() => [
              _createVNode(_component_CardMonitoringStatus, {
                item: _ctx.currentItemMonitoringRecord,
                "data-qa": _ctx.dataQa + '.monitoring-status'
              }, null, 8, ["item", "data-qa"])
            ])
          }
        : undefined
    ]), 1032, ["model-acl", "label", "inactivated", "changed", "actions", "data-qa"]),
    _createVNode(_component_NTabs, {
      items: _ctx.tabsItems,
      modelValue: _ctx.sidebarSharedState.activeTab,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.sidebarSharedState.activeTab) = $event)),
      class: "multisidebar-content__tabs control-m",
      "data-qa": _ctx.dataQa + '.tabs'
    }, null, 8, ["items", "modelValue", "data-qa"]),
    _createElementVNode("div", _hoisted_1, [
      (_ctx.sidebarSharedState.activeTab === _ctx.TabItemsMap.Info)
        ? (_openBlock(), _createBlock(_component_CardForm, {
            key: 0,
            ref: "cardFormInstance",
            item: _ctx.currentItem,
            event: _ctx.fromEventModel?.item,
            objects: _ctx.fromEventObjectType,
            "card-type": _ctx.cardType,
            disabled: _ctx.disabled,
            "data-qa": _ctx.dataQa + '.card-form'
          }, null, 8, ["item", "event", "objects", "card-type", "disabled", "data-qa"]))
        : _createCommentVNode("", true),
      (_ctx.isMetaTab)
        ? (_openBlock(), _createBlock(_component_NForm, {
            key: 1,
            ref: "cardFormInstance",
            model: _ctx.currentItem.meta,
            layout: _ctx.metaTabFormSchema,
            disabled: _ctx.disabled,
            "data-qa": _ctx.dataQa + '.meta.' + _ctx.sidebarSharedState.activeTab
          }, null, 8, ["model", "layout", "disabled", "data-qa"]))
        : _createCommentVNode("", true),
      (_ctx.sidebarSharedState.activeTab === _ctx.TabItemsMap.Locations)
        ? (_openBlock(), _createBlock(_component_FilterDateRange, {
            key: 2,
            "clear-button": false,
            "has-intervals": false,
            model: _ctx.itemsModule?.filter?.current,
            "gte-prop": "event_timestamp_gte",
            "lte-prop": "event_timestamp_lte",
            "data-qa": _ctx.dataQa + '.filter-date-range'
          }, null, 8, ["model", "data-qa"]))
        : _createCommentVNode("", true),
      (_ctx.itemsModule)
        ? (_openBlock(), _createBlock(_component_ItemsView, {
            card: _ctx.currentItem,
            key: _ctx.itemsModule.name,
            module: _ctx.itemsModule,
            "model-acl": _ctx.tabModelAcl,
            sortTypes: _ctx.itemsSortTypes,
            "upload-feature": _ctx.uploadFeature && !_ctx.disabled,
            matchedCaseClusters: _ctx.itemsModule === _ctx.matchedCaseClustersVM,
            relatedCaseClusters: _ctx.itemsModule === _ctx.relatedCaseClustersVM,
            locations: _ctx.itemsModule === _ctx.remoteDailyEventsVM,
            "data-qa": _ctx.dataQa + '.items.' + _ctx.sidebarSharedState.activeTab,
            onAction: _cache[2] || (_cache[2] = (v, payload) => _ctx.itemActionHandler(v, payload))
          }, null, 8, ["card", "module", "model-acl", "sortTypes", "upload-feature", "matchedCaseClusters", "relatedCaseClusters", "locations", "data-qa"]))
        : _createCommentVNode("", true),
      (_ctx.sidebarSharedState.activeTab === _ctx.TabItemsMap.CaseHumanCards)
        ? (_openBlock(), _createBlock(_component_CaseCardsView, {
            key: 4,
            "card-id": _ctx.currentItem.id,
            onAction: _ctx.actionHandler,
            "data-qa": _ctx.dataQa + '.items.' + _ctx.sidebarSharedState.activeTab
          }, null, 8, ["card-id", "onAction", "data-qa"]))
        : _createCommentVNode("", true),
      _createElementVNode("input", {
        name: "file",
        type: "file",
        ref: "fileInput",
        onChange: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.setFile && _ctx.setFile(...args))),
        class: "hide-element",
        "data-qa": _ctx.dataQa + '.file'
      }, null, 40, _hoisted_2),
      (_ctx.showDetectionDialog)
        ? (_openBlock(), _createBlock(_component_DetectionDialog, {
            key: 5,
            "image-file": _ctx.file,
            options: _ctx.objectType,
            lockApplyButton: _ctx.applying,
            onApply: _ctx.applyDetection,
            onClose: _cache[4] || (_cache[4] = ($event: any) => (_ctx.showDetectionDialog = false)),
            "data-qa": _ctx.dataQa + '.detection-dialog'
          }, null, 8, ["image-file", "options", "lockApplyButton", "onApply", "data-qa"]))
        : _createCommentVNode("", true)
    ]),
    _createVNode(_component_MultisidebarSaveActions, {
      "model-acl": _ctx.modelAcl,
      "sidebar-item": _ctx.sidebarItem,
      "data-qa": _ctx.dataQa + '.save-actions'
    }, null, 8, ["model-acl", "sidebar-item", "data-qa"])
  ]))
}