import NText from '@/uikit/text/NText.vue';
import { ITableColumn } from '@/uikit/table/NTable.vue';
import NDateTimeText from '@/uikit/text/NDateTimeText.vue';
import { NButton } from '@/uikit';

const columns: ITableColumn[] = [
  {
    header: {
      classes: ['bottom-border'],
      component: NText,
      props: { i18n_modelValue: 'common.id' }
    },
    content: {
      path: 'id',
      component: NText,
      props: { align: 'left' }
    }
  },
  {
    header: {
      classes: ['bottom-border'],
      component: NText,
      props: { i18n_modelValue: 'common.photo' }
    },
    content: {
      path: 'source_photo',
      component: NText
    }
  },
  {
    header: {
      classes: ['bottom-border'],
      component: NText,
      props: { i18n_modelValue: 'common.card' }
    },
    content: {
      path: 'card_id',
      component: function ({ item }) {
        const props = { type: 'link' };
        if (item.human_card_id > 0) {
          return <NButton {...props}>{item.human_card_id}</NButton>;
        } else if (item.car_card_id > 0) {
          return <NButton {...props}>{item.car_card_id}</NButton>;
        }
        return <NText> - </NText>;
      }
    }
  },
  {
    header: {
      classes: ['bottom-border'],
      component: NText,
      props: { i18n_modelValue: 'common.error' }
    },
    content: {
      path: 'error_desc',
      component: NText
    }
  },
  {
    header: {
      classes: ['bottom-border'],
      component: NText,
      props: { i18n_modelValue: 'common.created' }
    },
    content: {
      path: 'created_date',
      component: NDateTimeText
    }
  }
];

export default columns;
