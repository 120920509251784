
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { ItemsActionName, ItemsActionNames } from '@/definitions/app/item.actions.name';
import { ItemViewModel } from '@/definitions/view-models';
import { PageTypes } from '@/store/application/page.definitions';
import { MultisidebarItem, MultisidebarItemType, MultisidebarSharedState } from '@/store/multisidebar/types';
import NTabs from '@/uikit/tabs/NTabs.vue';
import Confidence from '@/components/common/Confidence.vue';
import { eventEpisodeAdapter, EventView } from '@/components/events/adapter';
import MultisidebarHeader from '@/components/multisidebar/MultisidebarHeader.vue';
import EpisodeEventsPage from '@/pages/events/EpisodeEventsPage.vue';
import EventInfoSidebarContent from '@/pages/events/EventInfoSidebarContent.vue';
import {
  CardTypesMap,
  DetectionEventType,
  DetectionEventTypes,
  EventTypes,
  getDetectionEventTypeByMsbType,
  ObjectsMultiToSingle,
  ObjectsType
} from '@/store/application/data.assets';
import { dataAssetsModule } from '@/store/application/data.assets.module';
import { actionHandler } from '@/store/data/ActionHandler';
import { EventDetails } from '@/uikit/thumbnail';
import {
  BodyEvent,
  BodyObjectRequest,
  CardsService,
  CarEpisode,
  CarEvent,
  CarObjectRequest,
  FaceEvent,
  FaceObjectRequest,
  HumanEpisode,
  ObjectsService
} from '@/api';
import { ModelAclResult } from '@/store/acl/types';
import { cardPhotoUploaderModule } from '@/store/cards/CardPhotoUploaderModule';
import { dataModule } from '@/store/data';
import { multisidebarModule } from '@/store/multisidebar';
import { MultisidebarItemTypes } from '@/store/multisidebar/types';
import { websocketModule } from '@/store/ws/websocket.module';
import LiveLabel from '@/components/common/LiveLabel.vue';
import { ObjectType } from '@/components/detection/types';
import EventGeo from '@/pages/events/geo/EventGeo.vue';

const EpisodeTabNames = {
  Info: 'info',
  Events: 'events',
  Geo: 'geo'
};

const EventsTabNames = {
  Info: 'info',
  Geo: 'geo'
};


const EpisodesTabs = Object.values(EpisodeTabNames);
const EventsTabs = Object.values(EventsTabNames);

export type Item = FaceEvent | BodyEvent | CarEvent | HumanEpisode | CarEpisode;

@Options({
  name: 'EventContentItem',
  components: { EventGeo, Confidence, EpisodeEventsPage, EventInfoSidebarContent, LiveLabel, MultisidebarHeader, NTabs }
})
export default class EventContentItem extends Vue {
  @Prop({ type: Object, required: true })
  readonly sidebarItem!: MultisidebarItem<ItemViewModel<Item>>;

  @Prop({ type: Object, required: true })
  readonly sidebarSharedState!: MultisidebarSharedState;

  @Prop({ type: Object, required: true })
  readonly modelAcl!: ModelAclResult;

  get headerLabel() {
    if (this.isEvents) {
      return this.$t(this.currentEventViewItem?.matched_card ? 'events.matched_event' : 'events.unmatched_event', 'f');
    } else return this.$t(this.currentEventViewItem?.matched_card ? 'events.matched_episode' : 'events.unmatched_episode', 'f');
  }

  get rawType() {
    return this.sidebarItem.type.split('_')[1];
  }

  get rawItem() {
    return this.sidebarItem.model.item;
  }

  get objectType() {
    return this.isEvents ? this.rawType : dataAssetsModule.getObjectTypeByEpisode(this.rawItem as HumanEpisode | CarEpisode);
  }

  get cardType() {
    return this.isEvents ? dataAssetsModule.getCardTypeByObjectType(this.rawType) : dataAssetsModule.getCardTypeByEpisodeType(this.rawType);
  }

  get isEvents(): boolean {
    return this.pageType === PageTypes.Events;
  }

  get pageType() {
    return this.sidebarItem.type.split('_')[0];
  }

  get isLiveEpisode() {
    return !this.isEvents && this.currentEventViewItem?.open;
  }

  get TabNames() {
    return EpisodeTabNames;
  }

  get tabsItems(): any[] | null {
    let tabs: string[] = [];
    const hasCoordinates = this.currentEventViewItem?.latitude && this.currentEventViewItem?.longitude;
    const filterGeoTab = (v: string) => v === 'geo' ? hasCoordinates : true;
    const wrapStringToItem = (v: string) => ({ name: v, label: this.$t(`common.${v}`, 'f') });

    switch (this.pageType) {
      case PageTypes.Episodes:
        tabs = EpisodesTabs;
        break;
      case PageTypes.Events:
        tabs = EventsTabs;
        break;
    }

    const result = tabs.filter(filterGeoTab).map(wrapStringToItem);
    return result;
  }

  get currentItem() {
    return this.module.item;
  }

  get module(): ItemViewModel<any> {
    return this.sidebarItem.model;
  }

  get currentEventViewItem(): EventView | null {
    return this.currentItem ? eventEpisodeAdapter(this.currentItem, this.objectType) : null;
  }

  get actions() {
    return this.isEvents
      ? [
          {
            name: ItemsActionNames.Search,
            i18n_label: 'common.search',
            hidden: true
          },
          {
            name: ItemsActionNames.CreateCard,
            i18n_label: 'common.use_for_a_card',
            hidden: true
          }
        ]
      : [];
  }

  get websocketModule() {
    return websocketModule;
  }

  episodeEventActionHandler(action: string, id: string | number, item: any) {
    switch (action) {
      case EventDetails.ShowInfo:
        actionHandler.run(ItemsActionNames.ShowItem, { type: `events_${this.objectType}`, rawItem: item || id });
        break;
    }
  }

  showPlayer(cameraId: number) {
    const timeFrom = new Date(this.module.item.created_date).getTime() / 1000;
    this.$videoPlayer.playArchive(cameraId, timeFrom - 3);
  }

  actionHandler(action: ItemsActionName, payload: any) {
    switch (action) {
      case ItemsActionNames.ShowPlayer:
        this.showPlayer(payload);
        break;

      case ItemsActionNames.Acknowledge:
        this.acknowledge(payload);
        break;

      case ItemsActionNames.NavigateToUser:
        this.navigateToUser(payload);
        break;

      case ItemsActionNames.CreateCard:
        this.createCardFromEvent(payload);
        break;

      default:
        actionHandler.run(action, payload);
    }
  }

  navigateToUser(id: number) {
    actionHandler.run(ItemsActionNames.ShowItem, { type: MultisidebarItemTypes.Users, rawItem: id });
  }

  async acknowledge(payload: any): Promise<void> {
    const id = this.sidebarItem.model.item?.id;
    if (this.isEvents) {
      await this.sidebarItem.model.update({ id, acknowledged: payload.value } as Partial<Item>);
    } else {
      const { best_car_event, best_body_event, best_face_event } = this.sidebarItem.model.item as any;
      await this.sidebarItem.model.update({ id, acknowledged: payload.value } as Partial<Item>);
      Object.assign(this.sidebarItem.model.item, { best_car_event, best_body_event, best_face_event });
    }
  }

  getCardType(type: string) {
    const computedType = type.split('_')[1];
    return dataAssetsModule.getCardTypeByObjectType(computedType);
  }

  extractPlaceNumber(eventItem: any) {
    return eventItem.features.license_plate_number?.name;
  }

  async showCreateCardSidebar(eventItem: any, type: string, msbItem: MultisidebarItem<any>) {
    const cleanMatchedLists = eventItem.matched_lists.filter((i: number) => i > 0 && dataModule.getIsEditableWatchList(i));
    const defaultWatchList = dataModule.editableWatchListsByCurrentUser.find((v) => v.id > 0);
    const msbType: MultisidebarItemType = type === CardTypesMap.Humans ? MultisidebarItemTypes.CardsHumans : MultisidebarItemTypes.CardsCars;
    const licencePlateNumber = eventItem.features.license_plate_number?.name;
    const licencePlateNumberDetected = eventItem.features.license_plate_number?.name && eventItem.features.license_plate_number?.name !== 'unknown';
    const itemProps = {
      name: eventItem.id,
      license_plate_number: licencePlateNumberDetected ? licencePlateNumber : '',
      watch_lists: cleanMatchedLists.length > 0 ? cleanMatchedLists : defaultWatchList ? [defaultWatchList.id] : []
    };
    await multisidebarModule.addNewItemByType(msbType, itemProps, { from: msbItem });
  }

  async createCardFromEvent(payload: any) {
    const type = this.getCardType(payload.type);
    const eventItem = payload.model.item;
    const detectionEventType = getDetectionEventTypeByMsbType(payload.type);
    const result = await cardPhotoUploaderModule.uploadByEvent(eventItem, detectionEventType!, this.objectType as ObjectsType, undefined, type);

    if (!result.result) {
      await this.showCreateCardSidebar(eventItem, type, payload);
    } else {
      await actionHandler.run(ItemsActionNames.ShowItem, { type: `cards_${type}`, rawItem: result.cardId });
    }
  }
}
