
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { CasePhoto } from '@/api';
import NFormBlocks from '@/uikit/forms/NFormBlocks.vue';
import { casePhotoFormLayout } from './CasePhotoFormLayout';

@Options({
  name: 'CasePhotoForm',
  components: { NFormBlocks }
})
export default class CasePhotoForm extends Vue {
  @Prop({ type: Object, required: true })
  readonly item!: CasePhoto;

  @Prop({ type: Boolean, default: false, required: false })
  readonly disabled!: boolean;

  layout = casePhotoFormLayout;

  get formState() {
    return {
      created: this.item.id >= 0
    };
  }

  validate() {
    return this.$refs.form.validateAndDisplayErrors();
  }
}
