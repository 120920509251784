
import { Options, Vue } from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import { ItemsActionName } from '@/definitions/app/item.actions.name';
import { ItemViewModel } from '@/definitions/view-models';
import { Case, CasePhoto, CaseStatusEnum } from '@/api';
import { viewModelRepository } from '@/api/common';
import { ItemAclResult, ModelAclResult } from '@/store/acl/types';
import { actionHandler } from '@/store/data/ActionHandler';
import { actionsModule } from '@/store/data/ActionsModule';
import { MultisidebarItem } from '@/store/multisidebar/types';
import { NForm } from '@/uikit';
import MultisidebarHeader from '@/components/multisidebar/MultisidebarHeader.vue';
import MultisidebarSaveActions from '@/components/multisidebar/MultisidebarSaveActions.vue';
import CasePhotoSidebarContent from '@/pages/cases/photos/CasePhotoSidebarContent.vue';
import { aclModule } from '@/store/acl';
import { CasePermissions } from '@/api/models/CasePermissions';
import { loadPermissions, PermissionsModelNamesMap } from '@/components/permissions/permission-helpers';

@Options({
  name: 'CasePhotoSidebar',
  components: {
    CasePhotoSidebarContent,
    MultisidebarHeader,
    MultisidebarSaveActions
  }
})
export default class CasePhotoSidebar extends Vue {
  @Prop({ type: Object, required: true })
  readonly sidebarItem!: MultisidebarItem<ItemViewModel<any>>;

  @Prop({ type: Object })
  readonly modelAcl!: ModelAclResult;

  case: Case | null = null;
  casePermissions: CasePermissions | null = null;

  get isActionsDisabled() {
    return this.isCaseArchived || !this.caseAcl.update;
  }

  get isCaseArchived() {
    return this.case?.status !== CaseStatusEnum.Open;
  }

  get currentItem(): CasePhoto {
    return this.module.item!;
  }

  get module(): ItemViewModel<any> {
    return this.sidebarItem.model;
  }

  get actions() {
    return actionsModule
      .getItemActions(this.modelAcl, {
        hasProcess: this.caseAcl.update,
        hasDelete: this.caseAcl.update
      })
      .map(actionsModule.computeActionByName);
  }

  created() {
    actionHandler.getIsItemFormValid = this.validateForm.bind(this);
  }

  mounted(): void {
    this.loadCase();
  }

  get caseAcl(): ItemAclResult {
    const result = aclModule.getCaseItemAclByUserGroupMap((this.casePermissions?.permissions as any) || {}, this.modelAcl);
    return result;
  }

  @Watch('item')
  async loadCase() {
    const caseId = this.currentItem?.case;
    const caseModel = viewModelRepository.getCasesItemViewModel();
    if (caseId) {
      await caseModel.get(caseId);
      this.case = caseModel.item!;
      this.casePermissions = await loadPermissions(caseId, PermissionsModelNamesMap.Cases);
    } else {
      this.case = null;
    }
  }

  validateForm() {
    const form: NForm | undefined = this.$refs.content.form as any as NForm;
    const result = form ? form.validate() : true;
    if (!result) form.displayErrors();
    return result;
  }

  actionHandler(action: ItemsActionName, payload: any) {
    actionHandler.run(action, payload);
  }
}
