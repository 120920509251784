import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "batch-upload-logs-details" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NTable = _resolveComponent("NTable")!
  const _component_InfiniteScroll = _resolveComponent("InfiniteScroll")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_NTable, {
      columns: _ctx.tableSchema,
      content: _ctx.module.items,
      onCellClick: _ctx.cellClick
    }, null, 8, ["columns", "content", "onCellClick"]),
    _createVNode(_component_InfiniteScroll, {
      filters: _ctx.module.filter.current,
      onLoadMore: _cache[0] || (_cache[0] = ($event: any) => (_ctx.module.append()))
    }, null, 8, ["filters"])
  ]))
}