import { get } from 'lodash';
import { Camera, VideoArchive } from '@/api';
import { languageModule } from '@/store/languages';
import { dataSourceVideosModule } from '@/store/videos';
import { IFormLayout } from '@/uikit/forms/NForm.vue';
import NText from '@/uikit/text/NText.vue';
import NTextDelimiter from '@/uikit/text/NTextDelimiter.vue';
import CameraScreenshot from '@/components/data-source/CameraScreenshot.vue';
import VideoProcessingPlayer from '@/components/data-source/VideoProcessingPlayer.vue';
import { configModule } from '@/store/config';

export function getDataSourceInfo(isVideoFile = false, isPlayEnabled: boolean = false) {
  const screenshotField = {
    path: 'screenshot',
    classes: 'label-m n-form-w-12 n-form-pad-10',
    component: CameraScreenshot,
    props: { width: 520, displayWidth: '520px', hasPlay: isPlayEnabled }
  };

  const commonLayout: IFormLayout = [
    {
      classes: 'label-m n-form-w-6 n-form-pad-10',
      component: NTextDelimiter,
      props: { i18n_label: 'ds.info' }
    },
    {
      path: 'id',
      classes: 'n-form-w-6 n-form-horizontal n-form-pad-10',
      i18n_label: 'common.id',
      component: NText
    },
    {
      classes: 'n-form-w-6 pad n-form-horizontal n-form-pad-10',
      i18n_label: 'common.status',
      component: NText,
      props: (item: VideoArchive | Camera) => {
        return {
          modelValue: isVideoFile ? getStatusLabelValue(item as any) : item?.health_status?.status,
          defaultClass: item?.error ? 'n-form-item_error' : ''
        };
      }
    },
    {
      path: 'health_status.statistic.processed_duration',
      classes: 'n-form-w-6 pad n-form-horizontal n-form-pad-10',
      i18n_label: 'ds.process_duration',
      component: NText
    },
    {
      path: 'health_status.statistic.frames_dropped',
      classes: 'n-form-w-6 pad n-form-horizontal n-form-pad-10',
      i18n_label: 'ds.frames_dropped',
      component: NText
    },
    {
      path: 'health_status.statistic.job_starts',
      classes: 'n-form-w-6 pad n-form-horizontal n-form-pad-10',
      i18n_label: 'ds.job_starts',
      component: NText
    }
  ];

  const statisticsHeader: IFormLayout = [
    {
      classes: 'label-m n-form-w-6 n-form-pad-10',
      component: NTextDelimiter,
      props: { i18n_label: 'ds.objects_statistics' }
    }
  ];

  const eventsStatistics: IFormLayout = [
    {
      path: isVideoFile ? 'face_count' : 'health_status.statistic.objects.face.posted',
      classes: 'n-form-w-6 pad n-form-horizontal n-form-pad-10',
      i18n_label: 'ds.faces_posted',
      component: NText,
      props: {
        defaultText: '-'
      }
    },
    {
      path: 'health_status.statistic.objects.face.not_posted',
      classes: 'n-form-w-6 pad n-form-horizontal n-form-pad-10',
      i18n_label: 'ds.faces_not_posted',
      component: NText,
      props: {
        defaultText: '-'
      }
    },
    {
      path: 'health_status.statistic.objects.face.failed',
      classes: 'n-form-w-6 pad n-form-horizontal n-form-pad-10',
      i18n_label: 'ds.faces_failed',
      component: NText,
      props: {
        defaultText: '-'
      }
    },
    {
      path: isVideoFile ? 'body_count' : 'health_status.statistic.objects.body.posted',
      classes: 'n-form-w-6 pad n-form-horizontal n-form-pad-10',
      i18n_label: 'ds.bodies_posted',
      component: NText,
      props: {
        defaultText: '-'
      }
    },
    {
      path: 'health_status.statistic.objects.body.not_posted',
      classes: 'n-form-w-6 pad n-form-horizontal n-form-pad-10',
      i18n_label: 'ds.bodies_not_posted',
      component: NText,
      props: {
        defaultText: '-'
      }
    },
    {
      path: 'health_status.statistic.objects.body.failed',
      classes: 'n-form-w-6 pad n-form-horizontal n-form-pad-10',
      i18n_label: 'ds.bodies_failed',
      component: NText,
      props: {
        defaultText: '-'
      }
    },
    {
      path: isVideoFile ? 'car_count' : 'health_status.statistic.objects.car.posted',
      classes: 'n-form-w-6 pad n-form-horizontal n-form-pad-10',
      i18n_label: 'ds.cars_posted',
      component: NText,
      props: {
        defaultText: '-'
      }
    },
    {
      path: 'health_status.statistic.objects.car.not_posted',
      classes: 'n-form-w-6 pad n-form-horizontal n-form-pad-10',
      i18n_label: 'ds.cars_not_posted',
      component: NText,
      props: {
        defaultText: '-'
      }
    },
    {
      path: 'health_status.statistic.objects.car.failed',
      classes: 'n-form-w-6 pad n-form-horizontal n-form-pad-10',
      i18n_label: 'ds.cars_failed',
      component: NText,
      props: {
        defaultText: '-'
      }
    }
  ];

  const caseClustrsStatistics: IFormLayout = [
    {
      path: 'face_case_cluster_count',
      classes: 'n-form-w-6 pad n-form-horizontal n-form-pad-10',
      i18n_label: 'ds.face_clusters',
      component: NText,
      props: {
        defaultText: '-'
      }
    }
  ];

  return [
    isVideoFile ? videoPlayerField : screenshotField,
    ...commonLayout,
    ...(configModule.features.events_enabled || configModule.features.cases_enabled ? statisticsHeader : []),
    ...(configModule.features.events_enabled ? eventsStatistics : []),
    ...(configModule.features.cases_enabled ? caseClustrsStatistics : [])
  ];
}

export function getStatusLabelValue(item: any): string {
  let result: any = dataSourceVideosModule.getStatusInfo(get(item, 'health_status.status'), item.progress, item.__upload_progress, item.finished);
  if (result.i18n_label) result.label = languageModule.getTranslatedToken(result.i18n_label, 'f');
  const errorMessage = item.error ? ` (${item.error})` : '';
  return result.label + errorMessage;
}

const videoPlayerField = {
  path: 'screenshot',
  classes: 'label-m n-form-w-12 n-form-pad-10',
  component: VideoProcessingPlayer,
  props: (item: VideoArchive) => ({ item })
};
