
import axios from 'axios';
import { nextTick, reactive } from 'vue';
import { Options, Vue } from 'vue-class-component';
import { Prop, Ref, Watch } from 'vue-property-decorator';
import { UploadList } from '@/api';
import { authModule } from '@/store/auth';
import { configModule } from '@/store/config';
import NButton from '@/uikit/buttons/NButton.vue';
import { formatDateTime } from '@/common/filters';

@Options({
  name: 'BatchUploadLogsDownload',
  components: { NButton }
})
export default class BatchUploadLogsDownload extends Vue {
  @Prop({ type: Object, required: true })
  readonly item!: UploadList;

  async download() {
    const item = this.item;
    if (!item) {
      console.error('downloadBatchUploadLogs: item not found!');
      return;
    }

    const user = item.name?.replace(/-\d+/, '');
    const date = formatDateTime(item.created_date, 'YYYY-MM-DD_HH-mm-ss');
    const filename = `${user}_${date}.csv`;

    const token = authModule.token?.toString();
    const imageUrl = (configModule.config.server?.url ?? '/') + `batch-upload/${item.id}/csv/?secret_key=${encodeURIComponent(item.secret_key)}`;
    const { data } = await axios({
      url: imageUrl,
      responseType: 'blob',
      headers: {
        Authorization: 'Token ' + encodeURIComponent(token!)
      }
    });

    const url = window.URL.createObjectURL(new Blob([data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', filename);
    document.body.appendChild(link);
    link.click();
  }
}
