
import { defineComponent } from 'vue';
import { object } from 'vue-types';
import { VmsPluginCamerasRequest } from '@/api';
import { VmsPluginCameraStream } from '@/api/models/VmsPluginCamerasRequest';
import NButton from '@/uikit/buttons/NButton.vue';
import { VmsSidebarCamerasTabListItemScreenshot, VmsSidebarCamerasTabListItemStreamTable } from './components';

const pass = () => {};

export default defineComponent({
  name: 'VmsSidebarCamerasTabListItem',
  components: {
    NButton,
    VmsSidebarCamerasTabListItemScreenshot,
    VmsSidebarCamerasTabListItemStreamTable
  },
  props: {
    camera: defineCameraProp()
  },
  emits: ['select'],
  methods: {
    copyCameraIdToClipboard(): void {
      navigator.clipboard.writeText(String(this.camera.id)).then(pass).catch(pass);
    },
    dispatchSelectEvent(stream: VmsPluginCameraStream): void {
      this.$emit('select', stream);
    }
  }
});

function defineCameraProp() {
  return object<VmsPluginCamerasRequest>().isRequired;
}
