import { Permission } from '../types';

export * from './plugin';
export * from './system';

export const BlockedPermissions: Permission[] = [
  'ffsecurity.add_all_own_sessions',
  'ffsecurity.add_notification',
  'ffsecurity.add_user',
  'ffsecurity.change_all_own_sessions',
  'ffsecurity.delete_notification',
  'ffsecurity.delete_user',
  'ffsecurity.add_externalvmsevent',
  'ffsecurity.change_externalvmsevent',
  'ffsecurity.delete_externalvmsevent',
  'ffsecurity.add_externalvmssendeventstatus',
  'ffsecurity.change_externalvmssendeventstatus',
  'ffsecurity.delete_externalvmssendeventstatus',
  'auth.change_group',
  'auth.add_group',
  'auth.delete_group',
  'ffsecurity_puppeteer.add_dailysearchevent',
  'ffsecurity_puppeteer.change_dailysearchevent',
  'ffsecurity_puppeteer.delete_dailysearchevent',
];
