
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { Line } from '@/api/models/Line';
import { LineAttributes } from '@/api/models/LineAttributes';
import { LinesService } from '@/api/services/LinesService';
import { ISelectItem, NSelect, NButton, NHint } from '@/uikit';
import NForm, { IFormContext, IFormModel } from '@/uikit/forms/NForm.vue';
import NInput from '@/uikit/input/NInput.vue';
import NTextDelimiter from '@/uikit/text/NTextDelimiter.vue';

type LineAttribute = keyof LineAttributes;

function getDistinctItems(items: string[]) {
  return [...new Set(items)];
}

@Options({
  name: 'LinesBI',
  components: { NButton, NForm, NHint, NTextDelimiter }
})
export default class LinesBI extends Vue {
  @Prop({ type: Object, required: true })
  readonly model!: Line;

  private attributesData: LineAttributes = {
    attributes: [],
    ab_attributes: [],
    ba_attributes: []
  };

  get layout() {
    return [
      {
        classes: 'label-m n-form-w-6 n-form-pad-10',
        i18n_label: 'lines.line_attributes',
        i18n_tooltip: 'lines.line_attributes_desc',
        component: NSelect,
        props: () => {
          return {
            multiple: true,
            allowCreate: true,
            keyField: '',
            i18n_placeholder: 'lines.attributes_placeholder',
            displayCreated: false,
            items: this.computeAttributeItems('attributes')
          };
        },
        encode: function (this: IFormContext, model: IFormModel, values: ISelectItem[]) {
          this.model.attributes = getDistinctItems(values.map(({ label }) => label)) || [];
        },
        decode: function (this: IFormContext) {
          return (this.model?.attributes || []).map((label: string) => ({ label, value: label }));
        }
      },
      {
        classes: 'n-form-w-6 n-form-block-start',
        component: (
          <NTextDelimiter>
            {this.$t('lines.entrance', 'f')} <span class="lines-bi__entrance-label">({this.$t('lines.line_crossing_direction_a_b', 'f')})</span>
          </NTextDelimiter>
        )
      },
      {
        path: 'ab_name',
        classes: 'label-m n-form-w-6 n-form-pad-10',
        i18n_label: 'lines.name',
        i18n_tooltip: 'lines.entrance_name_desc',
        props: {
          i18n_placeholder: 'lines.name_placeholder'
        },
        component: NInput
      },
      {
        classes: 'label-m n-form-w-6 n-form-pad-10',
        i18n_label: 'lines.entrance_attributes',
        i18n_tooltip: 'lines.entrance_attributes_desc',
        component: NSelect,
        props: () => {
          return {
            multiple: true,
            allowCreate: true,
            keyField: '',
            i18n_placeholder: 'lines.attributes_placeholder',
            displayCreated: false,
            items: this.computeAttributeItems('ab_attributes')
          };
        },
        encode: function (this: IFormContext, model: IFormModel, values: ISelectItem[]) {
          this.model.ab_attributes = getDistinctItems(values.map(({ label }) => label)) || [];
        },
        decode: function (this: IFormContext) {
          return (this.model?.ab_attributes || []).map((label: string) => ({ label, value: label }));
        }
      },
      {
        classes: 'n-form-w-6 n-form-block-start',
        component: (
          <NTextDelimiter>
            {this.$t('lines.exit', 'f')} <span class="lines-bi__exit-label">({this.$t('lines.line_crossing_direction_b_a', 'f')})</span>
          </NTextDelimiter>
        )
      },
      {
        path: 'ba_name',
        classes: 'label-m n-form-w-6 n-form-pad-10',
        i18n_label: 'lines.name',
        i18n_tooltip: 'lines.exit_name_desc',
        props: {
          i18n_placeholder: 'lines.name_placeholder'
        },
        component: NInput
      },
      {
        classes: 'label-m n-form-w-6 n-form-pad-10',
        i18n_label: 'lines.exit_attributes',
        i18n_tooltip: 'lines.exit_attributes_desc',
        component: NSelect,
        props: () => {
          return {
            multiple: true,
            allowCreate: true,
            keyField: '',
            i18n_placeholder: 'lines.attributes_placeholder',
            displayCreated: false,
            items: this.computeAttributeItems('ba_attributes')
          };
        },
        encode: function (this: IFormContext, model: IFormModel, values: ISelectItem[]) {
          this.model.ba_attributes = getDistinctItems(values.map(({ label }) => label)) || [];
        },
        decode: function (this: IFormContext) {
          return (this.model?.ba_attributes || []).map((label: string) => ({ label, value: label }));
        }
      }
    ];
  }

  computeAttributeItems(attributeField: LineAttribute) {
    const sharedLabels = this.attributesData[attributeField];
    const itemLabels = this.model[attributeField] ?? [];
    const labelsSet = new Set([...itemLabels, ...sharedLabels]);
    return [...labelsSet].map((label) => ({ label, value: label }));
  }

  validate() {
    return this.$refs.form.validateAndDisplayErrors();
  }

  async loadAttributesData() {
    this.attributesData = await LinesService.linesAttributesRetrieve();
  }

  created() {
    this.loadAttributesData();
  }
}
