
import { nextTick, reactive } from 'vue';
import { Options, Vue } from 'vue-class-component';
import { Prop, Ref, Watch } from 'vue-property-decorator';
import ImageViewer from '@/components/image-viewer/ImageViewer.vue';
import { convertToBBoxConfigs } from '@/components/photo-viewer/utils';

@Options({
  name: 'SimpleOverlay',
  components: { ImageViewer }
})
export default class SimpleOverlay extends Vue {
  @Prop({ type: String, required: true })
  readonly imageUrl!: string;

  @Prop({ type: Object, required: true })
  readonly bboxes!: any;

  @Prop({ type: Object, required: true })
  readonly line!: any;

  @Prop({ type: Object, required: true })
  readonly track!: any;

  get adaptedBBoxes() {
    return convertToBBoxConfigs(this.bboxes);
  }

  get adaptedLine() {
    return this.line;
  }

  get adaptedTrack() {
    return this.track;
  }
}
