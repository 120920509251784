
import Map from '@/components/map/Map.vue';
import { Options, Vue } from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import { Camera } from '@/api';
import CameraMarkerEditable from '@/components/map/CameraMarkerEditable.vue';
import { LatLngType } from '@/components/map/types';
import { isDefined } from '@/uikit/utils';
import NBaseBar from '@/uikit/bars/NBaseBar.vue';
import NButton from '@/uikit/buttons/NButton.vue';
import { closeFullscreen, openFullscreen } from '@/common/utils';
import MapProviderSelect from '@/components/map/MapProviderSelect.vue';
import { configModule } from '@/store/config';
import MapSearch from '@/components/map/MapSearch.vue';
import { Map as TMap } from 'leaflet';

const FieldsToReset = ['azimuth', 'longitude', 'latitude', 'angle_of_view', 'pitch'] as const;
type FieldToReset = typeof FieldsToReset[number];
type FieldToResetValue = Camera[FieldToReset];
type FieldToResetRecord = Record<FieldToReset, FieldToResetValue>;

@Options({
  name: 'CameraMap',
  components: {
    MapSearch,
    MapProviderSelect,
    NButton,
    NBaseBar,
    CameraMarkerEditable,
    Map
  }
})
export default class CameraMap extends Vue {
  @Prop({ type: Object, required: true })
  readonly item!: Camera;

  @Prop({ type: Boolean, default: false })
  readonly disabled!: boolean;

  map: TMap | null = null;
  providerId: string = 'default';
  isFullscreen = false;
  fieldsToReset: FieldToResetRecord | null = null;

  get config() {
    if (!configModule.config.map) {
      console.error('[Map]: set map configuration to use maps!');
    }
    return configModule.config.map;
  }

  get mapEnabled() {
    return configModule.features.maps_enabled;
  }

  get showCamera() {
    return isDefined(this.item.latitude) && isDefined(this.item.longitude);
  }

  get latLng() {
    return { lat: this.item.latitude as number, lng: this.item.longitude as number };
  }

  set latLng(latLng: LatLngType) {
    this.item.latitude = Number(latLng.lat.toFixed(9));
    this.item.longitude = Number(latLng.lng.toFixed(9));
  }

  get hasChanges() {
    return FieldsToReset.some((v) => this.item[v] !== this.fieldsToReset?.[v]);
  }

  handleFullscreenChange() {
    this.isFullscreen = document.fullscreenElement === this.$refs.cameraMap;
  }

  toggleFullscreen() {
    this.isFullscreen = !this.isFullscreen;
    this.isFullscreen ? openFullscreen(this.$refs.cameraMap) : closeFullscreen();
    this.map?.invalidateSize();
  }

  setCameraPosition(map: any) {
    const latLng = map.getCenter() as LatLngType;
    this.item.latitude = Number(latLng.lat.toFixed(9));
    this.item.longitude = Number(latLng.lng.toFixed(9));
  }

  resetFields() {
    if (this.fieldsToReset) {
      Object.entries(this.fieldsToReset).forEach(([key, value]) => {
        if (value !== undefined) {
          this.item[key as FieldToReset] = value;
        }
      });
    }
  }

  handleMapReady(map: TMap) {
    this.map = map;
    this.map.setMinZoom(15);
  }

  handleSearchSelect(result?: { lat: number; lng: number }) {
    if (result) {
      this.map?.setView(result);
    }
  }

  @Watch('item.modified_date')
  preserveFieldsToReset() {
    this.fieldsToReset = Object.fromEntries(FieldsToReset.map((v) => [v, this.item[v]])) as FieldToResetRecord;
  }

  mounted() {
    this.preserveFieldsToReset();
    window.addEventListener('fullscreenchange', this.handleFullscreenChange);
  }

  beforeUnmount() {
    window.removeEventListener('fullscreenchange', this.handleFullscreenChange);
  }
}
