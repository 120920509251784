
import axios, { CancelTokenSource } from 'axios';
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { Counter } from '@/api';
import { authModule } from '@/store/auth';
import { configModule } from '@/store/config';
import NButton from '@/uikit/buttons/NButton.vue';
import NCheckbox from '@/uikit/checkbox/NCheckbox.vue';
import NDraw from '@/uikit/draw/NDraw.vue';
import { DrawModes } from '@/uikit/draw/types';
import NIcon from '@/uikit/icons/NIcon.vue';
import NLoadingCircle from '@/uikit/loading/NLoadingCircle.vue';
import ImageLoadError from '@/components/common/ImageLoadError.vue';
import CameraName from '@/components/data-source/CameraName.vue';

function isRect(points: number[][]) {
  if (points.length !== 4) return false;
  const [p1, p2, p3, p4] = points;
  return p1[0] === p4[0] && p1[1] === p2[1] && p2[0] === p3[0] && p3[1] === p4[1];
}

@Options({
  name: 'CounterROI',
  components: { CameraName, ImageLoadError, NButton, NCheckbox, NDraw, NIcon, NLoadingCircle },
  emits: ['showModal']
})
export default class CounterROI extends Vue {
  @Prop({ type: Object, required: true })
  readonly model!: Counter;

  @Prop({ type: Number, required: true })
  readonly cameraId!: number;

  @Prop({ type: Boolean, default: true })
  readonly hasFullscreen!: boolean;

  @Prop({ type: Boolean, default: false })
  readonly disabled!: boolean;

  drawModes = DrawModes;

  loading = false;
  error = false;
  sourceCancelToken: CancelTokenSource | null = null;

  onlyRect = false;
  imageDataUrl = '';

  get hasCamera() {
    return this.cameraId !== -1 && this.imageDataUrl;
  }

  get points() {
    return (this.hasCamera && this.model.roi?.[this.cameraId]) || [];
  }

  set points(points: number[][]) {
    if (this.hasCamera) {
      if (!this.model.roi && points) {
        this.model.roi = {};
      }
      this.model.roi && (this.model.roi[this.cameraId] = points);
    }
  }

  mounted() {
    this.loadCameraImage();
  }

  async loadCameraImage(resetCache = false) {
    this.loading = true;
    this.error = false;
    try {
      if (this.sourceCancelToken) {
        this.sourceCancelToken.cancel();
        this.sourceCancelToken = null;
      }
      this.sourceCancelToken = axios.CancelToken.source();

      const token = authModule.token?.toString();
      const imageUrl = (configModule.config.server?.url ?? '/') + `cameras/${this.cameraId}/screenshot/`;
      const { data } = await axios({
        url: imageUrl,
        method: resetCache ? 'POST' : 'GET',
        responseType: 'blob',
        headers: {
          Authorization: 'Token ' + encodeURIComponent(token!)
        },
        cancelToken: this.sourceCancelToken.token
      });

      this.imageDataUrl = URL.createObjectURL(data);
    } catch (e) {
      this.error = true;
    } finally {
      this.loading = false;
      this.sourceCancelToken = null;
    }

    if (this.model.roi) {
      this.onlyRect = isRect(this.points);
    }
  }

  clearRect() {
    this.$refs.drawTool?.clear();
  }

  dispatchShowModal() {
    this.$emit('showModal');
  }
}
