import { VideoArchive } from '@/api';
import { NButton, NDateInput } from '@/uikit';
import { IFormContext, IFormLayout, IFormLayoutItem, IFormLayoutRow, IFormModel, IFormOptionalLayout } from '@/uikit/forms/NForm.vue';
import { getRequiredValidator } from '@/uikit/forms/validators';
import NInput from '@/uikit/input/NInput.vue';
import NSwitch from '@/uikit/switch/NSwitch.vue';
import NText from '@/uikit/text/NText.vue';
import NTextDelimiter from '@/uikit/text/NTextDelimiter.vue';
import { findLayoutItemByPath } from '@/common/form';
import CameraGroupName from '@/components/data-source/CameraGroupName.vue';
import CameraName from '@/components/data-source/CameraName.vue';
import FileSize from '@/components/data-source/FileSize.vue';
import { detectors } from './data-source-detectors';
import { configModule } from '@/store/config';

export function getDataSourceFileGeneralLayout(item: any = {}, availableObjects: string[], availableDataSourceFields: boolean = true): IFormLayout {
  const dataSourceFields: IFormOptionalLayout = [
    {
      path: 'camera_group',
      classes: 'n-form-w-3  n-form-pad-10',
      i18n_label: 'ds.camera_group',
      component: CameraGroupName,
      props: { excludeVideoArchive: false }
    },
    {
      path: 'camera',
      classes: 'n-form-w-3  n-form-pad-10',
      i18n_label: 'ds.camera_optional',
      component: CameraName,
      props: (item: VideoArchive) => {
        return { cameraGroup: item.camera_group };
      }
    }
  ];

  const livenessField: IFormLayoutItem = {
    classes: 'n-form-w-2 n-form-pad-10 n-form-min-h-30 n-form-vcenter',
    component: NSwitch,
    path: 'stream_settings.enable_liveness',
    props: { i18n_label: 'ds.enable_liveness' }
  };

  const definedFieldsDataSource: IFormOptionalLayout = [
    {
      classes: 'n-form-w-6  n-form-pad-10',
      component: NTextDelimiter,
      props: { i18n_label: 'ds.info' }
    },
    ...detectors,
    ...(availableDataSourceFields ? dataSourceFields : [])
  ];

  const result: IFormOptionalLayout = [
    [
      {
        path: 'name',
        classes: 'n-form-w-5 n-form-pad-10',
        i18n_label: 'ds.name',
        component: NInput,
        validators: [getRequiredValidator()]
      },
      {
        path: 'file_size',
        classes: 'n-form-w-2 n-form-pad-10',
        i18n_label: 'ds.file_size',
        component: FileSize
      }
    ],
    configModule.features.cases_enabled ? null : livenessField,
    item.url
      ? {
          path: 'url',
          classes: 'n-form-w-3  n-form-pad-10',
          i18n_label: 'ds.url',
          component: NText
        }
      : null,
    ...(item.case ? [] : definedFieldsDataSource),
    {
      classes: 'n-form-w-6  n-form-pad-10',
      component: NTextDelimiter,
      props: { i18n_label: 'ds.start_time' }
    },
    [
      {
        path: 'start_stream_timestamp',
        classes: 'n-form-w-4  n-form-pad-10',
        component: NDateInput,
        props: { datePlaceholder: '01.01.2020', timePlaceholder: '12:00', timeEnabled: true, secondsEnabled: true },
        encode: function (this: IFormContext, model: IFormModel, value: string) {
          if (value) {
            this.model.stream_settings.use_stream_timestamp = true;
            this.model.stream_settings.start_stream_timestamp = Math.round(new Date(value).getTime() / 1000);
          } else {
            this.model.stream_settings.use_stream_timestamp = false;
            this.model.stream_settings.start_stream_timestamp = 0;
          }
        },
        decode: function (this: IFormContext) {
          const timestamp = this.model.stream_settings.start_stream_timestamp;
          return timestamp ? new Date(timestamp * 1000).toISOString() : null;
        }
      },
      {
        classes: 'n-form-w-1 n-form-pad-10',
        component: NButton,
        props: {
          type: 'secondary',
          i18n_label: 'ds.now'
        },
        on: {
          click(this: any) {
            this.model.stream_settings.use_stream_timestamp = true;
            this.model.stream_settings.start_stream_timestamp = Math.round(new Date().getTime() / 1000);
          }
        }
      },
      {
        classes: 'n-form-w-1 n-form-pad-10',
        component: NButton,
        props: { type: 'secondary', i18n_label: 'ds.clear' },
        on: {
          click(this: any) {
            this.model.stream_settings.use_stream_timestamp = false;
            this.model.stream_settings.start_stream_timestamp = 0;
          }
        }
      }
    ]
  ];

  availableObjects.forEach((v) => {
    const layoutItem = findLayoutItemByPath(result, v);
    if (layoutItem && layoutItem.props) layoutItem.props.disabled = false;
  });

  return result.filter((v: IFormLayoutRow | null) => !!v) as any as IFormLayout;
}
