
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import NButton from '@/uikit/buttons/NButton.vue';
import { BatchLoaderItem } from '@/pages/cards/batch-loader/BatchLoaderItem';

@Options({
  components: { NButton },
  emits: ['open-card']
})
export default class BatchLoaderModalResult extends Vue {
  @Prop({ type: Object, required: true })
  item!: BatchLoaderItem;

  @Prop({ type: String, required: true })
  cardType!: string;

  get fileSrc() {
    if (this.item.file) {
      return this.item.file.src;
    }
    return require('@/pages/cards/batch-loader/no_image.png');
  }

  get fileName() {
    if (this.item.file) {
      return this.item.file.fileName;
    }
    if (this.item.item) {
      return this.item.item.name;
    }
    return '-';
  }

  get fileSize() {
    if (this.item.file) {
      return this.item.file.size;
    }
    if (this.item.item) {
      return this.item.item.license_plate_number;
    }
    return '-';
  }

  get comment() {
    if (this.item.item) {
      return this.item.item.comment;
    }
    return '';
  }

  getStatusBadge(item: BatchLoaderItem) {
    switch (item.statistics.errorCode) {
      case 'no_objects':
        return this.$t('cards.no_objects', 'f');
      case 'detection_error':
        return this.$t('cards.detection_error', 'f');
      default:
        if (item.statistics.errorCode) return this.$t('common.error', 'f');
    }
    if (item.statistics.statusCode === 'success') {
      return this.$t('cards.success', 'f');
    }
    return '';
  }

  getResultCardTitle(cardId: number) {
    return `${this.$t('common.card', 'f')}: ${cardId}`;
  }

  goToCard(cardId: number) {
    this.$emit('open-card', cardId);
  }
}
