
import { Options, Vue } from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import { ItemsActionNames } from '@/definitions/app/item.actions.name';
import { ListViewModel } from '@/definitions/view-models';
import { ModelAclResult } from '@/store/acl/types';
import { actionHandler } from '@/store/data/ActionHandler';
import { MultisidebarItemTypes } from '@/store/multisidebar/types';
import { RouterModule } from '@/store/router';
import { NLoadingCircle } from '@/uikit';
import NButton from '@/uikit/buttons/NButton.vue';
import InfiniteScroll from '@/components/common/InfiniteScroll.vue';
import SortDropdown from '@/components/common/SortDropdown.vue';
import DetectionDialog from '@/components/detection/DetectionDialog.vue';
import ObjectItem from '@/pages/cards/content/ObjectItem.vue';
import CaseClusterItem from '@/pages/cases/case-clusters/CaseClusterItem.vue';
import RemoteDailyEventItem from '@/pages/remote-daily-events/RemoteDailyEventItem.vue';
import { ViewerItem } from '@/pages/remote-daily-events/types';
import CameraShotAttach from '@/pages/users/uploader/CameraShotAttach.vue';
import { CarCard, CaseCluster, HumanCard } from '@/api';
import Confidence from '@/components/common/Confidence.vue';
import { PageTypes } from '@/store/application/page.definitions';

@Options({
  components: { Confidence, CameraShotAttach, CaseClusterItem, DetectionDialog, InfiniteScroll, NButton, NLoadingCircle, ObjectItem, RemoteDailyEventItem, SortDropdown }
})
export default class ItemsView extends Vue {
  @Prop({ type: Object, required: true })
  readonly module!: ListViewModel<any, any>;

  @Prop({ type: Array, required: false })
  readonly sortTypes?: any[] = [];

  @Prop({ type: Object, required: false })
  readonly card?: HumanCard | CarCard;

  @Prop({ type: Boolean, required: false })
  readonly uploadFeature: boolean = false;

  @Prop({ type: Boolean, required: false })
  readonly relatedCaseClusters: boolean = false;

  @Prop({ type: Boolean, required: false })
  readonly matchedCaseClusters: boolean = false;

  @Prop({ type: Boolean, required: false })
  readonly locations: boolean = false;

  @Prop({ type: Object, default: { add: true, view: true, delete: true, update: false } })
  readonly modelAcl: ModelAclResult = { add: true, view: true, delete: true, update: false };

  get bodyClasses() {
    const type = this.locations ? 'row' : 'tile';
    return {
      ['items-view__body_' + type]: true
    };
  }

  get items() {
    return this.module.items;
  }

  @Watch('module')
  async moduleHandler() {
    await this.load();
  }

  async mounted() {
    await this.load();
  }

  @Watch('module.filter.current', { deep: true })
  changeFilterHandler(v: any, p: any) {
    if (v?.page !== p.page) return;
    if (v?.limit !== p.limit) return;
    this.load();
  }

  async load() {
    const filter = this.module.filter.current;
    const hasEmptyClusterIn = filter.cluster_in && filter.cluster_in.length && filter.cluster_in[0] === 0;
    if (hasEmptyClusterIn && !this.relatedCaseClusters && !this.matchedCaseClusters && !this.locations) return;
    await this.module.get();
  }

  showImage(item: ViewerItem | undefined = undefined) {
    this.$photoViewer.show(item);
  }
  attachHandler(files: File[]) {
    const file = files[0];
    this.$emit('action', ItemsActionNames.Detect, file);
  }

  caseClusterActionHandler(type: string, item: CaseCluster) {
    if (type === 'click') {
      if (item.is_case_participant) {
        actionHandler.run(ItemsActionNames.ShowItem, {
          type: MultisidebarItemTypes.CaseClusters,
          rawItem: item
        });
      } else {
        this.$clusterWizardController.create(item);
      }
    }
  }

  getRelatedCaseClusterConfidence(item: CaseCluster): number {
    const id = this.card?.id;
    if (!id) return 0;
    const relatedCard = item.related_human_cards?.find((v) => v.id === id);
    return relatedCard?.confidence ?? 0;
  }

  getMatchedCaseClusterConfidence(item: CaseCluster): number {
    return item.matched_card?.confidence ?? 0;
  }

  getCaseClusterConfidence(item: CaseCluster): number {
    return this.relatedCaseClusters ? this.getRelatedCaseClusterConfidence(item) : this.getMatchedCaseClusterConfidence(item);
  }

  caseClickHandler(item: CaseCluster) {
    RouterModule.navigateToCase(item.case, { pageType: PageTypes.CaseClusters });
  }
}
