
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { CaseEvent } from '@/api/models/CaseEvent';
import { configModule } from '@/store/config';
import { EventDetails, EventTypes, ThumbnailPositions } from '@/uikit/thumbnail';
import NThumbnail from '@/uikit/thumbnail/NThumbnail.vue';
import Features from '@/components/common/Features.vue';
import Confidence from '@/components/common/Confidence.vue';
import { dataServiceRepository } from '@/api/common';

@Options({
  name: 'CaseClusterEvent',
  components: { Features, NThumbnail, Confidence }
})
export default class CaseClusterEvent extends Vue {
  @Prop({ type: Object, required: true })
  readonly item!: CaseEvent;

  get thumbnailActions() {
    const result = [
      { icon: 'eye-open', name: EventDetails.ShowFullScreen, position: ThumbnailPositions.BottomRight }
    ];
    return result;
  }
}
