
import { Options, Vue } from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import { HealthStatus } from '@/definitions/app/health-status';
import { ItemsActionName, ItemsActionNames } from '@/definitions/app/item.actions.name';
import { ItemViewModel } from '@/definitions/view-models';
import { Camera, CamerasService, Case, CaseStatusEnum, Detectors, VideoArchive } from '@/api';
import { viewModelRepository } from '@/api/common';
import { ItemAclResult, ModelAclResult } from '@/store/acl/types';
import { ObjectsSingleToMulti, ObjectsTypesMap } from '@/store/application/data.assets';
import { dataAssetsModule } from '@/store/application/data.assets.module';
import { configModule } from '@/store/config';
import { actionHandler } from '@/store/data/ActionHandler';
import { actionsModule } from '@/store/data/ActionsModule';
import { settingsItemModule } from '@/store/data/SettingsItemModule';
import { MultisidebarItem, MultisidebarItemTypes, MultisidebarSharedState } from '@/store/multisidebar/types';
import { Action } from '@/uikit';
import NButton from '@/uikit/buttons/NButton.vue';
import NButtonSelect from '@/uikit/buttons/NButtonSelect.vue';
import NCheckboxIcon from '@/uikit/checkbox/NCheckboxIcon.vue';
import NForm from '@/uikit/forms/NForm.vue';
import NTabs from '@/uikit/tabs/NTabs.vue';
import MultisidebarHeader from '@/components/multisidebar/MultisidebarHeader.vue';
import MultisidebarSaveActions from '@/components/multisidebar/MultisidebarSaveActions.vue';
import VMSCleanupForm from '@/components/settings/VMSCleanupForm.vue';
import { dataSourceGeneralExternalDetector } from '@/pages/data-sources/forms/data-source-general-external-detector';
import { getDataSourceFileGeneralLayout } from '@/pages/data-sources/forms/data-source-general-file';
import dataSourceAdvanced from './forms/data-source-advanced';
import getAnalyticsLayout from './forms/data-source-analytics';
import { getGeneralCameraLayout } from './forms/data-source-general-camera';
import { getDataSourceInfo } from './forms/data-source-info';
import dataSourceMap from './forms/data-source-map';
import dataSourceZones from './forms/data-source-zones';
import { getConnectionTypeByItem } from './forms/utils';
import CaseAclHelper from '@/pages/cases/CaseAclHelper';
import axios from 'axios';
import { dataServiceFactory } from '@/definitions/services/data.services';
import { Debounce } from '@/common/debounce-decorator';
import { RouterModule } from '@/store/router';

@Options({
  name: 'DataSourceSidebar',
  components: {
    MultisidebarHeader,
    MultisidebarSaveActions,
    NButton,
    NButtonSelect,
    NCheckboxIcon,
    NForm,
    NTabs,
    VMSCleanupForm
  }
})
export default class DataSourceSidebar extends Vue {
  @Prop({ type: Object, required: true })
  readonly sidebarItem!: MultisidebarItem<ItemViewModel<Camera | VideoArchive>>;

  @Prop({ type: Object, required: true })
  readonly sidebarSharedState!: MultisidebarSharedState;

  @Prop({ type: Object, required: true })
  readonly modelAcl!: ModelAclResult;

  displayDefaultSettings = false;
  caseAclHelper = new CaseAclHelper();
  excludeFromVideos = ['single_pass', 'stream_settings.disable_drops']

  get isCaseReadonly() {
    return (this.item as VideoArchive)?.case ? this.caseAclHelper.isCaseReadonly : false;
  }

  get settingsItemModule() {
    return settingsItemModule;
  }

  get pageType() {
    return this.sidebarItem.type;
  }

  get item(): Camera | VideoArchive {
    return this.sidebarItem.model.item!;
  }

  get disabled() {
    const canUpdateModel = this.modelAcl.update;
    const canUpdateCase = (this.item as VideoArchive)?.case ? !this.isCaseReadonly : true;
    return !(canUpdateModel && canUpdateCase);
  }

  get isExternalDetector() {
    return (this.item as Camera)?.external_detector;
  }

  get isVideoFile() {
    return this.pageType === MultisidebarItemTypes.Videos;
  }

  get isCaseVideoFile() {
    return this.pageType === MultisidebarItemTypes.CaseVideo;
  }

  get isCamera() {
    return this.pageType === MultisidebarItemTypes.Cameras;
  }

  get isInactive() {
    return this.isVideoFile ? false : !this.item?.active;
  }

  get tabItems() {
    let result = [
      {
        name: 'info',
        label: this.$t('ds.info', 'f')
      },
      {
        name: 'general',
        label: this.$t('ds.general', 'f')
      },
      {
        name: 'advanced',
        label: this.$t('ds.advanced', 'f')
      },
      {
        name: 'zones',
        label: this.$t('ds.zones', 'f')
      },
      ...this.detectorTabs
    ];

    if (this.isCamera) {
      const mapItem = {
        name: 'map',
        label: this.$t('ds.map', 'f')
      };
      result.push(mapItem);
    }

    if (this.isCamera && configModule.features.vms_enabled) {
      const vmsSettingsItem = {
        name: 'vms-settings',
        label: this.$t('settings.vms_cleanup_settings')
      };
      result.push(vmsSettingsItem);
    }

    if (this.isExternalDetector) {
      result = [
        {
          name: 'general',
          label: this.$t('ds.general', 'f')
        },
        {
          name: 'map',
          label: this.$t('ds.map', 'f')
        }
      ];
    }


    return result;
  }

  get detectorTabs() {
    return dataAssetsModule.availableObjects.map((object) => ({
      name: object,
      label: this.$t(`ds.${ObjectsSingleToMulti[object]}`, 'f'),
      disabled: !this.item?.stream_settings?.detectors?.[object as keyof Detectors]
    }));
  }

  get dataSourceAdvancedFiltered() {
    if (this.isVideoFile || this.isCaseVideoFile) {
      return dataSourceAdvanced.filter((item) => !this.excludeFromVideos.includes((item as any).path));
    }
    return dataSourceAdvanced;
  }

  get formLayout() {
    switch (this.sidebarSharedState.activeTab) {
      case 'info':
        return this.dataSourceInfo;
      case 'general':
        return this.isExternalDetector ? dataSourceGeneralExternalDetector : this.dataSourceGeneral;
      case 'advanced':
        return this.dataSourceAdvancedFiltered;
      case 'zones':
        return dataSourceZones;
      case 'face':
        return getAnalyticsLayout('face', this.isCamera);
      case 'body':
        return getAnalyticsLayout('body', this.isCamera);
      case 'car':
        return getAnalyticsLayout('car', this.isCamera);
      case 'map':
        return dataSourceMap;
      default:
        return [];
    }
  }

  get hasFormByTab() {
    const currentName = this.sidebarSharedState.activeTab;
    switch (currentName) {
      case 'face':
      case 'body':
      case 'car':
        return this.item?.stream_settings?.detectors?.[currentName as keyof Detectors];
    }
    return true;
  }

  get connectionType() {
    return this.isCamera ? getConnectionTypeByItem(this.item as Camera) : '';
  }

  get isExternalVmsEnabled() {
    return !!configModule.config.external_vms?.enabled;
  }

  get isPlayEnabled() {
    return !this.isExternalDetector;
  }

  get dataSourceInfo() {
    return getDataSourceInfo(this.isVideoFile || this.isCaseVideoFile, this.isPlayEnabled);
  }

  get dataSourceGeneral() {
    switch (this.pageType) {
      case MultisidebarItemTypes.Cameras:
        return getGeneralCameraLayout(this.connectionType, this.isExternalVmsEnabled);
      case MultisidebarItemTypes.Videos:
        return getDataSourceFileGeneralLayout(this.sidebarItem.model.item, dataAssetsModule.availableObjects, configModule.features.cameragroups_enabled);
      default:
        return [];
    }
  }

  get headerActions(): Action[] {
    let options = {};

    if (this.isCamera) {
      options = {
        hasActive: true,
        currentActive: this.item?.active,
        hasRestart: !this.isExternalDetector,
        hasChanges: this.sidebarItem.model.hasChanges,
        hasDelete: true,
        hasCameraReset: !this.isExternalDetector,
        hasCameraFilterEvent: true
      };
    } else {
      const videoArchive = this.item as VideoArchive;
      options = {
        isArchivable: true,
        hasChanges: this.sidebarItem.model.hasChanges,
        hasDelete: true,
        hasDeleteFile: !!videoArchive.file_size,
        hasVideoReset: true
      };
      this.caseAclHelper.updateActionsOptions(options, videoArchive);
    }

    const menuActions = actionsModule.getItemActions(this.modelAcl, options).map(actionsModule.computeActionByName);

    // TODO: simplify next ...
    const headerActions = actionsModule
      .getItemActions(this.modelAcl, {
        hasShowPlayer: configModule.config.vms?.enabled && !this.isExternalDetector && !this.isVideoFile
      })
      .map(actionsModule.computeActionByName)
      .map(actionsModule.addIconToAction)
      .map(actionsModule.makeActionVisible);

    return [...menuActions, ...headerActions];
  }

  async actionHandler(v: ItemsActionName) {
    switch (v) {
      case ItemsActionNames.FilterCameraEvent:
        RouterModule.navigateToEvents(ObjectsTypesMap.Faces, { cameras: this.item.id });
        break;
      case ItemsActionNames.Restart:
        this.item && CamerasService.camerasRestartCreate(this.item.id);
        break;
      case ItemsActionNames.ShowPlayer:
        if (this.item) {
          if (this.pageType === MultisidebarItemTypes.Cameras) {
            this.$videoPlayer.playCamera(this.item.id);
          } else {
            this.$videoPlayer.playVideo(this.item.id);
          }
        }
        break;
      default:
        actionHandler.run(v, this.sidebarItem);
    }
  }

  mounted() {
    this.loadCase();
  }

  @Watch('item')
  async changeItemHandler() {
    this.syncActiveTab();
    this.loadCase();
  }

  async loadCase() {
    this.syncActiveTab();
    const caseId = (this.item as VideoArchive)?.case;
    if (caseId) {
      await this.caseAclHelper.getCaseAclByCaseId(caseId);
    }
  }

  syncActiveTab() {
    const currentName = this.sidebarSharedState.activeTab;
    const currentObject = this.tabItems.find(v => v.name === currentName) as any;
    if (currentObject.disabled) {
      this.sidebarSharedState.activeTab = this.tabItems[0].name;
    }
  }

  @Watch('item.latitude')
  @Watch('item.longitude')
  @Debounce(500)
  async syncAddress(value: number) {
    const item = this.item as Camera;
    const hasCoordinates = item.latitude && item.longitude;
    if (!hasCoordinates || item.address) return;
    const result = await dataServiceFactory.getAxiosInstance().get<any>('/geocoding/reverse/', { params: { lat: item.latitude, lon: item.longitude } });
    if (result) (this.item as Camera).address = result.data?.[0]?.value;
  }

  validateForm() {
    const form: NForm | undefined = this.$refs.form as any as NForm;
    const result = form ? form.validate() : true;
    if (!result) form.displayErrors();
    return result;
  }

  created() {
    actionHandler.getIsItemFormValid = this.validateForm.bind(this);
  }

  toggleDisplayDefaultSettings() {
    this.displayDefaultSettings = !this.displayDefaultSettings;
  }
}
