import { computed, getCurrentInstance, provide, reactive, ref, Ref, unref, watch } from 'vue';
import { Camera, CameraGroup, CameraGroupsService, CancelablePromise, ExternalVms, PaginatedVmsPluginCamerasRequestList, VmsPluginCamerasRequest } from '@/api';
import { autoUpdateHelper } from '@/api/common/auto-update-helper';
import { request } from '@/api/core/request';
import { VmsPluginCameraStream } from '@/api/models/VmsPluginCamerasRequest';
import { languageModule } from '@/store/languages';
import notify from '@/uikit/notification/helpers/notification';
import { asDefined } from '@/common/utils';
import { createVmsSidebarCamerasTabGroupDialog } from './components/camera-group-dialog/utils';
import { VmsSidebarCamerasTabFilters } from './types';

export function useVmsSidebarCamerasTabViewModel(item: Ref<ExternalVms>) {
  const cameraGroups = computed(computeCameraGroups);
  const cameras = ref<VmsPluginCamerasRequest[]>([]);
  const filters = reactive<VmsSidebarCamerasTabFilters>({ added: '', groupContains: '', nameContains: '' });
  const selectCameraGroupId = createVmsSidebarCamerasTabGroupDialog(getCurrentInstance()?.appContext ?? null);
  const streams = ref<VmsPluginCameraStream[]>([]);

  watch(() => unref(item).id, fetchCameras);
  watch(filters, fetchCameras, { deep: true, immediate: true });

  function select(selected: VmsPluginCameraStream[]): void {
    streams.value = selected;
  }

  function selectAll(): void {
    streams.value = unref(cameras)
      .map(({ streams }) => streams)
      .flat();
  }

  async function createCameraFromSelectedStreams(): Promise<void> {
    const group = await selectCameraGroupId(await fetchCameraGroups());
    await Promise.all(unref(streams).map((stream) => createCameraFromStream(stream, group)));
    return useActionSuccessNotification(), await fetchCameras(), executeAutoUpdate();
  }

  async function createCameraFromSelectedStream(stream: VmsPluginCameraStream): Promise<void> {
    await createCameraFromStream(stream, await selectCameraGroupId(await fetchCameraGroups()));
    return useActionSuccessNotification(), await fetchCameras(), executeAutoUpdate();
  }

  function useActionSuccessNotification(): void {
    const content = languageModule.getTranslatedToken('common.action_success');
    return notify({ content, type: 'success' });
  }

  function createCameraFromStream(stream: VmsPluginCameraStream, group: number): CancelablePromise<Camera> {
    const camera = findCameraByStream(stream);
    return createCameraByVmsStream(camera.name, stream.url, unref(item).id, camera.id, group);
  }

  function findCameraByStream(stream: VmsPluginCameraStream): VmsPluginCamerasRequest {
    return asDefined(unref(cameras).find((camera) => camera.streams.some((target) => target === stream)));
  }

  function computeCameraGroups(): string[] {
    const groups = unref(cameras).map((camera) => camera.meta?.camera_group?.id);
    return [...new Set(groups.filter(Boolean))];
  }

  async function fetchCameras(): Promise<void> {
    try {
      cameras.value = await fetchVmsCameras(unref(item).id, unref(filters));
    } finally {
      streams.value = [];
    }
  }

  function executeAutoUpdate() {
    autoUpdateHelper.createHandler('/cameras/');
  }

  provide('select', select);
  provide('streams', streams);

  return { cameraGroups, cameras, createCameraFromSelectedStream, createCameraFromSelectedStreams, filters, selectAll, streams };
}

function fetchCameraGroups(): Promise<CameraGroup[]> {
  return CameraGroupsService.cameraGroupsList()
    .then(({ results }) => results ?? [])
    .catch(() => []);
}

function fetchVmsCameras(id: ExternalVms['id'], filters: VmsSidebarCamerasTabFilters): Promise<VmsPluginCamerasRequest[]> {
  return request<PaginatedVmsPluginCamerasRequestList>({
    method: 'GET',
    path: `/external-vms/${id}/cameras/`,
    query: { added: filters.added, group_contains: filters.groupContains, name_contains: filters.nameContains, limit: 1000 }
  })
    .then(({ results }) => results ?? [])
    .catch(() => []);
}

function createCameraByVmsStream(name: string, stream: string, vmsId: number, vmsCameraId: number, group: number): CancelablePromise<Camera> {
  return request({
    body: {
      active: true,
      external_vms_camera_id: vmsCameraId,
      external_vms: vmsId,
      group,
      name,
      url: stream
    },
    mediaType: 'application/json',
    method: 'POST',
    path: '/cameras/'
  });
}
