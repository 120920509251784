
import { Options, Vue } from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import { ItemsActionName, ItemsActionNames } from '@/definitions/app/item.actions.name';
import { CasePhoto } from '@/api';
import { viewModelRepository } from '@/api/common';
import { Status266Enum } from '@/api/models/Status266Enum';
import { dataAssetsModule } from '@/store/application/data.assets.module';
import { actionHandler } from '@/store/data/ActionHandler';
import { NButton, NForm, NModalWindow } from '@/uikit';
import NLoadingCircle from '@/uikit/loading/NLoadingCircle.vue';
import NThumbnail from '@/uikit/thumbnail/NThumbnail.vue';
import CardConfidence from '@/components/cards/CardConfidence.vue';
import Confidence from '@/components/common/Confidence.vue';
import ConfidenceDelimiter from '@/components/common/ConfidenceDelimiter.vue';
import Features from '@/components/common/Features.vue';
import InfiniteScroll from '@/components/common/InfiniteScroll.vue';
import SimpleReaction from '@/components/common/SimpleReaction.vue';
import SortDropdown from '@/components/common/SortDropdown.vue';
import Statistics from '@/components/common/Statistics.vue';
import CaseClusterEvent from '@/pages/cases/case-clusters/CaseClusterEvent.vue';
import CasePhotoForm from '@/pages/cases/photos/CasePhotoForm.vue';
import ClusterEventItem from '@/pages/clusters/ClusterEventItem.vue';
import MatchedCardInfo from '@/pages/events/MatchedCardInfo.vue';

@Options({
  name: 'ClustersSidebarContent',
  components: {
    CardConfidence,
    CaseClusterEvent,
    CasePhotoForm,
    ClusterEventItem,
    Confidence,
    ConfidenceDelimiter,
    Features,
    InfiniteScroll,
    MatchedCardInfo,
    NButton,
    NLoadingCircle,
    NModalWindow,
    NThumbnail,
    SimpleReaction,
    SortDropdown,
    Statistics
  }
})
export default class ClustersSidebarContent extends Vue {
  @Prop({ type: Object, required: true })
  readonly item!: CasePhoto;

  @Prop({ type: Boolean, required: false, default: false })
  readonly disabled!: boolean;

  created() {
    this.module.filter.current.limit = '20';
    this.setCaseEventFilter();
  }

  get form(): NForm {
    return this.$refs.form;
  }

  get sortTypes(): any[] {
    return dataAssetsModule.getSortTypes({ id: true }).map((v) => ({ ...v, label: this.$t(v.i18n_label) }));
  }

  get module() {
    return viewModelRepository.getCaseEventsListViewModel();
  }

  async actionHandler(v: ItemsActionName, payload?: any) {
    if (v === ItemsActionNames.Delete) {
      await this.module.delete(payload.id);
      await this.module.get();
    } else {
      await actionHandler.run(v, payload);
    }
  }

  @Watch('module.filter.current.ordering')
  changeOrderingHandler() {
    this.module.debouncedGet();
  }

  @Watch('item.status')
  statusChangeHandler(v: string, p: string) {
    if (v === Status266Enum.COMPLETED) this.module.debouncedGet();
  }

  showFullscreen() {
    actionHandler.run(ItemsActionNames.ShowFullScreen, this.item);
  }

  @Watch('item.id')
  setCaseEventFilter() {
    const id = Number(this.item?.id);
    this.module.filter.current.case_photo_in = [id];
    this.module.debouncedGet();
  }
}
