import { BatchUploadService } from '@/api';
import { viewModelRepository } from '@/api/common';
import { CardTypesMap } from '@/store/application/data.assets';

export default class BatchLoaderLogger {
  static async addUploadList(name: string) {
    const result = await BatchUploadService.batchUploadCreate({ name });
    return result;
  }
  static async addUploadListEntry(cardType: string, entryId: number, photo: string, card: number, code: number, error: string) {
    // TODO: BatchUploadEntryService.batchUploadEntryCreate();
    const module = viewModelRepository.getBatchUploadEntryListViewModel();
    const obj: any = { source_photo: photo, resp_code: code || 1, error_desc: error, upload_list: entryId };
    switch (cardType) {
      case CardTypesMap.Humans:
        obj.human_card_id = card;
        break;
      case CardTypesMap.Cars:
        obj.car_card_id = card;
        break;
    }
    const result = await module.create(obj);
    return result;
  }
}
