import { ItemViewModel } from '@/definitions/view-models';
import { CarCard, HumanCard } from '@/api';
import { dataAssetsModule } from '@/store/application/data.assets.module';
import { loadDataModule } from '@/store/data/LoadDataModule';
import { MultisidebarItem, MultisidebarItemAttributes } from '@/store/multisidebar/types';
import { MultisidebarItemTypes } from '@/store/multisidebar/types';

export async function cardAttributesLoader(item: MultisidebarItem<ItemViewModel<HumanCard | CarCard>>): Promise<MultisidebarItemAttributes> {
  const thumbnailSrc = async () => {
    const id = item.model.item?.id;
    if (id && id > 0) {
      const objectType = dataAssetsModule.getObjectTypeByCardType(item.type === MultisidebarItemTypes.CardsHumans ? 'humans' : 'cars');
      const objectsVM = loadDataModule.getObjectsLVMByCardID({ id, limit: 1, type: objectType, ordering: 'id', autoload: false });
      await objectsVM.get();
      return objectsVM.items[0]?.thumbnail ?? '';
    }
    else {
      return '';
    }
  };

  return {
    label: item.model.item?.name || '',
    thumbnailSrc
  };
}
