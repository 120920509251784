
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { ItemsActionNames } from '@/definitions/app/item.actions.name';
import { ItemAclResult, ModelAclResult } from '@/store/acl/types';
import { DefaultItemAclResult, DefaultModelAclResult } from '@/store/acl/types';
import { actionHandler } from '@/store/data/ActionHandler';
import { MultisidebarCommonItem } from '@/store/multisidebar/types';
import NButton from '@/uikit/buttons/NButton.vue';
import MultisidebarActions from '@/components/multisidebar/MultisidebarActions.vue';

@Options({
  name: 'MultisidebarSaveActions',
  components: { MultisidebarActions, NButton },
  emits: ['action']
})
export default class MultisidebarSaveActions extends Vue {
  @Prop({ type: Object, required: true })
  readonly sidebarItem!: MultisidebarCommonItem;

  @Prop({ type: Object, default: () => ({ ...DefaultModelAclResult }) })
  readonly modelAcl!: ModelAclResult;

  @Prop({ type: Object, default: () => ({ ...DefaultItemAclResult }) })
  readonly itemAcl!: ItemAclResult;
  
  @Prop({ type: String, default: 'msb-undefined.save-actions' })
  readonly dataQa!: string;

  @Prop({ type: Boolean, default: true })
  readonly canSave!: boolean;

  get saveAvailable() {
    return this.sidebarItem.model.isNew ? this.modelAcl.add : (this.itemAcl.update && this.modelAcl.update);
  }

  save() {
    actionHandler.run(ItemsActionNames.Save, this.sidebarItem);
  }

  discard() {
    actionHandler.run(ItemsActionNames.Reset, this.sidebarItem);
  }
}
