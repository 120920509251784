import { resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createBlock as _createBlock, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "alert-sidebar" }
const _hoisted_2 = { class: "alert-sidebar__content" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { key: 1 }
const _hoisted_5 = { class: "alert-sidebar__rules-header" }
const _hoisted_6 = { key: 2 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MultisidebarHeader = _resolveComponent("MultisidebarHeader")!
  const _component_NTabs = _resolveComponent("NTabs")!
  const _component_NForm = _resolveComponent("NForm")!
  const _component_NButton = _resolveComponent("NButton")!
  const _component_WebhooksFiltersEditorVisual = _resolveComponent("WebhooksFiltersEditorVisual")!
  const _component_FastCommentsForm = _resolveComponent("FastCommentsForm")!
  const _component_MultisidebarSaveActions = _resolveComponent("MultisidebarSaveActions")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_MultisidebarHeader, {
      label: _ctx.item.name,
      actions: _ctx.headerActions,
      changed: _ctx.sidebarItem.model.hasChanges,
      "model-acl": _ctx.modelAcl,
      onAction: _cache[0] || (_cache[0] = (v) => _ctx.handleAction(v, _ctx.sidebarItem))
    }, {
      beforeActions: _withCtx(() => []),
      _: 1
    }, 8, ["label", "actions", "changed", "model-acl"]),
    _createVNode(_component_NTabs, {
      items: _ctx.tabItems,
      modelValue: _ctx.sidebarSharedState.activeTab,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.sidebarSharedState.activeTab) = $event)),
      class: "multisidebar-content__tabs control-m"
    }, null, 8, ["items", "modelValue"]),
    _createElementVNode("div", _hoisted_2, [
      (_ctx.formSchema)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createVNode(_component_NForm, {
              ref: _ctx.setFormRef,
              model: _ctx.item,
              layout: _ctx.formSchema,
              disabled: _ctx.isFormDisabled
            }, null, 8, ["model", "layout", "disabled"])
          ]))
        : (_ctx.isAlertRulesSelected)
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
              _createElementVNode("div", _hoisted_5, [
                (_ctx.hasFiltersChanges)
                  ? (_openBlock(), _createBlock(_component_NButton, {
                      key: 0,
                      type: "secondary",
                      icon: "close",
                      onClick: _ctx.resetFilters,
                      disabled: _ctx.isFormDisabled
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t('common.reset', 'f')), 1)
                      ]),
                      _: 1
                    }, 8, ["onClick", "disabled"]))
                  : _createCommentVNode("", true),
                _createVNode(_component_NButton, {
                  type: "secondary",
                  onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.largeFilterActive = true)),
                  disabled: _ctx.isFormDisabled
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t('webhooks.enable_visual', 'f')), 1)
                  ]),
                  _: 1
                }, 8, ["disabled"])
              ]),
              _createVNode(_component_WebhooksFiltersEditorVisual, {
                largeFiltersActive: _ctx.largeFilterActive,
                hasChanges: _ctx.hasFiltersChanges,
                sections: _ctx.filterSections,
                multiple: false,
                mode: "alert",
                modelValue: _ctx.item.filters,
                "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.item.filters) = $event)),
                onClose: _cache[4] || (_cache[4] = ($event: any) => (_ctx.largeFilterActive = false)),
                onReset: _ctx.reset,
                disabled: _ctx.isFormDisabled
              }, null, 8, ["largeFiltersActive", "hasChanges", "sections", "modelValue", "onReset", "disabled"])
            ]))
          : (_ctx.isFastCommentsSelected)
            ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                _createVNode(_component_FastCommentsForm, {
                  class: "alert-wizard__body-content",
                  modelValue: _ctx.item.quick_replies,
                  "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.item.quick_replies) = $event)),
                  disabled: _ctx.isFormDisabled
                }, null, 8, ["modelValue", "disabled"])
              ]))
            : _createCommentVNode("", true)
    ]),
    _createVNode(_component_MultisidebarSaveActions, {
      "model-acl": _ctx.modelAcl,
      "sidebar-item": _ctx.sidebarItem,
      "data-qa": _ctx.dataQa + '.save-actions',
      "can-save": _ctx.canSave
    }, null, 8, ["model-acl", "sidebar-item", "data-qa", "can-save"])
  ]))
}