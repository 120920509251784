
import { Options, Vue } from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import { ItemsActionName, ItemsActionNames } from '@/definitions/app/item.actions.name';
import { TabItemsMap } from '@/definitions/app/tab.items';
import { ItemViewModel, ListViewModel } from '@/definitions/view-models';
import { BodyEvent, CarCard, CarEvent, FaceEvent, HumanCard } from '@/api';
import { viewModelRepository } from '@/api/common';
import { autoUpdateHelper } from '@/api/common/auto-update-helper';
import { RemoteMonitoringRecord } from '@/api/models/RemoteMonitoringRecord';
import { aclModule } from '@/store/acl';
import { AclModelNames, ModelAclResult } from '@/store/acl/types';
import { CardType, CardTypesMap, ObjectsType, ObjectsTypesMap } from '@/store/application/data.assets';
import { dataAssetsModule } from '@/store/application/data.assets.module';
import { PageNames } from '@/store/application/page.definitions';
import { cardAssetsModule } from '@/store/cards/CardAssetsModule';
import { cardPhotoUploaderModule } from '@/store/cards/CardPhotoUploaderModule';
import { actionHandler } from '@/store/data/ActionHandler';
import { actionsModule } from '@/store/data/ActionsModule';
import { loadDataModule } from '@/store/data/LoadDataModule';
import { dialogModule } from '@/store/dialogs/dialogModule';
import { canShowPage } from '@/store/menu';
import { MultisidebarItem, MultisidebarItemType, MultisidebarItemTypes, MultisidebarSharedState } from '@/store/multisidebar/types';
import { RouterModule } from '@/store/router';
import { Action, NForm, NTabs } from '@/uikit';
import { BboxConfig } from '@/uikit/bbox/types';
import CardsDuplicateDialog from '@/components/cards/CardsDuplicateDialog.vue';
import { EnrichedCard } from '@/components/cards/enrich-cards';
import FilterDateRange from '@/components/common/filter/controls/FilterDateRange.vue';
import DetectionDialog from '@/components/detection/DetectionDialog.vue';
import EventItem from '@/components/events/EventItem.vue';
import CardMonitoringStatus from '@/components/monitoring/CardMonitoringStatus.vue';
import { monitoringModule } from '@/components/monitoring/MonitoringModule';
import MultisidebarHeader from '@/components/multisidebar/MultisidebarHeader.vue';
import MultisidebarSaveActions from '@/components/multisidebar/MultisidebarSaveActions.vue';
import CardForm from '@/pages/cards/content/CardForm.vue';
import CaseCardsView from '@/pages/cards/content/CaseCardsView.vue';
import ItemsView from '@/pages/cards/content/ItemsView.vue';
import ExternalSearchRequestCreate from '@/pages/external-search/requests/ExternalSearchRequestCreate.vue';
import { flatten } from 'lodash';

@Options({
  name: 'CardsSidebar',
  components: {
    CardForm,
    CardMonitoringStatus,
    CardsDuplicateDialog,
    CaseCardsView,
    DetectionDialog,
    EventItem,
    ExternalSearchRequestCreate,
    FilterDateRange,
    ItemsView,
    MultisidebarHeader,
    MultisidebarSaveActions,
    NForm,
    NTabs
  }
})
export default class CardsSidebar extends Vue {
  @Prop({ type: Object, required: true })
  readonly sidebarItem!: MultisidebarItem<ItemViewModel<HumanCard | CarCard>>;

  @Prop({ type: Object, required: true })
  readonly sidebarSharedState!: MultisidebarSharedState;

  @Prop({ type: Object })
  readonly modelAcl!: ModelAclResult;

  @Prop({ type: String, required: true })
  readonly dataQa!: string;


  showDetectionDialog = false;
  file = null;
  applying = false;

  faceObjectsVM: ListViewModel<any, any> | null = null;
  bodyObjectsVM: ListViewModel<any, any> | null = null;
  carObjectsVM: ListViewModel<any, any> | null = null;
  faceClusterEventsVM: ListViewModel<any, any> | null = null;
  bodyClusterEventsVM: ListViewModel<any, any> | null = null;
  carClusterEventsVM: ListViewModel<any, any> | null = null;
  matchedCaseClustersVM: ListViewModel<any, any> | null = null;
  relatedCaseClustersVM: ListViewModel<any, any> | null = null;
  remoteDailyEventsVM: ListViewModel<any, any> | null = null;

  items: EnrichedCard[] = [];
  monitoringRecords: RemoteMonitoringRecord[] = [];

  watchlistEditPermission = false;

  get disabled() {
    const editPermission = this.modelAcl.update && this.watchlistEditPermission;
    return !(this.module.isNew ? this.modelAcl.add : editPermission);
  }

  get TabItemsMap() {
    return TabItemsMap;
  }

  get tabsItems(): any[] {
    const items = cardAssetsModule.getCardTabsItems(this.cardType);
    return this.currentItem && this.currentItem.id > 0 ? items : items.slice(0, 1);
  }

  get currentItem() {
    return this.module.item;
  }

  get module(): ItemViewModel<HumanCard | CarCard> {
    return this.sidebarItem.model;
  }

  get cardType() {
    return this.sidebarItem.type.split('_')[1];
  }

  get itemsSortTypes() {
    return this.sidebarSharedState.activeTab !== TabItemsMap.Locations
      ? dataAssetsModule.getSortTypes({ id: true }).map((v) => ({ ...v, label: this.$t(v.i18n_label) }))
      : undefined;
  }

  get isMetaTab(): boolean {
    return !!cardAssetsModule.getMetaTabs(this.cardType).find((v) => v.value === this.sidebarSharedState.activeTab);
  }

  get metaTabFormSchema() {
    return this.isMetaTab && this.sidebarSharedState.activeTab
      ? cardAssetsModule.getMetaFormItemsByTab(this.sidebarSharedState.activeTab, this.cardType)
      : null;
  }

  get monitoringModule() {
    return monitoringModule;
  }

  get isPuppeteerEnabled() {
    return this.cardType === CardTypesMap.Humans && this.monitoringModule.isPuppeteerEnabled;
  }

  get currentItemMonitoringRecord(): RemoteMonitoringRecord | null {
    return this.monitoringRecords[0];
  }

  get hasAnyMonitoringRecord() {
    return this.hasCardMonitoringRecord || this.hasWatchListMonitoringRecord;
  }

  get hasCardMonitoringRecord() {
    return monitoringModule.isCardOnMonitoring(this.currentItem!.id);
  }

  get hasWatchListMonitoringRecord() {
    return monitoringModule.isSomeWatchListOnMonitoring(this.currentItem!.watch_lists);
  }

  get headerActions(): Action[] {
    return actionsModule
      .getItemActions(this.modelAcl, {
        hasSearch: true,
        hasCardFilterEvent: true,
        hasActive: true,
        hasDelete: true,
        currentActive: this.currentItem?.active,
        hasChanges: this.module.hasChanges,
        hasInteractions: this.cardType === CardTypesMap.Humans && canShowPage(PageNames.Interactions),
        hasAddToMonitoring: !this.hasAnyMonitoringRecord,
        hasRemoveFromMonitoring: this.hasCardMonitoringRecord,
        hasPuppeteerExternalSearch: true
      })
      .map(actionsModule.computeActionByName);
  }

  get objectType() {
    const objectName = this.sidebarSharedState.activeTab?.split('_')[0] as string;
    return { [objectName]: dataAssetsModule.detectFeatures[objectName] };
  }

  get fromEventModel(): ItemViewModel<FaceEvent | BodyEvent | CarEvent> | undefined {
    const additionalDataFrom: MultisidebarItem<ItemViewModel<FaceEvent | BodyEvent | CarEvent>> = this.module.additionalData?.from;
    const isEvent = [MultisidebarItemTypes.EventsFaces, MultisidebarItemTypes.EventsBodies, MultisidebarItemTypes.EventsCars].includes(
      additionalDataFrom?.type as any
    );
    return isEvent ? additionalDataFrom.model : undefined;
  }

  get fromEventObjectType(): string | undefined {
    const additionalDataFrom: MultisidebarItem<ItemViewModel<FaceEvent | BodyEvent | CarEvent>> = this.module.additionalData?.from;
    return additionalDataFrom?.type ? additionalDataFrom.type.split('_')[1] : undefined;
  }

  @Watch('currentItem')
  loadCurrentItemModules() {
    const { id, face_cluster, body_cluster, car_cluster } = (this.currentItem || {}) as any;
    this.loadWatchlistPermission();
    this.syncActiveTabById(id);
    this.loadMonitoringRecords();
    if (!id || id <= 0) return;
    switch (this.cardType) {
      case CardTypesMap.Humans:
        this.faceObjectsVM = loadDataModule.getObjectsLVMByCardID({ id, type: 'faces', autoload: false });
        this.faceClusterEventsVM = loadDataModule.getClusterEventsLVMByClusterID({
          id: face_cluster,
          type: 'faces',
          autoload: false
        });
        this.bodyObjectsVM = loadDataModule.getObjectsLVMByCardID({ id, type: 'bodies', autoload: false });
        this.bodyClusterEventsVM = loadDataModule.getClusterEventsLVMByClusterID({
          id: body_cluster,
          type: 'bodies',
          autoload: false
        });
        this.matchedCaseClustersVM = viewModelRepository.getCaseClustersListViewModel();
        this.matchedCaseClustersVM.filter.force.matched_human_card = this.currentItem?.id;
        this.matchedCaseClustersVM.filter.current.limit = 1000;

        this.relatedCaseClustersVM = viewModelRepository.getCaseClustersListViewModel();
        this.relatedCaseClustersVM.filter.force.related_human_card = this.currentItem?.id;
        this.relatedCaseClustersVM.filter.current.limit = 1000;

        this.remoteDailyEventsVM = viewModelRepository.getPuppeteerDailyEventsListViewModel();
        this.remoteDailyEventsVM.filter.current.card = this.currentItem?.id;
        break;

      case CardTypesMap.Cars:
        this.carObjectsVM = loadDataModule.getObjectsLVMByCardID({ id, type: 'cars', autoload: false });
        this.carClusterEventsVM = loadDataModule.getClusterEventsLVMByClusterID({
          id: car_cluster,
          type: 'cars',
          autoload: false
        });
        break;
    }
  }

  @Watch('hasAnyMonitoringRecord')
  async loadMonitoringRecords() {
    if (!this.isPuppeteerEnabled || this.currentItem!.id < 0) return;
    this.monitoringRecords = [];
    const cardRecords = await monitoringModule.getItemsByCard(this.currentItem!.id);
    const watchListRecords = await monitoringModule.getItemsByWatchLists(this.currentItem!.watch_lists);
    this.monitoringRecords = this.monitoringRecords.concat(cardRecords, flatten(watchListRecords)).filter((v) => !!v);
  }

  async loadWatchlistPermission() {
    this.watchlistEditPermission = aclModule.getWatchLisEditPermission(this.currentItem?.watch_lists);
  }

  @Watch('currentItem.id')
  syncActiveTabById(v: number) {
    if (v < -1000) this.sidebarSharedState.activeTab = TabItemsMap.Info;
  }

  get itemsModule() {
    switch (this.sidebarSharedState.activeTab) {
      case TabItemsMap.FaceObjects:
        return this.faceObjectsVM;
      case TabItemsMap.BodyObjects:
        return this.bodyObjectsVM;
      case TabItemsMap.CarObjects:
        return this.carObjectsVM;
      case TabItemsMap.FaceClusterEvents:
        return this.faceClusterEventsVM;
      case TabItemsMap.BodyClusterEvents:
        return this.bodyClusterEventsVM;
      case TabItemsMap.CarClusterEvents:
        return this.carClusterEventsVM;
      case TabItemsMap.MatchedCaseClusters:
        return this.matchedCaseClustersVM;
      case TabItemsMap.RelatedCaseClusters:
        return this.relatedCaseClustersVM;
      case TabItemsMap.Locations:
        return this.remoteDailyEventsVM;
    }
    return null;
  }

  get objectsTypeByTab(): ObjectsType | undefined {
    switch (this.sidebarSharedState.activeTab) {
      case TabItemsMap.FaceObjects:
        return ObjectsTypesMap.Faces;
      case TabItemsMap.BodyObjects:
        return ObjectsTypesMap.Bodies;
      case TabItemsMap.CarObjects:
        return ObjectsTypesMap.Cars;
    }
    return undefined;
  }

  get tabModelAcl() {
    switch (this.sidebarSharedState.activeTab) {
      case TabItemsMap.FaceObjects:
        return aclModule.getModelAclByName(AclModelNames.FaceObject);
      case TabItemsMap.BodyObjects:
        return aclModule.getModelAclByName(AclModelNames.BodyObject);
      case TabItemsMap.CarObjects:
        return aclModule.getModelAclByName(AclModelNames.CarObject);
      case TabItemsMap.FaceClusterEvents:
        return aclModule.getModelAclByName(AclModelNames.FaceCluster);
      case TabItemsMap.BodyClusterEvents:
        return aclModule.getModelAclByName(AclModelNames.BodyCluster);
      case TabItemsMap.CarClusterEvents:
        return aclModule.getModelAclByName(AclModelNames.CarCluster);
    }
    return undefined;
  }

  get uploadFeature() {
    switch (this.sidebarSharedState.activeTab) {
      case TabItemsMap.FaceObjects:
      case TabItemsMap.BodyObjects:
      case TabItemsMap.CarObjects:
        return true;
    }
    return false;
  }

  get itemsModuleMultiSidebarType(): MultisidebarItemType | undefined {
    switch (this.sidebarSharedState.activeTab) {
      case TabItemsMap.FaceObjects:
        return MultisidebarItemTypes.FaceObjects;
      case TabItemsMap.BodyObjects:
        return MultisidebarItemTypes.BodyObjects;
      case TabItemsMap.CarObjects:
        return MultisidebarItemTypes.CarObjects;
    }

    return undefined;
  }

  sendUpdateEvent() {
    autoUpdateHelper.updateHandler(this.sidebarItem.model.name, this.sidebarItem.model.item);
  }

  async applyDetection(v: BboxConfig[]) {
    this.applying = true;
    try {
      const tab = this.objectsTypeByTab;
      const result = await cardPhotoUploaderModule.uploadByDetection(this.file!, v[0], tab!, this.currentItem?.id, this.cardType as CardType);
      if (result) {
        this.showDetectionDialog = false;
        await this.actionHandler(ItemsActionNames.Upload);
        this.sendUpdateEvent();
      }
    } catch (e) {
      console.error(e);
    }
    this.applying = false;
  }

  setFile(e: any) {
    const file = e.target.files && e.target.files[0];
    this.$refs.fileInput.value = null;
    this.actionHandler('detect', file);
  }

  async itemActionHandler(v: ItemsActionName, payload?: any) {
    switch (v) {
      case ItemsActionNames.Remove:
        if (
          !(await dialogModule.createDialog(ItemsActionNames.Delete, {
            type: this.itemsModuleMultiSidebarType!,
            id: payload.id
          } as any))
        ) {
          return;
        }
        await this.itemsModule?.delete(payload.id);
        await this.itemsModule?.get();
        this.sendUpdateEvent();
        break;
      default:
        this.actionHandler(v, payload);
    }
  }

  async actionHandler(v: ItemsActionName, payload?: any) {
    switch (v) {
      case ItemsActionNames.Detect:
        this.file = payload;
        this.showDetectionDialog = true;
        break;
      case ItemsActionNames.UploadFile:
        this.$refs.fileInput?.click();
        break;
      case ItemsActionNames.ShowInteractions:
        RouterModule.navigateToInteractions({ card: payload.model.item.id! });
        break;
      case ItemsActionNames.Upload:
        await this.itemsModule?.get();
        autoUpdateHelper.createHandler(this.sidebarItem.model.name);
        break;
      default:
        await actionHandler.run(v, payload, true);
    }
  }

  validateForm() {
    const form: NForm | undefined = this.$refs.cardFormInstance?.form as any as NForm;
    const result = form ? form.validate() : true;
    if (!result) form.displayErrors();
    return result;
  }

  created() {
    this.loadCurrentItemModules();
    actionHandler.getIsItemFormValid = this.validateForm.bind(this);
  }
}
