
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { Group, User } from '@/api';
import { dataModule } from '@/store/data';
import { NSelect, NRadio } from '@/uikit';
import NButtonModel from '@/uikit/buttons/NButtonModel.vue';
import NTable from '@/uikit/table/NTable.vue';
import { ITableCellChangePayload } from '@/uikit/table/NTableCell.vue';
import NTableColumnCell from '@/uikit/table/NTableColumnCell.vue';
import NText from '@/uikit/text/NText.vue';

@Options({
  components: { NRadio, NSelect, NTable }
})
export default class UserRoles extends Vue {
  @Prop({ type: Object, required: true })
  readonly item!: User;

  get embeddedUserGroups() {
    return this.groups.filter((v) => this.item.groups?.includes(v.id) || v.id === this.item.primary_group);
  }

  get groupAddItems() {
    return this.groups.filter((v) => !this.embeddedUserGroups.find((appliedGroup) => appliedGroup.id === v.id)).map((v) => ({ label: v.name, value: v.id }));
  }

  get groups() {
    return dataModule.groupsModule.items;
  }

  get columns() {
    return [
      {
        width: '325px',
        header: {
          classes: ['bottom-border'],
          component: NTableColumnCell,
          props: { i18n_label: 'common.roles' }
        },
        content: {
          path: 'name',
          component: NText
        }
      },
      {
        width: '120px',
        header: {
          classes: ['bottom-border', 'align-center'],
          component: NTableColumnCell,
          props: { i18n_label: 'common.primary_role' }
        },
        content: {
          classes: ['align-center'],
          path: 'primary_group',
          component: NRadio,
          decode: () => this.item.primary_group,
          props: (item: Group) => ({ selectValue: item.id })
        }
      },
      {
        width: '40px',
        header: {
          classes: ['bottom-border', 'align-center'],
          component: NTableColumnCell
        },
        content: {
          path: 'group_remove',
          classes: ['align-center'],
          component: NButtonModel,
          props: () => ({ type: 'text', icon: 'trash', disabled: !this.item.groups || this.item.groups.length === 0 })
        }
      }
    ];
  }

  protected addGroup(newExtraGroupId?: number) {
    newExtraGroupId !== undefined && this.item.groups?.push(newExtraGroupId);
  }

  protected removeGroup(groupId: number) {
    if (this.item.primary_group === groupId) {
      let newPrimaryGroupId = this.item.groups?.[0];
      if (newPrimaryGroupId !== undefined) {
        this.item.primary_group = newPrimaryGroupId;
        this.item.groups?.splice(0, 1);
      } else {
        throw new Error('Can\'t delete last user group. User should has at least one group!');
      }
    } else if (this.item.groups) {
      let groupIndex = this.item.groups.findIndex((v) => v === groupId);
      if (groupIndex !== -1) this.item.groups.splice(groupIndex, 1);
    }
  }

  protected updatePrimaryGroup(extraGroupId: number) {
    let prevExtraGroupId = this.item.primary_group;
    if (this.item.groups) {
      let extraGroupIndex = this.item.groups.findIndex((gId) => extraGroupId === gId);
      if (extraGroupIndex !== -1) {
        this.item.groups.splice(extraGroupIndex, 1, prevExtraGroupId);
      }
    }
    this.item.primary_group = extraGroupId;
  }

  protected handleTableAction(payload: ITableCellChangePayload) {
    if (payload.type === 'change') {
      switch (payload.path) {
        case 'primary_group':
          this.updatePrimaryGroup(payload.value);
          break;
        case 'group_remove':
          this.removeGroup(payload.row.id);
          break;
        default:
          console.warn('[UserRoles] Unknown table action path:' + payload.path);
      }
    }
  }
}
