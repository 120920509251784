import { NInputNumber } from '@/uikit';
import NCheckbox from '@/uikit/checkbox/NCheckbox.vue';
import { IFormContext, IFormLayout, IFormModel } from '@/uikit/forms/NForm.vue';
import { getNumberValidator, getUrlValidator } from '@/uikit/forms/validators';
import NInput from '@/uikit/input/NInput.vue';
import NTextDelimiter from '@/uikit/text/NTextDelimiter.vue';
import CameraTransform from '@/components/data-source/CameraTransform.vue';

const layout: IFormLayout = [
  /*{
    classes: 'label-m n-form-w-6 n-form-pad-10',
    i18n_label: 'common.address',
    i18n_tooltip: 'common.address',
    component: NInput
  },*/
  [
    {
      path: 'latitude',
      classes: 'label-m n-form-w-6 n-form-pad-10 pull-end',
      i18n_label: 'common.latitude',
      i18n_tooltip: 'common.latitude',
      component: NInputNumber,
      props: { units: '°', textAlign: 'right', min: -180, max: 180, readonly: true }
    },
    {
      path: 'longitude',
      classes: 'label-m n-form-w-6 n-form-pad-10 pull-end',
      i18n_tooltip: 'common.longitude',
      i18n_label: 'common.longitude',
      component: NInputNumber,
      props: { units: '°', textAlign: 'right', min: -180, max: 180, readonly: true }
    },
    {
      path: 'elevation',
      classes: 'label-m n-form-w-6 n-form-pad-10 pull-end',
      i18n_label: 'common.elevation',
      i18n_tooltip: 'common.elevation',
      component: NInputNumber,
      props: { readonly: true }
    }
  ]
];

export default layout;
