
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { Case } from '@/api';
import { RouterModule } from '@/store/router';
import NButton from '@/uikit/buttons/NButton.vue';

@Options({
  name: 'ExtendedCaseDescription',
  components: { NButton }
})
export default class ExtendedCaseDescription extends Vue {
  @Prop({ type: Object, required: true })
  readonly item!: Case;

  get classes() {
    return {
      [`case-description_row`]: true
    };
  }

  clickHandler() {
    RouterModule.navigateToCase(this.item.id, { subPage: 'participants' });
  }
}
