import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "case-cluster-connections" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Confidence = _resolveComponent("Confidence")!
  const _component_CaseClusterItem = _resolveComponent("CaseClusterItem")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.computedItems, (item) => {
      return (_openBlock(), _createBlock(_component_CaseClusterItem, {
        key: item.id,
        item: item,
        objects: "faces",
        onAction: (type) => _ctx.actionHandler(item, type),
        onCardClick: () => _ctx.actionHandler(item, 'ShowCard'),
        onCaseClick: () => _ctx.actionHandler(item, 'ShowCase'),
        "show-name": true,
        "show-role": true,
        "show-card": true,
        "show-case": true
      }, {
        thumbnailConfidence: _withCtx(() => [
          _createVNode(_component_Confidence, {
            value: item.confidence,
            type: "number",
            objects: 'faces',
            class: "cluster-wizard-item__looks-like"
          }, null, 8, ["value"])
        ]),
        _: 2
      }, 1032, ["item", "onAction", "onCardClick", "onCaseClick"]))
    }), 128))
  ]))
}