
import { Options, Vue } from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import { ItemsActionName, ItemsActionNames } from '@/definitions/app/item.actions.name';
import { TabItemsMap } from '@/definitions/app/tab.items';
import { ItemViewModel } from '@/definitions/view-models';
import { CarCard, CardsService, Case, CaseStatusEnum, HumanCard } from '@/api';
import { dataServiceRepository, viewModelRepository } from '@/api/common';
import { CaseCluster } from '@/api/models/CaseCluster';
import { aclModule } from '@/store/acl';
import { ItemAclResult, ModelAclResult } from '@/store/acl/types';
import { actionHandler } from '@/store/data/ActionHandler';
import { actionsModule } from '@/store/data/ActionsModule';
import { languageModule } from '@/store/languages';
import { MultisidebarItem, MultisidebarItemTypes, MultisidebarSharedState } from '@/store/multisidebar/types';
import NTabs from '@/uikit/tabs/NTabs.vue';
import NThumbnail from '@/uikit/thumbnail/NThumbnail.vue';
import CardGroupRow from '@/components/cards/CardGroupRow.vue';
import CardItemRow from '@/components/cards/CardItemRow.vue';
import CardItemSmall from '@/components/cards/CardItemSmall.vue';
import CardItemTile from '@/components/cards/CardItemTile.vue';
import MultisidebarHeader from '@/components/multisidebar/MultisidebarHeader.vue';
import MultisidebarSaveActions from '@/components/multisidebar/MultisidebarSaveActions.vue';
import CaseClusterConnections from '@/pages/cases/case-clusters/CaseClusterConnections.vue';
import CaseClusterEvents from '@/pages/cases/case-clusters/CaseClusterEvents.vue';
import CaseClusterItem from '@/pages/cases/case-clusters/CaseClusterItem.vue';
import CaseClusterForm from '@/pages/cases/case-clusters/form/CaseClusterForm.vue';
import ClusterWizardItem from '../case-cluster-wizard/ClusterWizardItem.vue';
import { EventDetails, ThumbnailPositions } from '@/uikit/thumbnail';
import { generateMultisidebarId } from '@/store/multisidebar/helpers';
import { multisidebarModule } from '@/store/multisidebar';
import { loadAndSyncPermissions, PermissionsModelNamesMap } from '@/components/permissions/permission-helpers';

@Options({
  name: 'CaseClustersSidebar',
  components: {
    CardGroupRow,
    CardItemRow,
    CardItemSmall,
    CardItemTile,
    CaseClusterConnections,
    CaseClusterEvents,
    CaseClusterForm,
    CaseClusterItem,
    MultisidebarHeader,
    MultisidebarSaveActions,
    NTabs,
    NThumbnail,
    ClusterWizardItem
  }
})
export default class CaseClustersSidebar extends Vue {
  @Prop({ type: Object, required: true })
  readonly sidebarItem!: MultisidebarItem<ItemViewModel<CaseCluster>>;

  @Prop({ type: Object, required: true })
  readonly sidebarSharedState!: MultisidebarSharedState;

  @Prop({ type: Object, required: true })
  readonly modelAcl!: ModelAclResult;
  activeTab = TabItemsMap.Info;
  case: Case | null = null;
  relatedCards: HumanCard[] = [];

  readonly relatedHumanCardsModule = viewModelRepository.getCardsHumansListViewModel();

  get isCaseArchived() {
    return this.case?.status !== CaseStatusEnum.Open;
  }

  get canChange() {
    return aclModule.getAccess('ffsecurity.change_case');
  }

  get tabsItems(): any[] {
    const tabs = [TabItemsMap.Info, TabItemsMap.ClusterEvents, TabItemsMap.Connections];
    const result = tabs.map((v: string) => ({
      value: v,
      name: v,
      label: languageModule.getTranslatedToken(`cards.${v}`, 'f')
    }));
    return result;
  }

  get moduleItem() {
    return this.sidebarItem.model;
  }

  get currentItem() {
    return this.sidebarItem.model.item;
  }

  get isParticipant() {
    return this.currentItem?.is_case_participant;
  }

  get headerActions() {
    return actionsModule
      .getItemActions(this.modelAcl, {
        hasChanges: this.moduleItem.hasChanges,
        hasSearch: true,
        hasResetParticipant: this.currentItemAcl.update
      })
      .map(actionsModule.computeActionByName);
  }

  get maxAcl(): ModelAclResult {
    return aclModule.getModelAclByName('case');
  }

  get currentItemAcl(): ItemAclResult {
    const result = aclModule.getCaseItemAclByUserGroupMap((this.case?.permissions as any) || {}, this.maxAcl);
    return result;
  }

  get cardSidebarType() {
    return MultisidebarItemTypes.CardsHumans;
  }

  get sidebarModule() {
    return multisidebarModule;
  }

  cardActionHandler(id: string | number, action: ItemsActionName, payload?: any) {
    actionHandler.run(action, payload);
  }

  getIsCardOpened(card: HumanCard | CarCard) {
    return this.sidebarModule.currentItem?.id === generateMultisidebarId(this.cardSidebarType, card.id);
  }

  actionHandler(actionName: ItemsActionName, payload?: any) {
    actionHandler.run(actionName, payload);
  }

  validateForm() {
    return this.$refs.form.validate();
  }

  async mounted() {
    actionHandler.getIsItemFormValid = this.validateForm.bind(this);
    await this.loadCase();
    await this.loadCards();
  }

  @Watch('item')
  async loadCards() {
    this.relatedHumanCardsModule.filter.current.id_in = this.currentItem?.related_human_cards?.map((v) => v.id) || [];
    await this.relatedHumanCardsModule.get();
    this.relatedCards = this.relatedHumanCardsModule.items.filter((el) => {
      el.looks_like_confidence = this.currentItem?.related_human_cards?.find((item) => item.id === el.id)?.confidence ?? 0;
      return this.currentItem?.related_human_cards?.map((item) => item.id).includes(el.id);
    });
  }

  @Watch('item')
  async loadCase() {
    const caseId = this.currentItem?.case;
    const caseModel = viewModelRepository.getCasesItemViewModel();
    if (caseId) {
      await caseModel.get(caseId);
      await loadAndSyncPermissions(caseModel, PermissionsModelNamesMap.Cases);
      this.case = caseModel.item!;
    } else {
      this.case = null;
    }
  }

  get caseClusterActions() {
    const result = [{ icon: 'eye-open', name: EventDetails.ShowFullScreen, position: ThumbnailPositions.BottomRight }];
    return result;
  }
}
