
import { Options, Vue } from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import { ExternalVms, VmsPluginInfoRequest } from '@/api';
import { viewModelRepository } from '@/api/common';
import { ExternalVmsEvent } from '@/api/models/ExternalVmsEvent';
import { dataModalController } from '@/store/application/data.modal.module';
import { NDateTimeLabel } from '@/uikit';
import NButton from '@/uikit/buttons/NButton.vue';
import { copyTextToClipboard } from '@/uikit/helpers';
import { NTable, NTableColumn } from '@/uikit/table-v2';
import NFilterDateRange from '@/components/common/filter/controls/FilterDateRange.vue';
import InfiniteScroll from '@/components/common/InfiniteScroll.vue';
import { ExternalVmsConfig } from '@/pages/external-vms/types';

@Options({
  name: 'ExternalVmsInfo',
  components: { InfiniteScroll, NFilterDateRange, NTable },
  emits: []
})
export default class ExternalVmsEvents extends Vue {
  @Prop({ type: Object, required: true })
  readonly item!: ExternalVms;

  @Prop({ type: Object, required: true })
  readonly info!: VmsPluginInfoRequest;

  @Prop({ type: Object, required: false })
  readonly config: ExternalVmsConfig | null = null;

  module = viewModelRepository.getExternalVmsEventsListViewModel();

  get columns(): NTableColumn<ExternalVmsEvent>[] {
    return [this.createUrlTableColumn(), this.createInfoTableColumn(), this.createdTableColumn()];
  }

  @Watch('module.filter.current', { deep: true })
  changeFilter(v: any, p: any) {
    if (v?.page !== p?.page || v?.limit !== p?.limit) return;
    this.module.get();
  }

  @Watch('item.id', { immediate: true })
  updateFilter() {
    this.module.filter.current.external_vms = [this.item.id];
  }

  createUrlTableColumn(): NTableColumn<ExternalVmsEvent> {
    return {
      body: {
        content: ({ model }) => (
          <div>
            <div class="vms-events__table__id label-mono-s">
              #{model.id}&nbsp;
              <NButton
                {...{
                  icon: 'copy',
                  iconSize: 14,
                  onClick: (e: any) => {
                    e.stopPropagation();
                    this.copyStreamUrlToClipboard(model.id);
                  },
                  type: 'link'
                }}
              />
            </div>
          </div>
        )
      },
      width: 'max-content'
    };
  }

  createInfoTableColumn(): NTableColumn<ExternalVmsEvent> {
    return {
      body: ({ model }) => (
        <div class="label-m">
          {model.name}
          <br />
          {model.type}
          <div>{model.camera ? this.$t('common.camera_id') + ':' + model.camera : ''}</div>
        </div>
      ),
      width: '1fr'
    };
  }

  createdTableColumn(): NTableColumn<ExternalVmsEvent> {
    return {
      body: {
        content: ({ model }) => (
          <div class="vms-events__table__date">
            <NDateTimeLabel multiline modelValue={model.created_date}></NDateTimeLabel>
          </div>
        )
      }
    };
  }
  copyStreamUrlToClipboard(url: string | number): void {
    copyTextToClipboard(String(url));
  }

  rowClickHandler(item: ExternalVmsEvent) {
    dataModalController.show(item);
  }
}
