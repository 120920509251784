import { computed, getCurrentInstance, onBeforeUnmount, onMounted, ref, Ref, unref, watch } from 'vue';
import { Camera, OnvifCamerasService } from '@/api';
import { asDefined, isStringWithValue } from '@/common/utils';
import { CameraLookupAuthenticator, CameraLookupModel, CameraLookupSource, createCameraLookupDialog } from '../../../CameraLookup';

const authenticator: CameraLookupAuthenticator = async (id, username, password) =>
  (await OnvifCamerasService.onvifCamerasAuthCreate(id, { username, password })) as CameraLookupModel;
const source: CameraLookupSource = async () => ((await OnvifCamerasService.onvifCamerasList()).results ?? []) as CameraLookupModel[];

export function useCameraLookupSubForm(url: Ref<string>, target: Ref<Camera>, onChange: (url: string) => void) {
  const camera = ref<CameraLookupModel | null>(null);
  const dialog = createCameraLookupDialog(getCurrentInstance()?.appContext ?? null);
  const isSelectDisabled = computed(() => !unref(streams).length || unref(running));
  const running = ref<boolean>(false);
  const streams = computed(computeCameraStreams);
  const token = ref<string>('');

  onBeforeUnmount(watch(token, lookup));
  onMounted(async () => (camera.value = await fetchOnvifMetaData()));

  async function lookup(token: string): Promise<void> {
    try {
      running.value = true;
      isStringWithValue(token) && onChange(await fetchStreamUrl(token));
    } finally {
      running.value = false;
    }
  }

  function computeCameraStreams() {
    return (unref(camera)?.meta.media.Profiles ?? []).map(({ Name, token }) => ({ label: Name, value: token }));
  }

  async function fetchOnvifMetaData(): Promise<CameraLookupModel | null> {
    const id = unref(target).onvif_camera as unknown as number;
    return (await OnvifCamerasService.onvifCamerasRetrieve(id).catch(() => null)) as CameraLookupModel | null;
  }

  async function fetchStreamUrl(token: string): Promise<string> {
    await OnvifCamerasService.onvifCamerasStartStreamingCreate(asDefined(unref(camera)).id, { profile_token: token });
    return await OnvifCamerasService.onvifCamerasStreamUriRetrieve(asDefined(unref(camera)).id, token)
      .then(({ Uri }) => Uri)
      .catch(() => '');
  }

  return { isSelectDisabled, lookup, running, streams, token };
}
