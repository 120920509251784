
import { defineComponent } from 'vue';
import { arrayOf, object } from 'vue-types';
import NButton from '@/uikit/buttons/NButton.vue';
import { NTable, NTableColumn } from '@/uikit/table-v2';
import type { CameraLookupModel } from '../types';

export default defineComponent({
  name: 'CameraLookupTable',
  components: { NTable },
  props: {
    cameras: defineCamerasProp()
  },
  emits: ['select'],
  computed: {
    columns(): NTableColumn<CameraLookupModel>[] {
      return [
        {
          body: ({ model }) => model.meta.device.Hostname?.Name ?? '-',
          head: this.$t('ds.brand', 'f'),
          width: 100
        },
        {
          body: '-',
          head: this.$t('ds.model', 'f'),
          width: 100
        },
        {
          body: ({ model }) => model.meta.host,
          head: this.$t('ds.ip_address'),
          width: 217
        },
        {
          body: ({ model }) => model.meta.port,
          head: this.$t('ds.port', 'f'),
          width: 100
        },
        {
          body: '-',
          head: this.$t('ds.mac_address'),
          width: 'minmax(313px, 1fr)'
        },
        {
          body: ({ model }) => (model.need_auth ? <NButton {...{ icon: 'authenticate', type: 'text' }} /> : ''),
          head: ''
        }
      ];
    }
  },
  methods: {
    dispatchSelectEvent(camera: CameraLookupModel): void {
      this.$emit('select', camera);
    }
  }
});

function defineCamerasProp() {
  return arrayOf(object<CameraLookupModel>()).isRequired;
}
