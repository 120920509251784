
import { Options, Vue } from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import NButton from '@/uikit/buttons/NButton.vue';
import NButtonGroup from '@/uikit/buttons/NButtonGroup.vue';
import { NButtonGroupItem } from '@/uikit/buttons/types';
import { ItemsNavigationState } from '@/components/common/ItemsNavigationTypes';

@Options({
  name: 'ModulePageNavigation',
  components: { NButtonGroup, NButton }
})
export default class ModulePageNavigation extends Vue {
  @Prop({ type: Object, required: true })
  readonly state!: ItemsNavigationState;

  get items(): NButtonGroupItem[] {
    const { limit, page, source } = this.state;
    const firstPage = page === 0;
    const hasNextPage = (page + 1) * limit <= source.length;
    const result = [
      { icon: 'navigation-double-left', label: '', value: 'first', disabled: firstPage },
      { icon: 'navigation-left', label: '', value: 'prev', disabled: firstPage },
      { label: this.$t('common.page', 'f') + ' ' + (page + 1), value: 'refresh' },
      { icon: 'navigation-right', label: '', value: 'next', disabled: !hasNextPage },
      { icon: 'navigation-double-right', label: '', value: 'last', disabled: !hasNextPage }
    ];
    return result;
  }

  async navigate(type: string) {
    switch (type) {
      case 'first':
        this.$emit('setPage', 0);
        this.state.page = 0;
        break;
      case 'prev':
        this.state.page--;
        break;
      case 'next':
        this.state.page++;
        break;
      case 'last':
        this.state.page = Math.floor(this.state.source.length / this.state.limit);
        break;
      case 'refresh':
        break;
    }
  }
}
