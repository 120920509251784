
import { defineComponent, toRefs } from 'vue';
import { arrayOf, string } from 'vue-types';
import NInput from '@/uikit/input/NInput.vue';
import { VmsSidebarCamerasTabFiltersCameraGroup, VmsSidebarCamerasTabFiltersStatus } from './components';
import { useVmsSidebarCamerasTabFiltersViewModel } from './setup';

export default defineComponent({
  name: 'VmsSidebarCamerasTabFilters',
  components: {
    NInput,
    VmsSidebarCamerasTabFiltersCameraGroup,
    VmsSidebarCamerasTabFiltersStatus
  },
  props: {
    added: string().isRequired,
    cameraGroups: arrayOf(string()).isRequired,
    groupContains: string().isRequired,
    nameContains: string().isRequired
  },
  emits: ['update:added', 'update:groupContains', 'update:nameContains'],
  setup: (props, { emit }) => {
    return useVmsSidebarCamerasTabFiltersViewModel(toRefs(props), emit);
  }
});
