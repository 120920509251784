import { get } from 'lodash';
import { Camera } from '@/api';
import { configModule } from '@/store/config';
import NButtonGroup from '@/uikit/buttons/NButtonGroup.vue';
import { IFormContext, IFormLayout, IFormModel } from '@/uikit/forms/NForm.vue';
import { getRequiredValidator, getUrlValidator } from '@/uikit/forms/validators';
import NInput from '@/uikit/input/NInput.vue';
import NSwitch from '@/uikit/switch/NSwitch.vue';
import NTextDelimiter from '@/uikit/text/NTextDelimiter.vue';
import NTextareaAutosize from '@/uikit/textarea/NTextareaAutosize.vue';
import CameraGroupName from '@/components/data-source/CameraGroupName.vue';
import ExternalVmsCameraSelect from '@/pages/data-sources/forms/components/external-vms/ExternalVmsCameraSelect.vue';
import ExternalVmsSelect from '@/pages/data-sources/forms/components/external-vms/ExternalVmsSelect.vue';
import ExternalVmsStreamSelect from '@/pages/data-sources/forms/components/external-vms/ExternalVmsStreamSelect.vue';
import { ConnectionTypes, getConnectionTypeByItem } from '@/pages/data-sources/forms/utils';
import { CameraLookupSubForm } from './components/CameraLookupSubForm';
import { detectors } from './data-source-detectors';

function getConnectionGroupItems(externalVmsEnabled: boolean) {
  const items = [
    { name: ConnectionTypes.Onvif, i18n_label: 'ds.connection_onvif' },
    { name: ConnectionTypes.Stream, i18n_label: 'ds.connection_stream' }
  ];
  externalVmsEnabled && items.push({ name: ConnectionTypes.ExternalVMS, i18n_label: 'ds.connection_external_vms' });

  return items;
}

function getBaseLayout(externalVmsEnabled: boolean): IFormLayout {
  return [
    {
      classes: 'n-form-w-6  n-form-pad-10',
      component: NTextDelimiter,
      props: { i18n_label: 'ds.info' }
    },
    [
      {
        path: 'name',
        classes: 'n-form-w-3 n-form-pad-10',
        i18n_label: 'ds.camera_name',
        component: NInput,
        validators: [getRequiredValidator()]
      },
      {
        path: 'group',
        classes: 'n-form-w-3 n-form-pad-10',
        i18n_label: 'ds.camera_group',
        component: CameraGroupName
      }
    ],
    {
      path: 'comment',
      classes: 'n-form-w-6  n-form-pad-10',
      i18n_label: 'ds.description_optional',
      component: NTextareaAutosize
    },
    {
      path: 'stream_settings.enable_recorder',
      classes: 'n-form-w-2 n-form-pad-10 n-form-min-h-30 n-form-vcenter',
      component: NSwitch,
      props: { i18n_label: 'ds.enable_recorder' },
      hidden: () => !configModule.config.vms?.enabled
    },
    {
      classes: 'n-form-w-2 n-form-pad-10 n-form-min-h-30 n-form-vcenter',
      component: NSwitch,
      path: 'stream_settings.enable_liveness',
      props: { i18n_label: 'ds.enable_liveness' }
    },
    ...detectors,
    {
      classes: 'n-form-w-6 n-form-pad-10',
      component: NTextDelimiter,
      props: { i18n_label: 'ds.connection_type' }
    },
    {
      classes: 'n-form-pad-10',
      component: NButtonGroup,
      props: (item: any) =>
        Object.freeze({
          items: getConnectionGroupItems(externalVmsEnabled),
          disabled: item.id > 0
        }),
      encode: function (this: IFormContext, model: IFormModel, value: string) {
        switch (value) {
          case ConnectionTypes.Onvif:
            this.model.external_vms = null;
            this.model.onvif_camera = true;
            break;
          case ConnectionTypes.ExternalVMS:
            this.model.onvif_camera = null;
            this.model.external_vms = true;
            break;
          default:
            this.model.external_vms = this.model.onvif_camera = null;
        }
      },
      decode: function (this: IFormContext) {
        return getConnectionTypeByItem(this.model);
      }
    }
  ];
}

const onvif_layout = [
  {
    classes: 'n-form-pad-10',
    component: CameraLookupSubForm,
    path: 'url',
    props: (camera: Camera) => ({ camera })
  }
];

const stream_layout = [
  {
    path: 'url',
    classes: 'n-form-pad-10 grow-1',
    i18n_label: 'ds.streaming_protocol_url',
    component: NTextareaAutosize,
    props: { minRows: 3 },
    validators: [getRequiredValidator(), getUrlValidator()]
  }
];

const external_vms_layout: IFormLayout = [
  {
    i18n_label: 'external_vms.vms_select',
    classes: 'n-form-w-full n-form-pad-10',
    component: ExternalVmsSelect,
    path: 'external_vms',
    props: (item: any) => ({ model: item }),
    validators: [
      {
        i18n_message: 'errors.required.field',
        handler: (context: IFormContext) => {
          const value = context.item?.path && get(context.model, context.item.path);
          return value !== true && value;
        }
      }
    ]
  },
  {
    i18n_label: 'external_vms.camera_select',
    classes: 'n-form-w-full n-form-pad-10',
    component: ExternalVmsCameraSelect,
    path: 'external_vms_camera_id',
    props: (item: any) => ({ model: item, disabled: !item.external_vms && item.external_vms !== true }),
    validators: [getRequiredValidator()]
  },
  {
    i18n_label: 'external_vms.stream_select',
    classes: 'n-form-w-full n-form-pad-10',
    component: ExternalVmsStreamSelect,
    path: 'url',
    props: (item: any) => ({ model: item, disabled: !item.external_vms_camera_id }),
    validators: [getRequiredValidator()]
  }
];

export function getGeneralCameraLayout(connectionType: string, externalVmsEnabled: boolean) {
  const layout = getBaseLayout(externalVmsEnabled);
  switch (connectionType) {
    case ConnectionTypes.Onvif:
      return layout.concat(onvif_layout);
    case ConnectionTypes.Stream:
      return layout.concat(stream_layout);
    case ConnectionTypes.ExternalVMS:
      return layout.concat(externalVmsEnabled ? external_vms_layout : stream_layout);
  }
  return layout;
}
