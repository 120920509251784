import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "case-clusters-sidebar" }
const _hoisted_2 = {
  key: 0,
  class: "margin-top-10"
}
const _hoisted_3 = {
  key: 0,
  class: "case-clusters-sidebar__related-clusters"
}
const _hoisted_4 = {
  key: 1,
  class: "margin-top-10"
}
const _hoisted_5 = {
  key: 2,
  class: "margin-top-10"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MultisidebarHeader = _resolveComponent("MultisidebarHeader")!
  const _component_NTabs = _resolveComponent("NTabs")!
  const _component_CaseClusterItem = _resolveComponent("CaseClusterItem")!
  const _component_CardItemTile = _resolveComponent("CardItemTile")!
  const _component_ClusterWizardItem = _resolveComponent("ClusterWizardItem")!
  const _component_CardGroupRow = _resolveComponent("CardGroupRow")!
  const _component_CaseClusterForm = _resolveComponent("CaseClusterForm")!
  const _component_CaseClusterEvents = _resolveComponent("CaseClusterEvents")!
  const _component_CaseClusterConnections = _resolveComponent("CaseClusterConnections")!
  const _component_MultisidebarSaveActions = _resolveComponent("MultisidebarSaveActions")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_MultisidebarHeader, {
      label: _ctx.currentItem.name || '#' + _ctx.currentItem.id,
      changed: _ctx.moduleItem.hasChanges,
      actions: _ctx.headerActions,
      disableActions: _ctx.isCaseArchived,
      onAction: _cache[0] || (_cache[0] = (v) => _ctx.actionHandler(v, _ctx.sidebarItem))
    }, null, 8, ["label", "changed", "actions", "disableActions"]),
    _createVNode(_component_NTabs, {
      items: _ctx.tabsItems,
      modelValue: _ctx.sidebarSharedState.activeTab,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.sidebarSharedState.activeTab) = $event)),
      class: "multisidebar-content__tabs control-m"
    }, null, 8, ["items", "modelValue"]),
    (_ctx.sidebarSharedState.activeTab === 'info')
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_CardGroupRow, { class: "case-clusters-sidebar__card-group" }, {
            default: _withCtx(() => [
              _createVNode(_component_CaseClusterItem, {
                inSidebar: "",
                item: _ctx.sidebarItem.model.item,
                onAction: _cache[2] || (_cache[2] = (v) => _ctx.actionHandler(v, _ctx.currentItem.best_event)),
                actions: _ctx.caseClusterActions
              }, null, 8, ["item", "actions"])
            ]),
            card: _withCtx(() => [
              (_ctx.relatedCards.length)
                ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.relatedCards, (item) => {
                      return (_openBlock(), _createBlock(_component_ClusterWizardItem, {
                        key: item.id,
                        canAdd: false,
                        confidence: item.looks_like_confidence
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_CardItemTile, {
                            type: "humans",
                            item: item,
                            selected: !!_ctx.sidebarModule.getItem(_ctx.cardSidebarType, item.id),
                            opened: _ctx.getIsCardOpened(item),
                            onSelect: () => _ctx.sidebarModule.addItemAndOpen(_ctx.cardSidebarType, item),
                            onAction: (v) => _ctx.cardActionHandler(item.id, v, item)
                          }, null, 8, ["item", "selected", "opened", "onSelect", "onAction"])
                        ]),
                        _: 2
                      }, 1032, ["confidence"]))
                    }), 128))
                  ]))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }),
          _createVNode(_component_CaseClusterForm, {
            ref: "form",
            item: _ctx.moduleItem.item,
            showAcknowledge: _ctx.canChange && !_ctx.isParticipant,
            disabled: _ctx.isCaseArchived || !this.currentItemAcl.update
          }, null, 8, ["item", "showAcknowledge", "disabled"])
        ]))
      : _createCommentVNode("", true),
    (_ctx.sidebarSharedState.activeTab === 'cluster_events')
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _createVNode(_component_CaseClusterEvents, {
            clusterId: _ctx.currentItem.id
          }, null, 8, ["clusterId"])
        ]))
      : _createCommentVNode("", true),
    (_ctx.sidebarSharedState.activeTab === 'connections')
      ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
          _createVNode(_component_CaseClusterConnections, {
            items: _ctx.currentItem.related_case_clusters
          }, null, 8, ["items"])
        ]))
      : _createCommentVNode("", true),
    _createVNode(_component_MultisidebarSaveActions, {
      "sidebar-item": _ctx.sidebarItem,
      "model-acl": _ctx.modelAcl
    }, null, 8, ["sidebar-item", "model-acl"])
  ]))
}